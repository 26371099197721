/*
---------------------------------------
concept
---------------------------------------
*/

/*mainvisual*/
#page-concept{

	.l_contents{
		width: 90%;
		position: relative;
		@include sp{
			width: 100%;
			margin-bottom: 60px;
		}
		&--mv-top{
			width: 100%;
			height: 767px;
			background-size: cover;
			background-position: center center;
			@include sp{
				height: 490px;
			}
			&--catch{
				position: absolute;
				bottom: 0px;
				left: 11%;
				background-color: #fff;
				@include sp{
					background-color: unset;
					left: 15px;
					bottom: -2px;
				}
				h2{
					font-size: 104px;
					color: #231815;
					letter-spacing: 0.24em;
					line-height: 1.17;
					font-weight: 500;
					padding: 30px 50px 0 50px;
					position: relative;
					@include sp{
						font-size: 40px;
						line-height: 1;
						color: #fff;
						padding: 0;
					}
				}
				.sub-ttl{
					font-size: 16px;
					color: #231815;
					letter-spacing: 0.32em;
					line-height: 1;
					font-weight: 400;
					writing-mode: vertical-rl;
					position: absolute;
					bottom: 6%;
					right: 6%;
					@include sp{
						writing-mode: inherit;
						letter-spacing: 0.5em;
						font-weight: 500;
						color: #fff;
						bottom: 0;
						left: 200px;
					}
				}
			}
		}
	}

	.pl_concept{
		width: 90%;
		max-width: 1280px;
		margin-left: auto;
		margin-right: auto;
		@include sp{
			width: 100%;
		}
		&--intro{
			width: 100%;
			margin-bottom: 120px;
			margin-left: auto;
			margin-right: auto;
			@include sp{
				margin-bottom: 60px;
			}
			&--ttl{
				@include sp{}
				h2{
					font-size: 45px;
					letter-spacing: 0.28em;
					line-height: 1.56;
					font-weight: 500;
					text-align: center;
					margin-bottom: 50px;
					position: relative;
					@include sp{
						font-size: 18px;
					}
				}
				h2:before{
					content:'';
					position: absolute;
					bottom: 20%;
					left: 50%;
					transform: translateX(-50%);
					border: solid 1px #231815;
					width: 790px;
					@include sp{
						width: 315px;
						bottom: 4px;
					}
				}
			}
			&--txt{
				p{
					font-size: 20px;
					letter-spacing: 0.28em;
					line-height: 2.22;
					font-weight: 500;
					text-align: center;
					@include sp{
						font-size: 18px;
					}
				}
			}
		}

		.sec_basic{
			width: 100%;
			max-width: 1280px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 140px;
			@include sp{
				width: 92%;
				margin-bottom: 60px;
			}
			h2{
				font-size: 66px;
				letter-spacing: 0.28em;
				line-height: 1.156;
				font-weight: 500;
				margin-bottom: 45px;
				@include sp{
					font-size: 28px;
					margin-bottom: 20px;
				}
			}
			p{
				font-size: 14px;
				letter-spacing: 0.28em;
				line-height: 2.538;
				font-weight: 500;
				text-align: justify;
				margin-bottom: 30px;
				@include sp{
					margin-bottom: 20px;
				}
			}
			span{
				font-size: 13px;
				letter-spacing: 0.48em;
				line-height: 1;
				font-weight: 500;
				width: 165px;
				height: 54px;
				border: solid 1px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				@include sp{
					width: 150px;
					height: 50px;
				}
			}
		}

		&--sec_basic01{
			margin-top: -120px;
			padding-top: 120px;
			@include sp{
				margin-top: -70px;
				padding-top: 70px;
			}
			&--inner{
				@include flex;
				.inner_left{
					width: 640px;
					@include flex;
					@include sp{
						width: 100%;
					}
					.left_img{
						width: 322px;
						margin-top: 110px;
						@include sp{
							width: 100%;
							margin: 0 auto 20px;
						}
					}
					.right_img{
						width: 300px;
						@include sp{
							width: 100%;
						}
					}
				}
				.inner_right{
					width: 475px;
					@include sp{
						width: 100%;
						order: -1;
						margin-bottom: 30px;
					}
					&--ttl{
						h2{
							position: relative;
							letter-spacing: 0.18em;
						}
						h2:after{
							content: '';
							position: absolute;
							bottom: 0;
							left: 0;
							border-bottom: solid 33px $green;
							width: 440px;
							z-index: -1;
							@include sp{
								width: 100%;
								border-bottom: solid 20px $green;
							}
						}
					}
					&--txt{
						width: 430px;
						@include sp{
							width: 100%;
						}
						p{}
					}
					&--btn{
						width: 180px;
						margin-left: auto;
						@include sp{
							width: 150px;
						}
						span{
							color: $green;
						}
					}
				}
			}
		}

		&--sec_basic02{
			position: relative;
			margin-top: -120px;
			padding-top: 120px;
			@include sp{
				margin-top: -70px;
				padding-top: 70px;
			}
			&--inner{
				@include flex;
				.inner_left{
					z-index: 2;
					@include sp{
						margin-bottom: 30px;
					}
					&--ttl{
						position: relative;
						h2{
							letter-spacing: 0.28em;
							margin-left: 7.81vw;
							@include sp{
								margin-left: 0;
							}
						}
						h2:after{
							content: '';
							position: absolute;
							bottom: 0;
							left: 0;
							border-bottom: solid 33px $gray;
							width: 582px;
							z-index: -1;
							@include sp{
								width: 100%;
								border-bottom: solid 20px $gray;
							}
						}
					}
					&--txt{
						width: 430px;
						margin-left: 7.81vw;
						@include sp{
							width: 100%;
							margin-left: 0;
						}
						p{}
					}
					&--btn{
						width: 430px;
						margin-left: 7.81vw;
						text-align: right;
						@include sp{
							width: 100%;
							margin-left: 0;
						}
						span{
							color: $gray;
						}
					}
				}
				.inner_right{
					height: 500px;
					@include sp{
						height: auto;
					}
					.img{
						width: 470px;
						position: absolute;
						top: 120px;
						right: 0;
						@include sp{
							width: 100%;
							top: 0;
							position: relative;
						}
					}
				}
			}
		}

		&--sec_basic03{
			position: relative;
			margin-top: -120px;
			padding-top: 120px;
			@include sp{
				margin-top: -70px;
				padding-top: 70px;
			}
			&--inner{
				@include flex;
				height: auto;
				.inner_left{
					width: 650px;
					@include sp{
						width: 100%;
					}
					.left_img{
						width: 495px;
						margin-bottom: 20px;
						@include sp{
							width: 100%;
						}
					}
					.right_img{
						width: 495px;
						margin-left: 11.72vw;
						@include sp{
							width: 100%;
							margin-left: 0;
						}
					}
				}
				.inner_right{
					position: absolute;
					top: 120px;
					right: 0;
					@include sp{
						top: 0;
						position: relative;
						order: -1;
						margin-bottom: 30px;
					}
					&--ttl{
						h2{
							letter-spacing: 0.2em;
							position: relative;
							text-align: right;
							@include sp{
								text-align: left;
							}
						}
						h2:after{
							content: '';
							position: absolute;
							bottom: 0;
							right: 0;
							border-bottom: solid 33px $green;
							width: 440px;
							z-index: -1;
							@include sp{
								width: 100%;
								border-bottom: solid 20px $green;
							}
						}
					}
					&--txt{
						width: 430px;
						margin-left: auto;
						@include sp{
							width: 100%;
						}
						p{}
					}
					&--btn{
						width: 180px;
						text-align: right;
						margin-left: auto;
						@include sp{
							width: 100%;
						}
						span{
							color: $green;
						}
					}
				}
			}
		}

		&--sec_basic04{
			position: relative;
			margin-top: -120px;
			padding-top: 120px;
			@include sp{
				margin-top: -70px;
				padding-top: 70px;
			}
			&--inner{
				@include flex;
				.inner_left{
					z-index: 2;
					&--ttl{
						position: relative;
						h2{
							letter-spacing: 0.2em;
							margin-left: 7.81vw;
						}
						h2:after{
							content: '';
							position: absolute;
							bottom: 0;
							left: 0;
							border-bottom: solid 33px $gray;
							width: 582px;
							z-index: -1;
							@include sp{
								width: 100%;
								border-bottom: solid 20px $gray;
							}
						}
					}
					&--txt{
						width: 430px;
						margin-left: 7.81vw;
						@include sp{
							width: 100%;
						}
						p{}
					}
					&--btn{
						width: 430px;
						margin-left: 7.81vw;
						text-align: right;
						@include sp{
							width: 100%;
							margin-left: 0;
						}
						span{
							color: $gray;
						}
					}
				}
				.inner_right{
					height: 500px;
					.img{
						width: 470px;
						position: absolute;
						top: 0;
						right: 0;
						@include sp{
							width: 100%;
						}
					}
				}
			}
		}

	}

}


