.drawer{
  position: fixed;
  width: 10%;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 99999;
  @include sp{
    right: 25px;
    width: 46px;
    height: 120px;
  }
  &--inner{
    /*　ハンバーガーボタン　*/
    .hamburger {
      display: block;
      position: absolute;
      z-index: 99999;
      left: 50%;
      transform: translateX(-50%);
      top: 35px;
      width: 42px;
      height: 42px;
      text-align: center;
      @include sp{
        top: 30px;
        width: 20px;
        height: 20px;
      }
      span {
        display: block;
        position: absolute;
        width: 30px;
        height: 2px ;
        left: 6px;
        background: #000;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        @include sp{
          background: #fff;
          width: 20px;
        }
      }
    }
    .icon_area{
      @include sp{
        position: absolute;
        top: 85px ;
        right: 0px;
        width: 46px;
      }
      ul{
        @include flex;
        li{
          a{
            .img{}
            .insta{
              width: 21px;
            }
            .fb{
              width: 12px;
            }
          }
        }
      }
    }
  }
}
.hamburger span:nth-child(1) {
  top: 10px;
}
.hamburger span:nth-child(2) {
  top: 20px;
}
.hamburger span:nth-child(3) {
  top: 30px;
}

/* ナビ開いてる時のボタン */
.hamburger.active span:nth-child(1) {
  top: 16px;
  left: 6px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger.active span:nth-child(2),
.hamburger.active span:nth-child(3) {
  top: 16px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
.hamburger.active {
  @include sp{
    background-color: transparent;
  }
}
.hamburger {
  @include sp{
    background-color: transparent;
  }
}


.globalMenuSp {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background: #80b3a5;
  transform: translateY(-100%);
  transition: all 0.6s;
  width: 100%;
  overflow: hidden;
  @include sp{
    width: 100%;
    }
  &--inner{
    width: 100%;
    margin-right: auto;
    height: 100vh;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    @include sp{
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 70px;
    }
    .menu_left{
      @include sp{
      }
      .img{
        width: 225px;
        @include sp{
          width: 100px;
          margin-bottom: 40px;
        }
      }
    }
    ul {
      margin-left: 100px;
      @include sp{
        margin-left: 0;
      }
      li {
        list-style-type: none;
        padding: 0;
        width: 100%;
        a {
          display: inline-block;
          color: #fff;
          font-size: 28px;
          letter-spacing: 0.35em;
          line-height: 2.5;
          font-weight: 400;
          @include sp{
            font-size: 18px;
            display: block;
            text-align: center;
          }
        }
      }
    }
  }
  .side{
      width: 10%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    background-color: #fff;
    }
}

/* このクラスを、jQueryで付与・削除する */
.globalMenuSp.active {
  transform: translateY(0%);
}

