/*
---------------------------------------
news
---------------------------------------
*/

/*mainvisual*/
#page-news{

    .l_contents{
        width: 90%;
        position: relative;
        @include sp{
            width: 100%;
            margin-bottom: 60px;
        }
        &--mv-top{
            width: 100%;
            height: 767px;
            background-size: cover;
            background-position: center center;
            @include sp{
                height: 490px;
            }
            &--catch{
                position: absolute;
                bottom: -23px;
                left: 11%;
                @include sp{
                    left: 15px;
                    bottom: -9px;
                }
                h2{
                    font-size: 104px;
                    color: #fff;
                    letter-spacing: 0.24em;
                    line-height: 1.17;
                    position: relative;
                    @include sp{
                        font-size: 40px;
                        letter-spacing: 0.18em;
                    }
                }
                .sub-ttl{
                    font-size: 17px;
                    color: #fff;
                    letter-spacing: 0.9em;
                    line-height: 1;
                    font-weight: 400;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 410px;
                    width: 100%;
                    @include sp{
                        font-size: 16px;
                        letter-spacing: 0.5em;
                        left: 175px;
                        bottom: 0;
                        transform: inherit;
                    }
                }
            }
        }
    }

    .pl_news{
        width: 90%;
        @include sp{
            width: 92%;
            margin: 0 auto;
        }
        /*ニュースコンテンツ*/
        &--inner{
            width: 86.81%;
            margin-left: auto;
            margin-bottom: 100px;
            @include flex;
            color: #231815;
            @include sp{
                width: 100%;
                margin-bottom: 40px;
            }
            &--content{
                width: 670px;
                .content_wrap{
                    border-bottom: solid 1px #231815;
                    margin-bottom: 36px;
                    padding-bottom: 20px;
                    @include sp{
                        padding-bottom: 10px;
                        margin-bottom: 20px;
                    }
                    a{
                        .date{
                            p{
                                font-size: 10px;
                                letter-spacing: 0.28em;
                                line-height: 2.1;
                                font-weight: 400;
                                @include sp{
                                    margin-bottom:5px;
                                }
                            }
                        }
                        .article{
                            p{
                                font-size: 15px;
                                letter-spacing: 0.28em;
                                line-height: 2.73;
                                font-weight: 500;
                                @include sp{
                                    line-height: 1.85;
                                }
                            }
                        }
                    }
                }
            }
            &--category{
                width: 190px;
                @include sp{
                    display: none;
                }
                .category_wrap{
                    .ttl{
                        border-bottom: solid 1px #231815;
                        margin-bottom: 40px;
                        padding-bottom: 26px;
                        h3{
                            font-size: 15px;
                            letter-spacing: 0.28em;
                            line-height: 1;
                            font-weight: 500;
                            text-align: center;
                        }
                    }
                    .item{
                        position: relative;
                        display: inline-flex;
                        margin: 0 0 30px 10px;
                        width: 100%;
                        a{
                            display: flex;
                        .txt{
                            font-size: 11px;
                            letter-spacing: 0.28em;
                            line-height: 1;
                            font-weight: 400;
                        }
                        .count{
                            display: inline;
                            font-size: 9px;
                            letter-spacing: 0.28em;
                            line-height: 1;
                            font-weight: 400;
                        }
                    }
                    }
                    .item:before{
                        content:'';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        border-top: solid 1px #231815;
                        width: 5px;
                        left: -10px;
                    }
                }
            }
        }

        /*pagination*/
        .pagination{
            margin-bottom: 140px;
            @include sp{
                margin-bottom: 80px;
            }
            &--inner{
                width: 86.81%;
                margin-left: auto;
                &--list{
                    @include flex($justify:start);
                    &--item{
                        margin: 0 1.56vw;
                        .page-link{
                            color: #231815;
                            padding: 0;
                            display: inline-block;
                            line-height: 1;
                            position: relative;
                            .arrow-left_txt{
                                padding-left: 20px;
                            }
                            .arrow-left{
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                left: 0;
                                img{
                                    width: 10px;
                                }
                            }
                            .arrow-right_txt{
                                padding-right: 20px;
                            }
                            .arrow-right{
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                right: 0;
                                img{
                                    width: 10px;
                                }
                            }
                        }
                        .page-link.is-active{
                            border-bottom: 1px solid #231815;
                        }
                    }
                    &--item:first-child{
                        margin-left: 0;
                    }
                }
            }
        }
    }

}

/*news-detail*/
#news-dtl{
    .l_header--logo{
        position: relative;
        top: 40px;
        left: 140px;
        z-index: 999;
        @include sp{
            position: absolute;
            top: 30px;
            left: 24px;
        }
    }
    .l_breadcrumb {
        width: 90%;
        position: relative;
        padding: 20px 0 100px;
        margin-top: 60px;
        @include sp{
            display: none;
        }
    }
    .pl_news-detail{
        &--inner{
            &--head{
                background-color: $gray;
                text-align: left;
                .head_inner{
                    padding: 36px 0 70px;
                    @include sp{
                            padding: 150px 0 70px;
                        }
                    .date{
                        width: 782px;
                        margin: 0 auto 10px;
                        @include sp{
                            width: 92%;
                        }
                        .txt{
                            font-size: 10px;
                            letter-spacing: 0.28em;
                            line-height: 2.1;
                            font-weight: 400;
                            color: #fff;
                        }
                    }
                    .head_ttl{
                        width: 782px;
                        margin: 0 auto;
                        @include sp{
                            width: 92%;
                        }
                        .ttl{
                            font-size: 21px;
                            letter-spacing: 0.28em;
                            line-height: 1.28;
                            font-weight: 500;
                            color: #fff;
                            @include sp{
                                font-size: 18px;
                            }
                        }
                    }
                    .head_label{
                        width: 782px;
                        margin: 0 auto;
                        @include sp{
                            width: 92%;
                        }
                        .label{
                            text-align: right;
                            @include sp{
                                text-align: left;
                            }
                            p{
                            font-size: 8px;
                            letter-spacing: 0.32em;
                            line-height: 1;
                            font-weight: 400;
                            color: #fff;
                            border: solid 1px #fff;
                            display: inline-block;
                            padding: 2px 4px;
                            }
                        }
                    }
                }
            }
        }
        /*detail-pagination*/
        .pagination{
            &--inner{
                &--list{
                    &--item{}
                    .list_txt{
                        margin: 0 auto;
                        p{
                            color: #fff;
                            background-color: $gray;
                        }
                    }
                }
            }
        }
    }
}