@charset "UTF-8";
/*
---------------------------------------
variables
---------------------------------------
*/
/*
---------------------------------------
mixin
---------------------------------------
*/
/*
---------------------------------------
reset
---------------------------------------
*/
html {
  height: 100%;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
}

body {
  height: 100%;
  margin: 0 auto;
}

body, div, dl, dt, dd, ul, ol, li, p, th, td, figure, section, article, aside, main {
  margin: 0;
  padding: 0;
}

main {
  display: block;
}

span {
  display: inline-block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: inherit;
  font-size: 100%;
}

img {
  margin: 0;
  padding: 0;
  border: none;
  line-height: 0;
  vertical-align: bottom;
  outline: none;
}

ul li {
  list-style: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-weight: normal;
  font-style: normal;
}

body, *:before, *:after {
  font-size: 14px;
  *font-size: small;
  *font: x-small;
  font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif, noto-sans-cjk-jp, YuGothic, "游ゴシック", "Noto Sans JP", "Helvetica Neue", "Helvetica Neue LT Std", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Helvetica Neue", Arial, "メイリオ", Meiryo, "MS PGothic", sans-serif;
  color: #000000;
}

input, select, button, textarea, table {
  font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "Hiragino Mincho Pro", HGS明朝E, メイリオ, Meiryo, serif, noto-sans-cjk-jp, YuGothic, "游ゴシック", "Noto Sans JP", "Helvetica Neue", "Helvetica Neue LT Std", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Helvetica Neue", Arial, "メイリオ", Meiryo, "MS PGothic", sans-serif;
  line-height: 1.75;
  font-size: 100%;
  box-sizing: border-box;
  color: #000000;
}

cite, address, em {
  font-style: normal;
}

a {
  text-decoration: none;
  color: inherit;
}

* {
  background-repeat: no-repeat;
  box-sizing: border-box;
}

input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type=radio],
input[type=checkbox] {
  display: none;
}

/*
---------------------------------------
fonts
---------------------------------------
*/
.brandon {
  font-family: brandon-grotesque, sans-serif;
  font-style: normal;
}

.din {
  font-family: din-2014, sans-serif;
  font-style: normal;
}

.caslon {
  font-family: adobe-caslon-pro, serif;
  font-weight: 400;
}

.kozuka {
  font-family: "小塚ゴシック Pro", "Kozuka Gothic Pro", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", sans-serif;
}

.vertical {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}

/*
---------------------------------------
icon
---------------------------------------
*/
@font-face {
  font-family: "icomoon";
  src: url("/../icon/icomoon.eot?bx8fym");
  src: url("/../icon/icomoon.eot?bx8fym#iefix") format("embedded-opentype"), url("/../icon/icomoon.ttf?bx8fym") format("truetype"), url("/../icon/icomoon.woff?bx8fym") format("woff"), url("/../icon/icomoon.svg?bx8fym#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  display: inline-block;
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^=icon-], [class*=" icon-"] {
  display: inline-block;
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^=icon-]::before, [class*=" icon-"]::before {
  font-family: inherit !important;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

.icon-arrow-down:before {
  content: "\e900";
}

.icon-arrow-left:before {
  content: "\e901";
}

.icon-arrow-right:before {
  content: "\e902";
}

.icon-arrow-up:before {
  content: "\e903";
}

.icon-book:before {
  content: "\e904";
}

.icon-calc:before {
  content: "\e905";
}

.icon-circle-arrow-right:before {
  content: "\e906";
}

.icon-home:before {
  content: "\e907";
}

.icon-mail:before {
  content: "\e908";
}

.icon-pin:before {
  content: "\e909";
}

.icon-write:before {
  content: "\e90a";
}

/*
---------------------------------------
base
---------------------------------------
*/
body {
  min-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 480px) {
  body {
    min-width: auto;
  }
}

.inner {
  width: 1000px;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 480px) {
  .inner {
    width: 345px;
  }
}

.img img {
  width: 100%;
}

.thumb {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
}
.thumb::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.thumb > * {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.marker {
  background: linear-gradient(transparent 80%, #F5FF00 80%);
}

.elem--center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.font--blue {
  color: #00A0C8;
}

html {
  scroll-behavior: smooth;
}

@media (min-width: 1281px) and (max-width: 2560px) {
  .page_inner {
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
  }
  .pl_contact-parts--inner {
    margin-right: auto;
  }
}
/*
---------------------------------------
form
---------------------------------------
*/
input[type=text], input[type=email] {
  width: 490px;
  height: 65px;
  border: 1px solid #9da6ac;
  padding: 17px 25px;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.075em;
  line-height: 1.85em;
  color: #231815;
}
@media (max-width: 480px) {
  input[type=text], input[type=email] {
    width: 100%;
    height: 42px;
    font-size: 15px;
    font-size: 15px;
    padding: 0 15px;
  }
}
input[type=text]::placeholder, input[type=email]::placeholder {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.075em;
  line-height: 1.85em;
  color: #E0E0E0;
  font-size: 15px;
}

select {
  width: 345px;
  height: 65px;
  border: 1px solid #9da6ac;
  padding: 0 65px 0 25px;
  background: url("/images/contact/arrow-gr.svg");
  background-size: 16px auto, 100% 100%;
  background-position: right 22px center, center center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.075em;
  line-height: 1.85em;
  color: #231815;
}
@media all and (-ms-high-contrast: none) {
  select {
    background-image: none;
    padding: 0 0 0 25px;
  }
}
@media (max-width: 480px) {
  select {
    width: 100%;
    height: 42px;
    background: url("/images/contact/arrow-gr.svg");
    background-size: 16px auto, 100% 100%;
    background-position: right 19px center, center center;
    background-repeat: no-repeat;
    font-size: 15px;
    padding: 0 56px 0 15px;
  }
}

input[type=date] {
  width: 345px;
  height: 65px;
  border: 1px solid #9da6ac;
  padding: 0 25px 0 25px;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.075em;
  line-height: 1.85em;
  color: #231815;
}
@media (max-width: 480px) {
  input[type=date] {
    width: 150px;
    height: 42px;
    font-size: 15px;
    padding: 0 58px 0 15px;
  }
}
input[type=date]::placeholder {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.075em;
  line-height: 1.85em;
  color: #E0E0E0;
  font-size: 15px;
}

textarea {
  width: 100%;
  min-height: 280px;
  border: 1px solid #9da6ac;
  padding: 10px 25px;
  background-color: #fff;
}
@media (max-width: 480px) {
  textarea {
    min-height: 200px;
    padding: 10px 15px;
  }
}

input[type=radio], input[type=checkbox] {
  display: none;
}
input[type=radio] + label, input[type=checkbox] + label {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.32em;
  line-height: 1.6;
  padding-left: 45px;
}
input[type=radio] + label::before, input[type=radio] + label::after, input[type=checkbox] + label::before, input[type=checkbox] + label::after {
  position: absolute;
  top: 50%;
  content: "";
  display: block;
  transform: translate3d(0, -50%, 0);
  border-radius: 50%;
  box-sizing: border-box;
}
input[type=radio] + label::before, input[type=checkbox] + label::before {
  width: 30px;
  height: 30px;
  left: 0;
  background-color: #fff;
  border: 1px solid #9da6ac;
}
input[type=radio] + label::after, input[type=checkbox] + label::after {
  width: 12px;
  height: 12px;
  left: 9px;
  background-color: transparent;
}
input[type=radio]:checked + label::after, input[type=checkbox]:checked + label::after {
  background-color: #9da6ac;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: 0;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  line-height: 0;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  font-family: "slick";
  font-size: 6px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: #00A0C8;
  opacity: 0.75;
}

/*
---------------------------------------
utility
---------------------------------------
*/
.pu_circle {
  display: table;
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
}
.pu_circle > * {
  display: table-cell;
  vertical-align: middle;
}
.pu_circle > * {
  line-height: 0;
}
.pu_circle .icon-arrow-right {
  font-size: 10px;
  color: #00A0C8;
  transform: scale(0.6);
}

.pu_square {
  display: table;
  width: 58px;
  height: 58px;
  text-align: center;
  background-color: #fff;
}
.pu_square > * {
  display: table-cell;
  vertical-align: middle;
}
@media (max-width: 480px) {
  .pu_square {
    width: 35px;
    height: 35px;
  }
}
.pu_square > * {
  line-height: 0;
}
.pu_square .icon-arrow-right {
  font-size: 16px;
}
@media (max-width: 480px) {
  .pu_square .icon-arrow-right {
    font-size: 10px;
    transform: scale(0.9);
  }
}

/*
---------------------------------------
ttl
---------------------------------------
*/
.p_ttl--page--wrap {
  display: table;
  width: 480px;
  height: 150px;
  text-align: left;
  position: absolute;
  bottom: 75px;
  left: 0;
  padding-left: 50px;
  background-color: #fff;
  border-radius: 0 5px 5px 0;
}
.p_ttl--page--wrap > * {
  display: table-cell;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .p_ttl--page--wrap {
    left: -1px;
  }
}
@media (max-width: 480px) {
  .p_ttl--page--wrap {
    width: 270px;
    height: 100px;
    padding-left: 20px;
    bottom: 45px;
  }
}
.p_ttl--page--wrap .p_ttl--page > * {
  display: block;
}
.p_ttl--page--wrap .p_ttl--page--en {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.175em;
  line-height: 1.41666667em;
  color: #9da6ac;
  margin-bottom: 5px;
}
@media (max-width: 480px) {
  .p_ttl--page--wrap .p_ttl--page--en {
    font-size: 16px;
    line-height: 1.4375em;
  }
}
.p_ttl--page--wrap .p_ttl--page--ja {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.225em;
  line-height: 1.5em;
  color: #00A0C8;
}
@media (max-width: 480px) {
  .p_ttl--page--wrap .p_ttl--page--ja {
    font-size: 20px;
    line-height: 1.45em;
  }
}

.p_ttl--basic--ja {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.2em;
  line-height: 1.5em;
  color: #000;
}
@media (max-width: 480px) {
  .p_ttl--basic--ja {
    font-size: 21px;
  }
}

.p_ttl--heading--ja {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.2em;
  line-height: 1.5em;
  color: #000;
}
@media (max-width: 480px) {
  .p_ttl--heading--ja {
    font-size: 19px;
  }
}

.p_ttl--sec > * {
  display: block;
  text-align: center;
}
.p_ttl--sec--en {
  font-size: 38px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.42105213em;
  color: #00A0C8;
  margin-bottom: 5px;
}
@media (max-width: 480px) {
  .p_ttl--sec--en {
    font-size: 26px;
    line-height: 1.42307692em;
  }
}
.p_ttl--sec--ja {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.225em;
  line-height: 1.5em;
  color: #000;
}
@media (max-width: 480px) {
  .p_ttl--sec--ja {
    font-size: 13px;
    line-height: 1.46153846em;
  }
}

.p_ttl--border {
  padding: 0 0 10px 20px;
  border-bottom: 1px solid #9da6ac;
}
@media (max-width: 480px) {
  .p_ttl--border {
    padding: 0 0 10px 0;
  }
}
.p_ttl--border--ja {
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.2em;
  line-height: 1.46428571em;
  color: #00A0C8;
}
@media (max-width: 480px) {
  .p_ttl--border--ja {
    font-size: 17px;
  }
}

.p_ttl--num > * {
  display: block;
  text-align: center;
}
.p_ttl--num--num {
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1em;
  color: #9da6ac;
  margin-bottom: 10px;
}
@media (max-width: 480px) {
  .p_ttl--num--num {
    font-size: 50px;
  }
}
.p_ttl--num--en {
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.125em;
  line-height: 1.42857143em;
  color: #9da6ac;
  margin-bottom: 15px;
}
@media (max-width: 480px) {
  .p_ttl--num--en {
    font-size: 15px;
    line-height: 1.46666667em;
  }
}
.p_ttl--num--ja {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.125em;
  line-height: 1.85em;
  color: #000;
}
@media (max-width: 480px) {
  .p_ttl--num--ja {
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0.2em;
    line-height: 1.58823529em;
    color: #000;
  }
}

.p_ttl--num-horizon {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
.p_ttl--num-horizon--wrap > * {
  display: block;
  text-align: center;
}
.p_ttl--num-horizon--num {
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1em;
  color: #9da6ac;
  margin: -37px 25px 0 0;
}
.p_ttl--num-horizon--en {
  font-size: 34px;
  font-weight: 700;
  letter-spacing: 0.125em;
  line-height: 1.44117647em;
  color: #9da6ac;
  margin-bottom: 10px;
}
.p_ttl--num-horizon--ja {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.225em;
  line-height: 1.5em;
  color: #000;
}

/*
---------------------------------------
text
---------------------------------------
*/
.p_txt p {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.075em;
  line-height: 1.85em;
  color: #000;
  margin-bottom: 1.75em;
}
.p_txt p:last-child {
  margin-bottom: 0;
}
@media (max-width: 480px) {
  .p_txt p {
    font-size: 13px;
  }
}

/*
---------------------------------------
btn
---------------------------------------
*/
.p_btn {
  display: table;
  width: 100%;
  text-align: center;
  position: relative;
  border: 1px solid #CAD4DB;
  background-color: #fff;
  line-height: 1;
  opacity: 1;
  transition: opacity 0.4s;
}
.p_btn > * {
  display: table-cell;
  vertical-align: middle;
}
.p_btn:hover {
  opacity: 0.75;
}

.p_btn--large {
  max-width: 540px;
  height: 110px;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0.225em;
}
@media (max-width: 480px) {
  .p_btn--large {
    height: 75px;
    font-size: 14px;
  }
}
.p_btn--large .icon-calc,
.p_btn--large .icon-book {
  font-size: 32px;
  color: #00A0C8;
  left: 50px;
}
@media (max-width: 480px) {
  .p_btn--large .icon-calc,
.p_btn--large .icon-book {
    font-size: 21px;
    left: 30px;
  }
}
.p_btn--large .icon-arrow-right {
  font-size: 16px;
  right: 20px;
}
@media (max-width: 480px) {
  .p_btn--large .icon-arrow-right {
    font-size: 10px;
  }
}
.p_btn--middle {
  max-width: 425px;
  height: 75px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.125em;
}
@media (max-width: 480px) {
  .p_btn--middle {
    max-width: 345px;
    font-size: 14px;
  }
}
.p_btn--middle .pu_circle {
  right: 20px;
}
@media (max-width: 480px) {
  .p_btn--middle .pu_circle {
    width: 28px;
    height: 28px;
    right: 15px;
  }
}
.p_btn--middle .pu_circle .icon-arrow-right {
  font-size: 10px;
  color: #00A0C8;
  transform: scale(0.7);
}
@media (max-width: 480px) {
  .p_btn--middle .pu_circle .icon-arrow-right {
    transform: scale(0.85);
  }
}
.p_btn--radius {
  max-width: 165px;
  height: 35px;
  border-radius: 17px;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.125em;
}
.p_btn--radius .pu_circle {
  width: 18px;
  height: 18px;
  border: 1px solid #fff;
  background-color: transparent;
  right: 8px;
}
.p_btn--radius .pu_circle .icon-arrow-right {
  font-size: 10px;
  color: #fff;
  transform: scale(0.5);
}
.p_btn--blue {
  background-color: #00A0C8;
  border-color: #00A0C8;
  color: #fff;
}
.p_btn--orange {
  background-color: #ED8900;
  border-color: #ED8900;
  color: #fff;
}
.p_btn--gray {
  background-color: #9da6ac;
  border-color: #9da6ac;
  color: #fff;
}

@keyframes shake {
  0% {
    bottom: 10px;
  }
  50% {
    bottom: 25px;
  }
  100% {
    bottom: 10px;
  }
}
/*mainvisual*/
#page-top {
  /*concept*/
  /*works*/
  /*dit*/
  /*event*/
  /*catalog*/
  /*news*/
}
#page-top .l_contents {
  width: 90%;
  position: relative;
}
@media (max-width: 480px) {
  #page-top .l_contents {
    width: 100%;
    margin-bottom: 35px;
  }
}
#page-top .l_contents--mv-top {
  width: 100%;
  height: 767px;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 480px) {
  #page-top .l_contents--mv-top {
    width: 100%;
    height: 360px;
  }
}
#page-top .l_contents--mv-top--catch {
  position: absolute;
  bottom: -14px;
  left: 140px;
}
@media (max-width: 480px) {
  #page-top .l_contents--mv-top--catch {
    bottom: -7px;
    left: 15px;
  }
}
#page-top .l_contents--mv-top--catch h2 {
  font-size: 97px;
  color: #fff;
  letter-spacing: 0.12em;
  line-height: 1.09;
}
@media (max-width: 480px) {
  #page-top .l_contents--mv-top--catch h2 {
    font-size: 40px;
  }
}
@media (min-width: 1281px) and (max-width: 2560px) {
  #page-top .page_inner {
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
  }
  #page-top .pl_contact-parts--inner {
    margin-right: auto;
  }
}
#page-top .pl_top_concept {
  width: 90%;
  height: 835px;
  position: relative;
  padding-top: 70px;
}
@media (max-width: 480px) {
  #page-top .pl_top_concept {
    width: 100%;
    padding-top: 0;
    margin-bottom: 60px;
    height: 695px;
  }
}
@media (max-width: 480px) {
  #page-top .pl_top_concept .scroll-arrow {
    display: none;
  }
}
#page-top .pl_top_concept .scroll-arrow .arrow {
  position: relative;
  width: 45px;
  animation: 3s shake infinite ease-in;
  transform: rotate(90deg);
  margin-left: 13%;
}
#page-top .pl_top_concept .scroll-arrow .arrow:before, #page-top .pl_top_concept .scroll-arrow .arrow:after {
  content: "";
  display: block;
  position: absolute;
  top: 25px;
  right: -50px;
  height: 1px;
  background: #80b3a5;
}
#page-top .pl_top_concept .scroll-arrow .arrow:before {
  width: 66px;
  transform: translate(-30px, 0px);
}
#page-top .pl_top_concept .scroll-arrow .arrow:after {
  width: 10px;
  transform-origin: right center;
  transform: translate(-30px, 0px) rotate(45deg);
}
#page-top .pl_top_concept .scroll-arrow p {
  font-style: italic;
  color: #80b3a5;
  font-size: 1.071em;
  letter-spacing: 0.1em;
  line-height: 1;
}
#page-top .pl_top_concept--inner {
  display: flex;
  flex-flow: column-reverse;
}
#page-top .pl_top_concept--inner .left_box .img {
  width: 726px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  height: auto;
  mix-blend-mode: multiply;
}
@media (max-width: 480px) {
  #page-top .pl_top_concept--inner .left_box .img {
    width: 100%;
    position: relative;
  }
}
#page-top .pl_top_concept--inner .right_box {
  width: 100%;
  margin-bottom: 40px;
}
#page-top .pl_top_concept--inner .right_box--inner .box1 .img {
  width: 504px;
  position: absolute;
  right: 0;
  top: 94px;
  height: auto;
}
@media (max-width: 480px) {
  #page-top .pl_top_concept--inner .right_box--inner .box1 .img {
    display: none;
  }
}
#page-top .pl_top_concept--inner .right_box--inner .box2 .img {
  width: 737px;
  position: absolute;
  right: 0;
  top: 163px;
  z-index: 20;
}
@media (max-width: 480px) {
  #page-top .pl_top_concept--inner .right_box--inner .box2 .img {
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    width: 92%;
  }
}
#page-top .pl_top_concept--inner .right_box--inner .box3 {
  width: 375px;
  height: auto;
  position: absolute;
  right: 0;
  bottom: 0;
}
@media (max-width: 480px) {
  #page-top .pl_top_concept--inner .right_box--inner .box3 {
    width: 100%;
    position: relative;
  }
}
#page-top .pl_top_concept--inner .right_box--inner .box3--inner {
  position: relative;
}
#page-top .pl_top_concept--inner .right_box--inner .box3--inner--ttl {
  margin-bottom: 30px;
}
@media (max-width: 480px) {
  #page-top .pl_top_concept--inner .right_box--inner .box3--inner--ttl {
    width: 300px;
    margin: 0 auto 15px;
  }
}
#page-top .pl_top_concept--inner .right_box--inner .box3--inner--ttl .img {
  width: 100%;
}
#page-top .pl_top_concept--inner .right_box--inner .box3--inner--txt {
  margin-bottom: 30px;
}
@media (max-width: 480px) {
  #page-top .pl_top_concept--inner .right_box--inner .box3--inner--txt {
    width: 80vw;
    margin: 0 auto 20px;
  }
}
#page-top .pl_top_concept--inner .right_box--inner .box3--inner--txt p {
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 2;
  font-weight: bold;
  text-align: justify;
}
@media (max-width: 480px) {
  #page-top .pl_top_concept--inner .right_box--inner .box3--inner--txt p {
    text-align: center;
    letter-spacing: 0.15em;
  }
}
#page-top .pl_top_concept--inner .right_box--inner .box3--inner--btn a {
  width: 165px;
  height: 54px;
  border: solid 1px #80b3a5;
  display: block;
  margin: 0 auto;
  position: relative;
}
@media (max-width: 480px) {
  #page-top .pl_top_concept--inner .right_box--inner .box3--inner--btn a {
    width: 150px;
    height: 50px;
  }
}
#page-top .pl_top_concept--inner .right_box--inner .box3--inner--btn a span {
  font-size: 11px;
  letter-spacing: 0.48em;
  line-height: 1;
  font-weight: 700;
  color: #80b3a5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#page-top .pl_top_works {
  width: 90%;
  margin-bottom: 120px;
}
@media (max-width: 480px) {
  #page-top .pl_top_works {
    width: 100%;
    margin-bottom: 40px;
  }
}
#page-top .pl_top_works--inner--ttl {
  /*.ttl_inner:after{
  	content:"";
  	position: absolute;
  	top: 0;
  	left: 16%;
  	border-left: solid 34px $gray;
  	height: 300px;
  	z-index: -2;
  	mix-blend-mode: multiply;
  	@include sp{
  		display: none;
  	}
  	}*/
}
#page-top .pl_top_works--inner--ttl .ttl_inner {
  position: relative;
  height: 300px;
}
@media (max-width: 480px) {
  #page-top .pl_top_works--inner--ttl .ttl_inner {
    height: auto;
    margin-bottom: 50px;
  }
}
#page-top .pl_top_works--inner--ttl .ttl_inner h2 {
  font-size: 3.214em;
  letter-spacing: 0.6em;
  line-height: 1.155;
  display: block;
  position: absolute;
  top: 50%;
  left: 150px;
  margin-top: -25px;
}
@media (max-width: 480px) {
  #page-top .pl_top_works--inner--ttl .ttl_inner h2 {
    font-size: 20px;
    position: relative;
    text-align: center;
    top: 0;
    left: 0;
    margin-top: 0;
    margin-left: 0;
  }
}
#page-top .pl_top_works--inner--ttl .ttl_inner:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  border-top: solid 34px #80b3a5;
  width: 480px;
  z-index: -1;
  mix-blend-mode: multiply;
}
@media (max-width: 480px) {
  #page-top .pl_top_works--inner--ttl .ttl_inner:before {
    width: 50.67%;
    border-top: solid 10px #80b3a5;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
  }
}
#page-top .pl_top_works--inner--item {
  width: 86.81%;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}
@media (max-width: 480px) {
  #page-top .pl_top_works--inner--item {
    width: 100%;
    display: block;
    margin-bottom: 0;
  }
}
#page-top .pl_top_works--inner--item--item_wrap {
  position: relative;
  transition: all 1s;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media (max-width: 480px) {
  #page-top .pl_top_works--inner--item--item_wrap {
    width: 260px;
    padding: 0 5px;
  }
}
#page-top .pl_top_works--inner--item--item_wrap .img {
  width: 488px;
  height: 488px;
  overflow: hidden;
}
@media (max-width: 480px) {
  #page-top .pl_top_works--inner--item--item_wrap .img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
}
#page-top .pl_top_works--inner--item--item_wrap--box {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  opacity: 0;
}
#page-top .pl_top_works--inner--item--item_wrap--box .mask {
  position: absolute;
  top: 417px;
  left: 24px;
  display: flex;
  justify-content: space-between;
  width: 445px;
}
#page-top .pl_top_works--inner--item--item_wrap--box .mask p {
  color: #fff;
}
#page-top .pl_top_works--inner--item--item_wrap--box .mask .item_ttl {
  z-index: 1;
}
#page-top .pl_top_works--inner--item--item_wrap--box .mask .item_ttl .ttl {
  font-size: 16px;
  letter-spacing: 0.2em;
  line-height: 1.4375;
  font-weight: 500;
}
#page-top .pl_top_works--inner--item--item_wrap--box .mask .item_ttl .label {
  font-size: 11px;
  letter-spacing: 0.28em;
  line-height: 1;
  font-weight: 500;
  background-color: #fff;
  color: rgba(33, 39, 98, 0.5);
  display: inline-block;
  padding: 2% 3%;
  font-weight: 500;
  color: #000;
}
#page-top .pl_top_works--inner--item--item_wrap--box .mask .item_txt {
  z-index: 1;
}
#page-top .pl_top_works--inner--item--item_wrap--box .mask .item_txt dl {
  display: flex;
  color: #fff;
}
#page-top .pl_top_works--inner--item--item_wrap--box .mask .item_txt dl dt, #page-top .pl_top_works--inner--item--item_wrap--box .mask .item_txt dl dd {
  font-size: 11px;
  letter-spacing: 0.1em;
  line-height: 2;
  font-weight: 400;
}
#page-top .pl_top_works--inner--item--item_wrap--box:after {
  background-color: #80b3a5;
  mix-blend-mode: multiply;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
}
#page-top .pl_top_works--inner--item--item_wrap--box:hover {
  opacity: 1;
}
#page-top .pl_top_works--inner--item--item_wrap img {
  transition: all 2s;
}
#page-top .pl_top_works--inner--item--item_wrap:hover img {
  transform: scale(1.1);
  transition: all 2s;
}
@media (max-width: 480px) {
  #page-top .pl_top_works--inner--item--item_wrap:hover img {
    transform: none;
  }
}
#page-top .pl_top_works--inner--item ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
#page-top .pl_top_works--inner--item li {
  margin-bottom: 25px;
}
@media (max-width: 480px) {
  #page-top .pl_top_works--inner--item li {
    margin-bottom: 0;
  }
}
#page-top .pl_top_works--inner--btn {
  width: 86.81%;
  margin-left: auto;
  display: flex;
  justify-content: center;
}
@media (max-width: 480px) {
  #page-top .pl_top_works--inner--btn {
    width: 100%;
    margin: 0 auto;
  }
}
#page-top .pl_top_works--inner--btn a {
  width: 165px;
  height: 54px;
  border: solid 1px #80b3a5;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
#page-top .pl_top_works--inner--btn a span {
  font-size: 0.857em;
  letter-spacing: 0.48em;
  line-height: 1;
  color: #80b3a5;
}
#page-top .pl_top_works .slider .ttl {
  text-align: center;
  margin-bottom: 10px;
}
#page-top .pl_top_works .slick-num {
  margin-bottom: 60px;
  text-align: center;
}
#page-top .pl_top_works .slick-arrow {
  width: 10px;
  position: absolute;
  bottom: -15px;
}
#page-top .pl_top_works .arrow_left {
  left: 26px;
}
#page-top .pl_top_works .arrow_right {
  right: 26px;
}
#page-top .pl_top_works .slick-next, #page-top .pl_top_works .slick-prev {
  display: none;
}
#page-top .pl_top_dit {
  width: 90%;
  margin-bottom: 160px;
}
@media (max-width: 480px) {
  #page-top .pl_top_dit {
    width: 100%;
    margin-bottom: 40px;
  }
}
#page-top .pl_top_dit--inner {
  width: 86.81%;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 480px) {
  #page-top .pl_top_dit--inner {
    width: 100%;
    margin: 0 auto;
    display: block;
  }
}
#page-top .pl_top_dit--inner--left {
  position: relative;
}
#page-top .pl_top_dit--inner--left .left_box {
  width: 488px;
  position: relative;
}
@media (max-width: 480px) {
  #page-top .pl_top_dit--inner--left .left_box {
    width: 75.47%;
    margin: 0 auto 30px;
  }
}
#page-top .pl_top_dit--inner--right .right_box {
  width: 488px;
  position: relative;
  height: 0;
  padding-top: 81.15%;
  display: block;
}
@media (max-width: 480px) {
  #page-top .pl_top_dit--inner--right .right_box {
    width: 75.47%;
    margin: 0 auto;
    padding-top: 61%;
  }
}
#page-top .pl_top_dit--inner--right .right_box iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  top: 0;
}
#page-top .pl_top_dit--inner--right .right_box img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
#page-top .pl_top_event {
  width: 100%;
  height: 775px;
  display: flex;
}
@media (max-width: 480px) {
  #page-top .pl_top_event {
    width: 100%;
    padding-top: 0;
    height: auto;
    margin-bottom: 60px;
  }
}
#page-top .pl_top_event--inner {
  margin: 0 auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  align-items: center;
}
@media (max-width: 480px) {
  #page-top .pl_top_event--inner {
    position: relative;
    width: 100%;
  }
}
@media (max-width: 480px) {
  #page-top .pl_top_event--inner--left {
    display: none;
  }
}
#page-top .pl_top_event--inner--left .img {
  width: 437px;
}
#page-top .pl_top_event--inner--right {
  margin-left: 50px;
  height: 640px;
}
@media (max-width: 480px) {
  #page-top .pl_top_event--inner--right {
    width: 100%;
    display: block;
    margin-left: 0;
    height: auto;
  }
}
#page-top .pl_top_event--inner--right--ttl {
  width: 400px;
  height: 190px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;
}
@media (max-width: 480px) {
  #page-top .pl_top_event--inner--right--ttl {
    width: 240px;
    margin: 0 auto;
    height: 165px;
    align-content: center;
  }
}
#page-top .pl_top_event--inner--right--ttl h2 {
  color: #fff;
  text-align: center;
}
#page-top .pl_top_event--inner--right--ttl h2 .ttl_en {
  font-size: 2.285em;
  letter-spacing: 0.34em;
  line-height: 1.1875;
  font-weight: 500;
  margin-bottom: 10px;
}
@media (max-width: 480px) {
  #page-top .pl_top_event--inner--right--ttl h2 .ttl_en {
    font-size: 20px;
  }
}
#page-top .pl_top_event--inner--right--ttl h2 .ttl_ja {
  font-size: 0.714em;
  letter-spacing: 0.34em;
  line-height: 1;
  font-weight: 500;
  display: block;
}
@media (max-width: 480px) {
  #page-top .pl_top_event--inner--right--ttl h2 .ttl_ja {
    font-size: 10px;
  }
}
#page-top .pl_top_event--inner--right--ttl .event_ttl {
  position: relative;
  margin-top: 30px;
}
@media (max-width: 480px) {
  #page-top .pl_top_event--inner--right--ttl .event_ttl {
    position: relative;
    top: 0;
    left: 0;
    align-content: flex-start;
    margin-top: 50px;
  }
}
#page-top .pl_top_event--inner--right--ttl .event_ttl:after {
  position: absolute;
  top: -30px;
  left: 70%;
  content: "";
  width: 160px;
  height: 190px;
  background-image: linear-gradient(to left top, transparent 49.5%, white 49.5%, white 50.5%, transparent 50.5%);
}
@media (max-width: 480px) {
  #page-top .pl_top_event--inner--right--ttl .event_ttl:after {
    width: 98px;
    height: 122px;
    top: 40px;
    left: 60%;
    transform: translateY(-50%);
  }
}
#page-top .pl_top_event--inner--right--ttl .ws_ttl {
  margin-top: 90px;
}
@media (max-width: 480px) {
  #page-top .pl_top_event--inner--right--ttl .ws_ttl {
    margin-top: 75px;
  }
}
#page-top .pl_top_event--inner--right--article {
  width: 400px;
  color: #fff;
}
@media (max-width: 480px) {
  #page-top .pl_top_event--inner--right--article {
    width: 100%;
    position: relative;
    top: -5px;
    left: 0;
    background: #80b3a5;
    padding: 40px 15px 20px;
    mix-blend-mode: multiply;
  }
}
#page-top .pl_top_event--inner--right--article ul > li {
  border-bottom: 1px dashed #fff;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  margin-bottom: 16px;
}
#page-top .pl_top_event--inner--right--article ul > li:last-child {
  border-bottom: 1px dashed #fff;
}
#page-top .pl_top_event--inner--right--article .article_btn {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  width: 100%;
}
#page-top .pl_top_event--inner--right--article .left {
  width: 300px;
}
@media (max-width: 480px) {
  #page-top .pl_top_event--inner--right--article .left {
    width: 250px;
  }
}
#page-top .pl_top_event--inner--right--article .left .cate_ttl {
  background-color: #fff;
  color: #80b3a5;
  display: inline-block;
  font-size: 0.714em;
  letter-spacing: 0.28em;
  line-height: 1;
  font-weight: 500;
  padding: 5px 8px;
  margin-bottom: 8px;
}
#page-top .pl_top_event--inner--right--article .left .ttl {
  display: block;
  font-size: 0.642em;
  letter-spacing: 0.28em;
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: 5px;
}
#page-top .pl_top_event--inner--right--article .left .txt {
  display: block;
  font-size: 0.928em;
  letter-spacing: 0.28em;
  line-height: 1.76;
  font-weight: 500;
  margin-bottom: 10px;
}
#page-top .pl_top_event--inner--right--article .right .img {
  width: 86px;
}
#page-top .pl_top_event--inner--right--article--btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 25px;
  background-color: #fff;
  margin-left: auto;
}
#page-top .pl_top_event--inner--right--article--btn a {
  color: #80b3a5;
}
#page-top .pl_top_event--inner--right--article--btn a span {
  font-size: 12px;
  letter-spacing: 0.48em;
  line-height: 1;
  font-weight: 700;
}
#page-top .pl_top_event:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 290px;
  background-color: #9da6ac;
  z-index: -1;
  mix-blend-mode: multiply;
}
@media (max-width: 480px) {
  #page-top .pl_top_event:before {
    height: 165px;
  }
}
#page-top .pl_top_event:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background-color: #80b3a5;
  z-index: -1;
  mix-blend-mode: multiply;
}
@media (max-width: 480px) {
  #page-top .pl_top_event:after {
    display: none;
  }
}
#page-top .event_item {
  width: 90%;
  display: inline-flex;
}
@media (max-width: 480px) {
  #page-top .event_item {
    width: 100%;
  }
}
#page-top .pl_top_catalog {
  width: 90%;
  margin: 80px auto 120px;
  position: relative;
}
@media (max-width: 480px) {
  #page-top .pl_top_catalog {
    width: 100%;
    margin-bottom: 80px;
  }
}
#page-top .pl_top_catalog--inner {
  content: "";
  background-image: url("/images/common/catalog_bg.jpg");
  background-position: center left;
  background-size: cover;
  width: 715px;
  height: 592px;
  margin-right: auto;
}
@media (max-width: 480px) {
  #page-top .pl_top_catalog--inner {
    background-size: 188px, 185px;
    width: 100%;
    height: auto;
    background-position: 0 285px;
    padding-bottom: 30px;
  }
}
#page-top .pl_top_catalog--inner--txt_box {
  width: 630px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
@media (max-width: 480px) {
  #page-top .pl_top_catalog--inner--txt_box {
    width: 92%;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0;
    transform: inherit;
  }
}
#page-top .pl_top_catalog--inner--txt_box--ttl {
  width: 100%;
  height: auto;
  text-align: right;
}
#page-top .pl_top_catalog--inner--txt_box--ttl .ttl_en {
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0.6em;
  line-height: 1;
  margin-bottom: 40px;
}
@media (max-width: 480px) {
  #page-top .pl_top_catalog--inner--txt_box--ttl .ttl_en {
    font-size: 20px;
    text-align: center;
    position: relative;
  }
}
@media (max-width: 480px) {
  #page-top .pl_top_catalog--inner--txt_box--ttl .ttl_en:after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 48.5%;
    transform: translateX(-50%);
    width: 55.07%;
    border-top: solid 10px #9da6ac;
    z-index: -1;
  }
}
#page-top .pl_top_catalog--inner--txt_box--ttl .ttl_ja {
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0.48em;
  line-height: 1.89;
  margin-bottom: 40px;
}
@media (max-width: 480px) {
  #page-top .pl_top_catalog--inner--txt_box--ttl .ttl_ja {
    font-size: 27px;
    letter-spacing: 0.28em;
    line-height: 1.77;
    text-align: left;
    margin-bottom: 20px;
  }
}
#page-top .pl_top_catalog--inner--txt_box .box {
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  align-items: center;
}
#page-top .pl_top_catalog--inner--txt_box .box .txt {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.28em;
  line-height: 1.92;
  margin-bottom: 30px;
}
#page-top .pl_top_catalog--inner--txt_box .box .btn a {
  width: 180px;
  height: 60px;
  border: solid 1px #80b3a5;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media (max-width: 480px) {
  #page-top .pl_top_catalog--inner--txt_box .box .btn a {
    width: 150px;
    height: 50px;
    margin-left: 155px;
  }
}
#page-top .pl_top_catalog--inner--txt_box .box .btn a span {
  font-size: 13px;
  color: #80b3a5;
  letter-spacing: 0.48em;
}
#page-top .pl_top_news {
  width: 90%;
  margin-bottom: 100px;
}
@media (max-width: 480px) {
  #page-top .pl_top_news {
    width: 100%;
    margin: 0 auto 60px;
  }
}
#page-top .pl_top_news--inner--ttl {
  position: relative;
  width: 100%;
  height: 40px;
  margin-bottom: 100px;
}
@media (max-width: 480px) {
  #page-top .pl_top_news--inner--ttl {
    height: auto;
    margin-bottom: 60px;
  }
}
#page-top .pl_top_news--inner--ttl h2 {
  font-size: 3.214em;
  letter-spacing: 0.6em;
  line-height: 0.86;
  font-weight: 500;
  position: absolute;
  top: -40%;
  left: 47%;
  margin-left: -260px;
}
@media (max-width: 480px) {
  #page-top .pl_top_news--inner--ttl h2 {
    font-size: 20px;
    position: relative;
    margin-left: 0;
    top: 0;
    left: 0;
    text-align: center;
  }
}
#page-top .pl_top_news--inner--ttl:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-top: solid 34px #80b3a5;
  width: 47%;
  z-index: -1;
}
@media (max-width: 480px) {
  #page-top .pl_top_news--inner--ttl:after {
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
    width: 50.67%;
    border-top: solid 10px #80b3a5;
  }
}
#page-top .pl_top_news--inner--item {
  width: 86.81%;
  margin-left: auto;
}
@media (max-width: 480px) {
  #page-top .pl_top_news--inner--item {
    width: 92%;
    margin: 0 auto;
  }
}
#page-top .pl_top_news--inner--item .news_area--inner {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media (max-width: 480px) {
  #page-top .pl_top_news--inner--item .news_area--inner {
    display: block;
  }
}
#page-top .pl_top_news--inner--item .news_area--inner li {
  width: 47.15%;
  border-bottom: 1px solid #9da6ac;
  margin-bottom: 30px;
}
@media (max-width: 480px) {
  #page-top .pl_top_news--inner--item .news_area--inner li {
    width: 100%;
  }
}
#page-top .pl_top_news--inner--item .news_area--inner .date {
  display: block;
  font-size: 10px;
  letter-spacing: 0.28em;
  line-height: 2.1;
  margin-bottom: 5px;
}
#page-top .pl_top_news--inner--item .news_area--inner .txt {
  display: block;
  font-size: 15px;
  letter-spacing: 0.28em;
  line-height: 2.73;
  font-weight: 500;
  margin-bottom: 20px;
}
@media (max-width: 480px) {
  #page-top .pl_top_news--inner--item .news_area--inner .txt {
    font-size: 14px;
    letter-spacing: 0.2em;
    line-height: 1.6;
  }
}
#page-top .pl_top_news--inner--item .news_area--btn {
  display: flex;
  justify-content: flex-end;
}
#page-top .pl_top_news--inner--item .news_area--btn a {
  width: 85px;
  height: 25px;
  border: solid 1px #80b3a5;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
#page-top .pl_top_news--inner--item .news_area--btn a span {
  font-size: 13px;
  color: #80b3a5;
  letter-spacing: 0.48em;
  font-weight: 700;
}

/*
---------------------------------------
concept
---------------------------------------
*/
/*mainvisual*/
#page-concept .l_contents {
  width: 90%;
  position: relative;
}
@media (max-width: 480px) {
  #page-concept .l_contents {
    width: 100%;
    margin-bottom: 60px;
  }
}
#page-concept .l_contents--mv-top {
  width: 100%;
  height: 767px;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 480px) {
  #page-concept .l_contents--mv-top {
    height: 490px;
  }
}
#page-concept .l_contents--mv-top--catch {
  position: absolute;
  bottom: 0px;
  left: 11%;
  background-color: #fff;
}
@media (max-width: 480px) {
  #page-concept .l_contents--mv-top--catch {
    background-color: unset;
    left: 15px;
    bottom: -2px;
  }
}
#page-concept .l_contents--mv-top--catch h2 {
  font-size: 104px;
  color: #231815;
  letter-spacing: 0.24em;
  line-height: 1.17;
  font-weight: 500;
  padding: 30px 50px 0 50px;
  position: relative;
}
@media (max-width: 480px) {
  #page-concept .l_contents--mv-top--catch h2 {
    font-size: 40px;
    line-height: 1;
    color: #fff;
    padding: 0;
  }
}
#page-concept .l_contents--mv-top--catch .sub-ttl {
  font-size: 16px;
  color: #231815;
  letter-spacing: 0.32em;
  line-height: 1;
  font-weight: 400;
  writing-mode: vertical-rl;
  position: absolute;
  bottom: 6%;
  right: 6%;
}
@media (max-width: 480px) {
  #page-concept .l_contents--mv-top--catch .sub-ttl {
    writing-mode: inherit;
    letter-spacing: 0.5em;
    font-weight: 500;
    color: #fff;
    bottom: 0;
    left: 200px;
  }
}
#page-concept .pl_concept {
  width: 90%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 480px) {
  #page-concept .pl_concept {
    width: 100%;
  }
}
#page-concept .pl_concept--intro {
  width: 100%;
  margin-bottom: 120px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--intro {
    margin-bottom: 60px;
  }
}
#page-concept .pl_concept--intro--ttl h2 {
  font-size: 45px;
  letter-spacing: 0.28em;
  line-height: 1.56;
  font-weight: 500;
  text-align: center;
  margin-bottom: 50px;
  position: relative;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--intro--ttl h2 {
    font-size: 18px;
  }
}
#page-concept .pl_concept--intro--ttl h2:before {
  content: "";
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  border: solid 1px #231815;
  width: 790px;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--intro--ttl h2:before {
    width: 315px;
    bottom: 4px;
  }
}
#page-concept .pl_concept--intro--txt p {
  font-size: 20px;
  letter-spacing: 0.28em;
  line-height: 2.22;
  font-weight: 500;
  text-align: center;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--intro--txt p {
    font-size: 18px;
  }
}
#page-concept .pl_concept .sec_basic {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 140px;
}
@media (max-width: 480px) {
  #page-concept .pl_concept .sec_basic {
    width: 92%;
    margin-bottom: 60px;
  }
}
#page-concept .pl_concept .sec_basic h2 {
  font-size: 66px;
  letter-spacing: 0.28em;
  line-height: 1.156;
  font-weight: 500;
  margin-bottom: 45px;
}
@media (max-width: 480px) {
  #page-concept .pl_concept .sec_basic h2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
}
#page-concept .pl_concept .sec_basic p {
  font-size: 14px;
  letter-spacing: 0.28em;
  line-height: 2.538;
  font-weight: 500;
  text-align: justify;
  margin-bottom: 30px;
}
@media (max-width: 480px) {
  #page-concept .pl_concept .sec_basic p {
    margin-bottom: 20px;
  }
}
#page-concept .pl_concept .sec_basic span {
  font-size: 13px;
  letter-spacing: 0.48em;
  line-height: 1;
  font-weight: 500;
  width: 165px;
  height: 54px;
  border: solid 1px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 480px) {
  #page-concept .pl_concept .sec_basic span {
    width: 150px;
    height: 50px;
  }
}
#page-concept .pl_concept--sec_basic01 {
  margin-top: -120px;
  padding-top: 120px;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic01 {
    margin-top: -70px;
    padding-top: 70px;
  }
}
#page-concept .pl_concept--sec_basic01--inner {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
#page-concept .pl_concept--sec_basic01--inner .inner_left {
  width: 640px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic01--inner .inner_left {
    width: 100%;
  }
}
#page-concept .pl_concept--sec_basic01--inner .inner_left .left_img {
  width: 322px;
  margin-top: 110px;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic01--inner .inner_left .left_img {
    width: 100%;
    margin: 0 auto 20px;
  }
}
#page-concept .pl_concept--sec_basic01--inner .inner_left .right_img {
  width: 300px;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic01--inner .inner_left .right_img {
    width: 100%;
  }
}
#page-concept .pl_concept--sec_basic01--inner .inner_right {
  width: 475px;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic01--inner .inner_right {
    width: 100%;
    order: -1;
    margin-bottom: 30px;
  }
}
#page-concept .pl_concept--sec_basic01--inner .inner_right--ttl h2 {
  position: relative;
  letter-spacing: 0.18em;
}
#page-concept .pl_concept--sec_basic01--inner .inner_right--ttl h2:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: solid 33px #80b3a5;
  width: 440px;
  z-index: -1;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic01--inner .inner_right--ttl h2:after {
    width: 100%;
    border-bottom: solid 20px #80b3a5;
  }
}
#page-concept .pl_concept--sec_basic01--inner .inner_right--txt {
  width: 430px;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic01--inner .inner_right--txt {
    width: 100%;
  }
}
#page-concept .pl_concept--sec_basic01--inner .inner_right--btn {
  width: 180px;
  margin-left: auto;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic01--inner .inner_right--btn {
    width: 150px;
  }
}
#page-concept .pl_concept--sec_basic01--inner .inner_right--btn span {
  color: #80b3a5;
}
#page-concept .pl_concept--sec_basic02 {
  position: relative;
  margin-top: -120px;
  padding-top: 120px;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic02 {
    margin-top: -70px;
    padding-top: 70px;
  }
}
#page-concept .pl_concept--sec_basic02--inner {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
#page-concept .pl_concept--sec_basic02--inner .inner_left {
  z-index: 2;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic02--inner .inner_left {
    margin-bottom: 30px;
  }
}
#page-concept .pl_concept--sec_basic02--inner .inner_left--ttl {
  position: relative;
}
#page-concept .pl_concept--sec_basic02--inner .inner_left--ttl h2 {
  letter-spacing: 0.28em;
  margin-left: 7.81vw;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic02--inner .inner_left--ttl h2 {
    margin-left: 0;
  }
}
#page-concept .pl_concept--sec_basic02--inner .inner_left--ttl h2:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: solid 33px #9da6ac;
  width: 582px;
  z-index: -1;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic02--inner .inner_left--ttl h2:after {
    width: 100%;
    border-bottom: solid 20px #9da6ac;
  }
}
#page-concept .pl_concept--sec_basic02--inner .inner_left--txt {
  width: 430px;
  margin-left: 7.81vw;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic02--inner .inner_left--txt {
    width: 100%;
    margin-left: 0;
  }
}
#page-concept .pl_concept--sec_basic02--inner .inner_left--btn {
  width: 430px;
  margin-left: 7.81vw;
  text-align: right;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic02--inner .inner_left--btn {
    width: 100%;
    margin-left: 0;
  }
}
#page-concept .pl_concept--sec_basic02--inner .inner_left--btn span {
  color: #9da6ac;
}
#page-concept .pl_concept--sec_basic02--inner .inner_right {
  height: 500px;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic02--inner .inner_right {
    height: auto;
  }
}
#page-concept .pl_concept--sec_basic02--inner .inner_right .img {
  width: 470px;
  position: absolute;
  top: 120px;
  right: 0;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic02--inner .inner_right .img {
    width: 100%;
    top: 0;
    position: relative;
  }
}
#page-concept .pl_concept--sec_basic03 {
  position: relative;
  margin-top: -120px;
  padding-top: 120px;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic03 {
    margin-top: -70px;
    padding-top: 70px;
  }
}
#page-concept .pl_concept--sec_basic03--inner {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  height: auto;
}
#page-concept .pl_concept--sec_basic03--inner .inner_left {
  width: 650px;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic03--inner .inner_left {
    width: 100%;
  }
}
#page-concept .pl_concept--sec_basic03--inner .inner_left .left_img {
  width: 495px;
  margin-bottom: 20px;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic03--inner .inner_left .left_img {
    width: 100%;
  }
}
#page-concept .pl_concept--sec_basic03--inner .inner_left .right_img {
  width: 495px;
  margin-left: 11.72vw;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic03--inner .inner_left .right_img {
    width: 100%;
    margin-left: 0;
  }
}
#page-concept .pl_concept--sec_basic03--inner .inner_right {
  position: absolute;
  top: 120px;
  right: 0;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic03--inner .inner_right {
    top: 0;
    position: relative;
    order: -1;
    margin-bottom: 30px;
  }
}
#page-concept .pl_concept--sec_basic03--inner .inner_right--ttl h2 {
  letter-spacing: 0.2em;
  position: relative;
  text-align: right;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic03--inner .inner_right--ttl h2 {
    text-align: left;
  }
}
#page-concept .pl_concept--sec_basic03--inner .inner_right--ttl h2:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: solid 33px #80b3a5;
  width: 440px;
  z-index: -1;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic03--inner .inner_right--ttl h2:after {
    width: 100%;
    border-bottom: solid 20px #80b3a5;
  }
}
#page-concept .pl_concept--sec_basic03--inner .inner_right--txt {
  width: 430px;
  margin-left: auto;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic03--inner .inner_right--txt {
    width: 100%;
  }
}
#page-concept .pl_concept--sec_basic03--inner .inner_right--btn {
  width: 180px;
  text-align: right;
  margin-left: auto;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic03--inner .inner_right--btn {
    width: 100%;
  }
}
#page-concept .pl_concept--sec_basic03--inner .inner_right--btn span {
  color: #80b3a5;
}
#page-concept .pl_concept--sec_basic04 {
  position: relative;
  margin-top: -120px;
  padding-top: 120px;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic04 {
    margin-top: -70px;
    padding-top: 70px;
  }
}
#page-concept .pl_concept--sec_basic04--inner {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
#page-concept .pl_concept--sec_basic04--inner .inner_left {
  z-index: 2;
}
#page-concept .pl_concept--sec_basic04--inner .inner_left--ttl {
  position: relative;
}
#page-concept .pl_concept--sec_basic04--inner .inner_left--ttl h2 {
  letter-spacing: 0.2em;
  margin-left: 7.81vw;
}
#page-concept .pl_concept--sec_basic04--inner .inner_left--ttl h2:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: solid 33px #9da6ac;
  width: 582px;
  z-index: -1;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic04--inner .inner_left--ttl h2:after {
    width: 100%;
    border-bottom: solid 20px #9da6ac;
  }
}
#page-concept .pl_concept--sec_basic04--inner .inner_left--txt {
  width: 430px;
  margin-left: 7.81vw;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic04--inner .inner_left--txt {
    width: 100%;
  }
}
#page-concept .pl_concept--sec_basic04--inner .inner_left--btn {
  width: 430px;
  margin-left: 7.81vw;
  text-align: right;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic04--inner .inner_left--btn {
    width: 100%;
    margin-left: 0;
  }
}
#page-concept .pl_concept--sec_basic04--inner .inner_left--btn span {
  color: #9da6ac;
}
#page-concept .pl_concept--sec_basic04--inner .inner_right {
  height: 500px;
}
#page-concept .pl_concept--sec_basic04--inner .inner_right .img {
  width: 470px;
  position: absolute;
  top: 0;
  right: 0;
}
@media (max-width: 480px) {
  #page-concept .pl_concept--sec_basic04--inner .inner_right .img {
    width: 100%;
  }
}

/*
---------------------------------------
concept_detail
---------------------------------------
*/
/*mainvisual*/
#page-concept_dtl .l_header {
  width: 100%;
  height: 140px;
  position: relative;
}
#page-concept_dtl .l_contents {
  width: 90%;
  position: relative;
}
#page-concept_dtl .l_contents--mv-top {
  width: 100%;
  height: 627px;
  background-size: cover;
  background-position: center center;
}
#page-concept_dtl .l_contents--mv-top--catch {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}
#page-concept_dtl .l_contents--mv-top--catch h2 {
  font-size: 23px;
  color: #fff;
  letter-spacing: 0.28em;
  line-height: 1;
  font-weight: 500;
}
#page-concept_dtl .pl_concept {
  width: 90%;
}
#page-concept_dtl .pl_concept--inner {
  width: 100%;
}
#page-concept_dtl .pl_concept--inner--link_btn {
  width: 86.81%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  margin-left: auto;
  padding: 40px 0;
}
#page-concept_dtl .pl_concept--inner--link_btn .btn {
  width: 33.333%;
  height: 120px;
  border-left: 1px solid #808080;
  display: flex;
  justify-content: center;
  align-items: center;
}
#page-concept_dtl .pl_concept--inner--link_btn .btn a span {
  display: block;
}
#page-concept_dtl .pl_concept--inner--link_btn .btn a .txt {
  font-size: 21px;
  letter-spacing: 0.28em;
  line-height: 1;
  color: #808080;
  margin-bottom: 20px;
}
#page-concept_dtl .pl_concept--inner--link_btn .btn a .icon {
  width: 20px;
  margin: 0 auto;
}
#page-concept_dtl .pl_concept--inner--link_btn .btn:first-child {
  border-left: none;
}
#page-concept_dtl .pl_concept .sec_basic {
  width: 100%;
}
#page-concept_dtl .pl_concept .sec_basic--inner h2 {
  font-size: 66px;
  letter-spacing: 0.28em;
  line-height: 1.156;
  font-weight: 500;
  margin-bottom: 45px;
}
#page-concept_dtl .pl_concept .sec_basic--inner h3 {
  font-size: 17px;
  letter-spacing: 0.28em;
  line-height: 2.3;
  font-weight: 500;
}
#page-concept_dtl .pl_concept .sec_basic--inner p {
  font-size: 14px;
  letter-spacing: 0.28em;
  line-height: 2.538;
  font-weight: 500;
  text-align: justify;
  margin-bottom: 30px;
}
#page-concept_dtl .pl_concept .sec_basic--inner span {
  font-size: 14px;
  letter-spacing: 0.28em;
  line-height: 1;
  font-weight: 500;
}
#page-concept_dtl .pl_concept--sec_basic01 {
  width: 100%;
  height: auto;
  margin-bottom: 140px;
}
#page-concept_dtl .pl_concept--sec_basic01--inner {
  position: relative;
  width: 100%;
  height: 800px;
  margin-bottom: 40px;
}
#page-concept_dtl .pl_concept--sec_basic01--inner .inner_left--ttl {
  position: relative;
}
#page-concept_dtl .pl_concept--sec_basic01--inner .inner_left--ttl h2 {
  width: 86.81%;
  margin-left: auto;
}
#page-concept_dtl .pl_concept--sec_basic01--inner .inner_left--ttl h2:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: solid 33px #80b3a5;
  width: 542px;
  z-index: -1;
}
#page-concept_dtl .pl_concept--sec_basic01--inner .inner_left--txt {
  width: 86.81%;
  margin-left: auto;
}
#page-concept_dtl .pl_concept--sec_basic01--inner .inner_left--txt .txt p {
  font-size: 17px;
  letter-spacing: 0.28em;
  line-height: 2.3;
  font-weight: 500;
}
#page-concept_dtl .pl_concept--sec_basic01--inner .inner_left--txt .txt p span {
  line-height: 2.538;
}
#page-concept_dtl .pl_concept--sec_basic01--inner .inner_left--txt .t-txt p {
  margin-bottom: 80px;
}
#page-concept_dtl .pl_concept--sec_basic01--inner .inner_right .img {
  width: 595px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}
#page-concept_dtl .pl_concept--sec_basic01--inner02 {
  width: 86.81%;
  margin-left: auto;
}
#page-concept_dtl .pl_concept--sec_basic01--inner02 .arrow-d {
  width: 214px;
  margin-bottom: 60px;
}
#page-concept_dtl .pl_concept--sec_basic01--inner02 .arrow-d .img {
  width: 74px;
  margin: 0 auto;
}
#page-concept_dtl .pl_concept--sec_basic01--inner02--item ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
#page-concept_dtl .pl_concept--sec_basic01--inner02--item ul li {
  width: 214px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  justify-content: center;
  margin-right: 15px;
}
#page-concept_dtl .pl_concept--sec_basic01--inner02--item ul li .img {
  width: 214px;
  margin-bottom: 30px;
}
#page-concept_dtl .pl_concept--sec_basic01--inner02--item ul li .txt_area {
  text-align: center;
}
#page-concept_dtl .pl_concept--sec_basic01--inner02--item ul li .txt_area .ttl {
  position: relative;
  margin-bottom: 15px;
  height: 60px;
  display: flex;
  align-items: center;
}
#page-concept_dtl .pl_concept--sec_basic01--inner02--item ul li .txt_area .ttl p {
  font-size: 18px;
  letter-spacing: 0.075em;
  line-height: 1.333;
  font-weight: 500;
  display: block;
  margin-bottom: 20px;
  text-align: center;
}
#page-concept_dtl .pl_concept--sec_basic01--inner02--item ul li .txt_area .ttl:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-top: 1px solid #231815;
  width: 180px;
}
#page-concept_dtl .pl_concept--sec_basic01--inner02--item ul li .txt_area .txt p {
  font-size: 11px;
  letter-spacing: 0.1em;
  line-height: 2.18;
  font-weight: 500;
  display: block;
}
#page-concept_dtl .pl_concept--sec_basic02 {
  width: 100%;
  height: 800px;
  margin-bottom: 140px;
}
#page-concept_dtl .pl_concept--sec_basic02--inner {
  position: relative;
  width: 100%;
  height: 800px;
  margin-bottom: 40px;
}
#page-concept_dtl .pl_concept--sec_basic02--inner .inner_left--ttl {
  position: relative;
}
#page-concept_dtl .pl_concept--sec_basic02--inner .inner_left--ttl h2 {
  width: 86.81%;
  margin-left: auto;
}
#page-concept_dtl .pl_concept--sec_basic02--inner .inner_left--ttl h2:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: solid 33px #80b3a5;
  width: 542px;
  z-index: -1;
}
#page-concept_dtl .pl_concept--sec_basic02--inner .inner_left--txt {
  width: 86.81%;
  margin-left: auto;
}
#page-concept_dtl .pl_concept--sec_basic02--inner .inner_left--txt .txt p {
  font-size: 17px;
  letter-spacing: 0.28em;
  line-height: 2.3;
  font-weight: 500;
}
#page-concept_dtl .pl_concept--sec_basic02--inner .inner_left--txt .txt p span {
  line-height: 2.538;
}
#page-concept_dtl .pl_concept--sec_basic02--inner .inner_left--txt .t-txt p {
  margin-bottom: 80px;
}
#page-concept_dtl .pl_concept--sec_basic02--inner .inner_right .img {
  width: 595px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}
#page-concept_dtl .pl_concept--sec_basic03 {
  width: 100%;
  height: auto;
  margin-bottom: 140px;
}
#page-concept_dtl .pl_concept--sec_basic03--inner {
  position: relative;
  width: 100%;
  height: 287px;
  margin-bottom: 40px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  align-content: flex-start;
}
#page-concept_dtl .pl_concept--sec_basic03--inner .inner_left {
  width: 100%;
}
#page-concept_dtl .pl_concept--sec_basic03--inner .inner_left .ttl_area .inner_left-ttl {
  position: relative;
}
#page-concept_dtl .pl_concept--sec_basic03--inner .inner_left .ttl_area .inner_left-ttl h2 {
  width: 86.81%;
  margin-left: auto;
}
#page-concept_dtl .pl_concept--sec_basic03--inner .inner_left .ttl_area .inner_left-ttl h2:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: solid 33px #80b3a5;
  width: 542px;
  z-index: -1;
}
#page-concept_dtl .pl_concept--sec_basic03--inner .inner_left .ttl_area .inner_left-sub_ttl {
  width: 86.81%;
  margin-left: auto;
}
#page-concept_dtl .pl_concept--sec_basic03--inner .inner_left .ttl_area .inner_left-sub_ttl h3 {
  font-size: 36px;
  letter-spacing: 0.2em;
  line-height: 1.43;
  font-weight: 500;
}
#page-concept_dtl .pl_concept--sec_basic03--inner .inner_right {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  width: 745px;
}
#page-concept_dtl .pl_concept--sec_basic03--inner .inner_right .img {
  width: 100%;
}
#page-concept_dtl .pl_concept--sec_basic03--inner02 {
  width: 100%;
  height: 455px;
  margin-bottom: 40px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  position: relative;
}
#page-concept_dtl .pl_concept--sec_basic03--inner02--item {
  width: 86.81%;
  margin-left: auto;
  display: flex;
}
#page-concept_dtl .pl_concept--sec_basic03--inner02--item .inner_left {
  width: 445px;
}
#page-concept_dtl .pl_concept--sec_basic03--inner02--item .inner_left .img {
  width: 100%;
}
#page-concept_dtl .pl_concept--sec_basic03--inner02--item .inner_right {
  left: 515px;
  width: 745px;
  margin-left: auto;
}
#page-concept_dtl .pl_concept--sec_basic03--inner02--item .inner_right .txt_area {
  width: 480px;
  margin-right: auto;
}
#page-concept_dtl .pl_concept--sec_basic03--inner02--item .inner_right .txt_area .txt p {
  font-size: 13px;
  letter-spacing: 0.28em;
  line-height: 2.58;
  font-weight: 500;
}
#page-concept_dtl .pl_concept--sec_basic03--inner02--item .inner_right .txt_area .btn {
  width: 290px;
  height: 96px;
  text-align: center;
  background-color: #9da6ac;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: 0 auto;
}
#page-concept_dtl .pl_concept--sec_basic03--inner02--item .inner_right .txt_area .btn .ttl_en {
  font-size: 16px;
  letter-spacing: 0.3em;
  line-height: 1.75;
  font-weight: 500;
  display: block;
}
#page-concept_dtl .pl_concept--sec_basic03--inner02--item .inner_right .txt_area .btn .ttl_ja {
  font-size: 9px;
  letter-spacing: 0.32em;
  line-height: 1.75;
  font-weight: 500;
  display: block;
}

/*
---------------------------------------
works
---------------------------------------
*/
/*mainvisual*/
#page-works {
  /*カテゴリーボタン*/
  /*works-detail*/
}
#page-works .l_contents {
  width: 90%;
  height: 803px;
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 2.5%;
}
@media (max-width: 480px) {
  #page-works .l_contents {
    width: 100%;
    height: auto;
    padding-top: 0;
    margin-bottom: 60px;
  }
}
#page-works .l_contents--mv-top {
  width: 86.81%;
  height: 556px;
  background-size: cover;
  background-position: center center;
  margin-left: auto;
  position: relative;
}
@media (max-width: 480px) {
  #page-works .l_contents--mv-top {
    width: 100%;
    height: 490px;
  }
}
#page-works .l_contents--mv-top--catch {
  position: absolute;
  bottom: 0;
  left: 6.94%;
  display: flex;
  margin-bottom: -50px;
}
@media (max-width: 480px) {
  #page-works .l_contents--mv-top--catch {
    left: 15px;
    bottom: 0;
    margin-bottom: 0;
    width: 92%;
  }
}
#page-works .l_contents--mv-top--catch h2 {
  font-size: 90px;
  color: #fff;
  letter-spacing: 0.32em;
  line-height: 1;
  font-weight: 500;
  position: relative;
}
@media (max-width: 480px) {
  #page-works .l_contents--mv-top--catch h2 {
    font-size: 40px;
    bottom: -6px;
  }
}
#page-works .l_contents--mv-top--catch .sub-ttl {
  font-size: 18px;
  color: #fff;
  letter-spacing: 0.9em;
  line-height: 1;
  font-weight: 400;
  position: absolute;
  bottom: 15%;
  right: -9vw;
}
@media (max-width: 480px) {
  #page-works .l_contents--mv-top--catch .sub-ttl {
    font-size: 16px;
    bottom: 0;
    left: 215px;
    width: 130px;
  }
}
#page-works .l_contents:after {
  content: "";
  width: 69.92%;
  height: 804px;
  background-color: #80b3a5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
}
@media (max-width: 480px) {
  #page-works .l_contents:after {
    display: none;
  }
}
@media (min-width: 1281px) and (max-width: 2560px) {
  #page-works .page-works {
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
  }
  #page-works .pl_contact-parts--inner {
    margin-right: auto;
  }
}
#page-works .pl_works {
  width: 90%;
  /*施工事例アイテム*/
  /*pagination*/
}
@media (max-width: 480px) {
  #page-works .pl_works {
    width: 92%;
    margin: 0 auto;
  }
}
#page-works .pl_works--category {
  width: 90%;
  max-width: 1280px;
  margin-left: auto;
  margin-bottom: 100px;
}
@media (max-width: 480px) {
  #page-works .pl_works--category {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}
#page-works .pl_works--category .cate_btn {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media (max-width: 480px) {
  #page-works .pl_works--category .cate_btn {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
}
#page-works .pl_works--category .cate_btn .btn {
  margin-right: 20px;
}
@media (max-width: 480px) {
  #page-works .pl_works--category .cate_btn .btn {
    margin-bottom: 15px;
    margin-right: 0;
  }
}
#page-works .pl_works--category .cate_btn .btn a {
  width: 236px;
  height: 106px;
  display: table-cell;
  vertical-align: middle;
  background-color: #9da6ac;
}
@media (max-width: 480px) {
  #page-works .pl_works--category .cate_btn .btn a {
    width: 165px;
    height: 90px;
  }
}
#page-works .pl_works--category .cate_btn .btn a span {
  display: block;
  text-align: center;
  color: #fff;
}
#page-works .pl_works--category .cate_btn .btn a .btn_en {
  font-size: 16px;
  letter-spacing: 0.3em;
  line-height: 1.75;
  font-weight: 500;
}
@media (max-width: 480px) {
  #page-works .pl_works--category .cate_btn .btn a .btn_en {
    font-size: 14px;
  }
}
#page-works .pl_works--category .cate_btn .btn a .btn_ja {
  font-size: 9px;
  letter-spacing: 0.32em;
  line-height: 1.75;
  font-weight: 400;
}
#page-works .pl_works--category .cate_btn .btn a:hover {
  opacity: 0.8;
}
#page-works .pl_works--category .cate_btn .btn:last-child {
  margin-right: 0;
}
#page-works .pl_works--inner {
  width: 100%;
}
#page-works .pl_works--inner--list {
  width: 90%;
  margin-left: auto;
  margin-bottom: 100px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media (max-width: 480px) {
  #page-works .pl_works--inner--list {
    width: 100%;
    margin-bottom: 60px;
  }
}
#page-works .pl_works--inner--list--item {
  width: 488px;
  margin-bottom: 40px;
}
@media (max-width: 480px) {
  #page-works .pl_works--inner--list--item {
    width: 100%;
  }
}
#page-works .pl_works--inner--list--item a .thumb--wrap {
  position: relative;
  margin-bottom: 15px;
}
#page-works .pl_works--inner--list--item a .thumb--wrap .thumb::before {
  padding-top: 100%;
}
#page-works .pl_works--inner--list--item a .txt_area .ttl {
  font-size: 11px;
  letter-spacing: 0.16em;
  line-height: 2;
  font-weight: 500;
  color: #231815;
  margin-bottom: 5px;
}
#page-works .pl_works--inner--list--item a .txt_area .label {
  font-size: 8px;
  letter-spacing: 0.32em;
  line-height: 1;
  font-weight: 400;
  color: #a2a2a2;
  border: solid 1px #a2a2a2;
  display: inline-block;
  padding: 4px 5px;
}
@media (max-width: 480px) {
  #page-works .pl_works--inner--list--item:last-child {
    margin-bottom: 0;
  }
}
#page-works .pl_works .pagination {
  margin-bottom: 140px;
}
@media (max-width: 480px) {
  #page-works .pl_works .pagination {
    margin-bottom: 60px;
  }
}
#page-works .pl_works .pagination--inner {
  width: 90%;
  margin-left: auto;
}
@media (max-width: 480px) {
  #page-works .pl_works .pagination--inner {
    width: 100%;
  }
}
#page-works .pl_works .pagination--inner--list {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media (max-width: 480px) {
  #page-works .pl_works .pagination--inner--list {
    justify-content: center;
  }
}
#page-works .pl_works .pagination--inner--list--item {
  margin: 0 1.56vw;
}
#page-works .pl_works .pagination--inner--list--item .page-link {
  color: #231815;
  padding: 0;
  display: inline-block;
  line-height: 1;
  position: relative;
}
#page-works .pl_works .pagination--inner--list--item .page-link .arrow-left_txt {
  padding-left: 20px;
}
#page-works .pl_works .pagination--inner--list--item .page-link .arrow-left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
#page-works .pl_works .pagination--inner--list--item .page-link .arrow-left img {
  width: 10px;
}
#page-works .pl_works .pagination--inner--list--item .page-link .arrow-right_txt {
  padding-right: 20px;
}
#page-works .pl_works .pagination--inner--list--item .page-link .arrow-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
#page-works .pl_works .pagination--inner--list--item .page-link .arrow-right img {
  width: 10px;
}
#page-works .pl_works .pagination--inner--list--item .page-link.is-active {
  border-bottom: 1px solid #231815;
}
#page-works .pl_works .pagination--inner--list--item:first-child {
  margin-left: 0;
}
#page-works .page-works-dtl .l_header--logo {
  position: relative;
  top: 40px;
  left: 140px;
  z-index: 999;
}
@media (max-width: 480px) {
  #page-works .page-works-dtl .l_header--logo {
    position: absolute;
    top: 30px;
    left: 24px;
  }
}
#page-works .page-works-dtl .l_breadcrumb {
  width: 90%;
  position: relative;
  padding: 20px 0 100px;
  margin-top: 60px;
}
@media (max-width: 480px) {
  #page-works .page-works-dtl .l_breadcrumb {
    display: none;
  }
}
#page-works .pl_works-detail {
  /*detail-pagination*/
}
#page-works .pl_works-detail--inner--head {
  background-color: #80b3a5;
  text-align: center;
  height: 203px;
}
@media (max-width: 480px) {
  #page-works .pl_works-detail--inner--head {
    height: 320px;
  }
}
#page-works .pl_works-detail--inner--head .head_inner {
  padding: 36px 0 70px;
}
@media (max-width: 480px) {
  #page-works .pl_works-detail--inner--head .head_inner {
    padding: 150px 0 70px;
  }
}
#page-works .pl_works-detail .pagination--inner--list .list_txt {
  margin: 0 auto;
}
#page-works .pl_works-detail .pagination--inner--list .list_txt p {
  color: #fff;
  background-color: #80b3a5;
}

/*
---------------------------------------
news
---------------------------------------
*/
/*mainvisual*/
#page-news .l_contents {
  width: 90%;
  position: relative;
}
@media (max-width: 480px) {
  #page-news .l_contents {
    width: 100%;
    margin-bottom: 60px;
  }
}
#page-news .l_contents--mv-top {
  width: 100%;
  height: 767px;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 480px) {
  #page-news .l_contents--mv-top {
    height: 490px;
  }
}
#page-news .l_contents--mv-top--catch {
  position: absolute;
  bottom: -23px;
  left: 11%;
}
@media (max-width: 480px) {
  #page-news .l_contents--mv-top--catch {
    left: 15px;
    bottom: -9px;
  }
}
#page-news .l_contents--mv-top--catch h2 {
  font-size: 104px;
  color: #fff;
  letter-spacing: 0.24em;
  line-height: 1.17;
  position: relative;
}
@media (max-width: 480px) {
  #page-news .l_contents--mv-top--catch h2 {
    font-size: 40px;
    letter-spacing: 0.18em;
  }
}
#page-news .l_contents--mv-top--catch .sub-ttl {
  font-size: 17px;
  color: #fff;
  letter-spacing: 0.9em;
  line-height: 1;
  font-weight: 400;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 410px;
  width: 100%;
}
@media (max-width: 480px) {
  #page-news .l_contents--mv-top--catch .sub-ttl {
    font-size: 16px;
    letter-spacing: 0.5em;
    left: 175px;
    bottom: 0;
    transform: inherit;
  }
}
#page-news .pl_news {
  width: 90%;
  /*ニュースコンテンツ*/
  /*pagination*/
}
@media (max-width: 480px) {
  #page-news .pl_news {
    width: 92%;
    margin: 0 auto;
  }
}
#page-news .pl_news--inner {
  width: 86.81%;
  margin-left: auto;
  margin-bottom: 100px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  color: #231815;
}
@media (max-width: 480px) {
  #page-news .pl_news--inner {
    width: 100%;
    margin-bottom: 40px;
  }
}
#page-news .pl_news--inner--content {
  width: 670px;
}
#page-news .pl_news--inner--content .content_wrap {
  border-bottom: solid 1px #231815;
  margin-bottom: 36px;
  padding-bottom: 20px;
}
@media (max-width: 480px) {
  #page-news .pl_news--inner--content .content_wrap {
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
}
#page-news .pl_news--inner--content .content_wrap a .date p {
  font-size: 10px;
  letter-spacing: 0.28em;
  line-height: 2.1;
  font-weight: 400;
}
@media (max-width: 480px) {
  #page-news .pl_news--inner--content .content_wrap a .date p {
    margin-bottom: 5px;
  }
}
#page-news .pl_news--inner--content .content_wrap a .article p {
  font-size: 15px;
  letter-spacing: 0.28em;
  line-height: 2.73;
  font-weight: 500;
}
@media (max-width: 480px) {
  #page-news .pl_news--inner--content .content_wrap a .article p {
    line-height: 1.85;
  }
}
#page-news .pl_news--inner--category {
  width: 190px;
}
@media (max-width: 480px) {
  #page-news .pl_news--inner--category {
    display: none;
  }
}
#page-news .pl_news--inner--category .category_wrap .ttl {
  border-bottom: solid 1px #231815;
  margin-bottom: 40px;
  padding-bottom: 26px;
}
#page-news .pl_news--inner--category .category_wrap .ttl h3 {
  font-size: 15px;
  letter-spacing: 0.28em;
  line-height: 1;
  font-weight: 500;
  text-align: center;
}
#page-news .pl_news--inner--category .category_wrap .item {
  position: relative;
  display: inline-flex;
  margin: 0 0 30px 10px;
  width: 100%;
}
#page-news .pl_news--inner--category .category_wrap .item a {
  display: flex;
}
#page-news .pl_news--inner--category .category_wrap .item a .txt {
  font-size: 11px;
  letter-spacing: 0.28em;
  line-height: 1;
  font-weight: 400;
}
#page-news .pl_news--inner--category .category_wrap .item a .count {
  display: inline;
  font-size: 9px;
  letter-spacing: 0.28em;
  line-height: 1;
  font-weight: 400;
}
#page-news .pl_news--inner--category .category_wrap .item:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-top: solid 1px #231815;
  width: 5px;
  left: -10px;
}
#page-news .pl_news .pagination {
  margin-bottom: 140px;
}
@media (max-width: 480px) {
  #page-news .pl_news .pagination {
    margin-bottom: 80px;
  }
}
#page-news .pl_news .pagination--inner {
  width: 86.81%;
  margin-left: auto;
}
#page-news .pl_news .pagination--inner--list {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
#page-news .pl_news .pagination--inner--list--item {
  margin: 0 1.56vw;
}
#page-news .pl_news .pagination--inner--list--item .page-link {
  color: #231815;
  padding: 0;
  display: inline-block;
  line-height: 1;
  position: relative;
}
#page-news .pl_news .pagination--inner--list--item .page-link .arrow-left_txt {
  padding-left: 20px;
}
#page-news .pl_news .pagination--inner--list--item .page-link .arrow-left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
#page-news .pl_news .pagination--inner--list--item .page-link .arrow-left img {
  width: 10px;
}
#page-news .pl_news .pagination--inner--list--item .page-link .arrow-right_txt {
  padding-right: 20px;
}
#page-news .pl_news .pagination--inner--list--item .page-link .arrow-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
#page-news .pl_news .pagination--inner--list--item .page-link .arrow-right img {
  width: 10px;
}
#page-news .pl_news .pagination--inner--list--item .page-link.is-active {
  border-bottom: 1px solid #231815;
}
#page-news .pl_news .pagination--inner--list--item:first-child {
  margin-left: 0;
}

/*news-detail*/
#news-dtl .l_header--logo {
  position: relative;
  top: 40px;
  left: 140px;
  z-index: 999;
}
@media (max-width: 480px) {
  #news-dtl .l_header--logo {
    position: absolute;
    top: 30px;
    left: 24px;
  }
}
#news-dtl .l_breadcrumb {
  width: 90%;
  position: relative;
  padding: 20px 0 100px;
  margin-top: 60px;
}
@media (max-width: 480px) {
  #news-dtl .l_breadcrumb {
    display: none;
  }
}
#news-dtl .pl_news-detail {
  /*detail-pagination*/
}
#news-dtl .pl_news-detail--inner--head {
  background-color: #9da6ac;
  text-align: left;
}
#news-dtl .pl_news-detail--inner--head .head_inner {
  padding: 36px 0 70px;
}
@media (max-width: 480px) {
  #news-dtl .pl_news-detail--inner--head .head_inner {
    padding: 150px 0 70px;
  }
}
#news-dtl .pl_news-detail--inner--head .head_inner .date {
  width: 782px;
  margin: 0 auto 10px;
}
@media (max-width: 480px) {
  #news-dtl .pl_news-detail--inner--head .head_inner .date {
    width: 92%;
  }
}
#news-dtl .pl_news-detail--inner--head .head_inner .date .txt {
  font-size: 10px;
  letter-spacing: 0.28em;
  line-height: 2.1;
  font-weight: 400;
  color: #fff;
}
#news-dtl .pl_news-detail--inner--head .head_inner .head_ttl {
  width: 782px;
  margin: 0 auto;
}
@media (max-width: 480px) {
  #news-dtl .pl_news-detail--inner--head .head_inner .head_ttl {
    width: 92%;
  }
}
#news-dtl .pl_news-detail--inner--head .head_inner .head_ttl .ttl {
  font-size: 21px;
  letter-spacing: 0.28em;
  line-height: 1.28;
  font-weight: 500;
  color: #fff;
}
@media (max-width: 480px) {
  #news-dtl .pl_news-detail--inner--head .head_inner .head_ttl .ttl {
    font-size: 18px;
  }
}
#news-dtl .pl_news-detail--inner--head .head_inner .head_label {
  width: 782px;
  margin: 0 auto;
}
@media (max-width: 480px) {
  #news-dtl .pl_news-detail--inner--head .head_inner .head_label {
    width: 92%;
  }
}
#news-dtl .pl_news-detail--inner--head .head_inner .head_label .label {
  text-align: right;
}
@media (max-width: 480px) {
  #news-dtl .pl_news-detail--inner--head .head_inner .head_label .label {
    text-align: left;
  }
}
#news-dtl .pl_news-detail--inner--head .head_inner .head_label .label p {
  font-size: 8px;
  letter-spacing: 0.32em;
  line-height: 1;
  font-weight: 400;
  color: #fff;
  border: solid 1px #fff;
  display: inline-block;
  padding: 2px 4px;
}
#news-dtl .pl_news-detail .pagination--inner--list .list_txt {
  margin: 0 auto;
}
#news-dtl .pl_news-detail .pagination--inner--list .list_txt p {
  color: #fff;
  background-color: #9da6ac;
}

/*
---------------------------------------
event/work shop
---------------------------------------
*/
/*mainvisual*/
#page-event .l_contents {
  width: 90%;
  position: relative;
}
@media (max-width: 480px) {
  #page-event .l_contents {
    width: 100%;
    margin-bottom: 40px;
  }
}
#page-event .l_contents--mv-top {
  width: 100%;
  height: 767px;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 480px) {
  #page-event .l_contents--mv-top {
    height: 490px;
  }
}
#page-event .l_contents--mv-top--catch {
  position: absolute;
  bottom: -23px;
  left: 11%;
}
@media (max-width: 480px) {
  #page-event .l_contents--mv-top--catch {
    bottom: -6px;
    left: 15px;
  }
}
#page-event .l_contents--mv-top--catch h2 {
  font-size: 104px;
  color: #fff;
  letter-spacing: 0.24em;
  line-height: 1.17;
  position: relative;
}
@media (max-width: 480px) {
  #page-event .l_contents--mv-top--catch h2 {
    font-size: 40px;
    letter-spacing: 0.18em;
    line-height: 1;
  }
}
#page-event .l_contents--mv-top--catch .sub-ttl {
  font-size: 17px;
  color: #fff;
  letter-spacing: 0.9em;
  line-height: 1;
  font-weight: 400;
  position: absolute;
  top: 20%;
  left: 70%;
}
@media (max-width: 480px) {
  #page-event .l_contents--mv-top--catch .sub-ttl {
    font-size: 16px;
    letter-spacing: 0.4em;
    line-height: 1.25;
    width: 160px;
    top: 0;
    left: 200px;
  }
}
#page-event .pl_event {
  width: 100%;
  /*pagination*/
}
#page-event .pl_event--inner {
  width: 90%;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner {
    width: 92%;
    margin: 0 auto;
  }
}
#page-event .pl_event--inner--sec_ttl {
  width: 86.81%;
  margin-left: auto;
  margin-bottom: 80px;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--sec_ttl {
    width: 100%;
    margin-bottom: 40px;
  }
}
#page-event .pl_event--inner--sec_ttl h3 {
  font-size: 24px;
  letter-spacing: 0.3em;
  line-height: 2.33;
  font-weight: 500;
  text-align: center;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--sec_ttl h3 {
    font-size: 18px;
    letter-spacing: 0.1em;
  }
}
#page-event .pl_event--inner--cat_btn {
  width: 86.81%;
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--cat_btn {
    width: 100%;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--cat_btn .cat_ttl {
    display: block;
    margin-right: auto;
    margin-bottom: 5px;
  }
}
#page-event .pl_event--inner--cat_btn .cat_ttl span {
  font-size: 13px;
  letter-spacing: 0.28em;
  line-height: 1;
  font-weight: 500;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--cat_btn .cat_ttl span {
    font-size: 16px;
  }
}
#page-event .pl_event--inner--cat_btn .btn_area {
  margin-left: 20px;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--cat_btn .btn_area {
    width: 100%;
    margin-left: 0;
  }
}
#page-event .pl_event--inner--cat_btn .btn_area ul {
  display: flex;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--cat_btn .btn_area ul {
    width: 100%;
    justify-content: flex-start;
  }
}
#page-event .pl_event--inner--cat_btn .btn_area ul .btn {
  margin-left: 16px;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--cat_btn .btn_area ul .btn {
    margin-left: 0;
    margin-right: 15px;
  }
}
#page-event .pl_event--inner--cat_btn .btn_area ul .btn a {
  width: 148px;
  height: 36px;
  border: solid 1px #231815;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--cat_btn .btn_area ul .btn a {
    width: 100px;
    height: 40px;
  }
}
#page-event .pl_event--inner--cat_btn .btn_area ul .btn a span {
  font-size: 15px;
  letter-spacing: 0.28em;
  line-height: 1;
  font-weight: 500;
  display: block;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--cat_btn .btn_area ul .btn a span {
    font-size: 13px;
  }
}
#page-event .pl_event--inner--cat_btn .btn_area ul .btn a.is-active {
  background-color: #231815;
  color: #fff;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--cat_btn .btn_area ul .btn:last-child {
    margin-right: 0;
  }
}
#page-event .pl_event--inner--item {
  width: 100%;
  margin-bottom: 110px;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--item {
    margin-bottom: 60px;
  }
}
#page-event .pl_event--inner--item .bg {
  background-color: #fff;
  display: flex;
  width: 100%;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--item .bg {
    padding: 20px 0 40px;
  }
}
#page-event .pl_event--inner--item .bg .item {
  width: 90%;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--item .bg .item {
    width: 100%;
  }
}
#page-event .pl_event--inner--item .bg .item .item_inner {
  width: 86.81%;
  margin-left: auto;
  display: flex;
  padding: 45px 0px 45px 25px;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--item .bg .item .item_inner {
    width: 100%;
    padding: 0;
    display: block;
  }
}
#page-event .pl_event--inner--item .bg .item .item_inner--img {
  width: 440px;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--item .bg .item .item_inner--img {
    width: 92%;
    margin: 0 auto 20px;
  }
}
#page-event .pl_event--inner--item .bg .item .item_inner--txt_box {
  margin-left: 45px;
  width: 310px;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--item .bg .item .item_inner--txt_box {
    margin: 0 auto;
    width: 92%;
  }
}
#page-event .pl_event--inner--item .bg .item .item_inner--txt_box span {
  font-size: 11px;
  letter-spacing: 0.28em;
  line-height: 1.85;
  font-weight: 400;
}
#page-event .pl_event--inner--item .bg .item .item_inner--txt_box .label {
  font-size: 12px;
  letter-spacing: 0.28em;
  line-height: 1;
  font-weight: 500;
  color: #fff;
  background-color: #231815;
  padding: 5px 20px;
  display: inline-block;
  margin-bottom: 0.781vh;
}
#page-event .pl_event--inner--item .bg .item .item_inner--txt_box .ttl {
  font-size: 16px;
  letter-spacing: 0.28em;
  line-height: 2.7;
  font-weight: 500;
}
#page-event .pl_event--inner--item .bg .item .item_inner--txt_box .note {
  margin-bottom: 1.171vh;
}
#page-event .pl_event--inner--item .bg .item .item_inner--txt_box .txt {
  font-size: 11px;
  letter-spacing: 0.28em;
  line-height: 1.85;
  font-weight: 400;
  margin-bottom: 25px;
  text-align: justify;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--item .bg .item .item_inner--txt_box .txt {
    margin-bottom: 20px;
  }
}
#page-event .pl_event--inner--item .bg .item .item_inner--txt_box .kozuka {
  line-height: 1.65;
}
#page-event .pl_event--inner--item .bg .item .item_inner--txt_box .head:after {
  content: ":";
}
#page-event .pl_event--inner--item .bg .item .item_inner--txt_box .more_btn .btn a {
  font-size: 16px;
  letter-spacing: 0.28em;
  line-height: 2.1;
  font-weight: 400;
  position: relative;
}
#page-event .pl_event--inner--item .bg .item .item_inner--txt_box .more_btn .btn a p {
  position: relative;
}
#page-event .pl_event--inner--item .bg .item .item_inner--txt_box .more_btn .btn a p:before {
  content: "";
  position: absolute;
  border-bottom: 1px solid #231815;
  bottom: 0;
  left: 0;
  width: 240px;
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--item .bg .item .item_inner--txt_box .more_btn .btn a p:before {
    width: 100px;
  }
}
#page-event .pl_event--inner--item .bg .item .item_inner--txt_box .more_btn .btn a p:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #231815;
  bottom: 11px;
  left: 214px;
  width: 30px;
  transform: rotate(45deg);
}
@media (max-width: 480px) {
  #page-event .pl_event--inner--item .bg .item .item_inner--txt_box .more_btn .btn a p:after {
    width: 15px;
    bottom: 6px;
    left: 86px;
  }
}
#page-event .pl_event--inner--item .bg:nth-child(2n+1) {
  background-color: #E2E4E4;
}
#page-event .pl_event .pagination {
  width: 90%;
  margin-bottom: 140px;
}
@media (max-width: 480px) {
  #page-event .pl_event .pagination {
    width: 100%;
    margin-bottom: 80px;
  }
}
#page-event .pl_event .pagination--inner {
  width: 86.81%;
  margin-left: auto;
}
@media (max-width: 480px) {
  #page-event .pl_event .pagination--inner {
    width: 100%;
  }
}
#page-event .pl_event .pagination--inner--list {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media (max-width: 480px) {
  #page-event .pl_event .pagination--inner--list {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
}
#page-event .pl_event .pagination--inner--list--item {
  margin: 0 20px;
}
@media (max-width: 480px) {
  #page-event .pl_event .pagination--inner--list--item {
    margin: 0 13px;
  }
}
#page-event .pl_event .pagination--inner--list--item .page-link {
  color: #231815;
  padding: 0;
  display: inline-block;
  line-height: 1;
  position: relative;
}
#page-event .pl_event .pagination--inner--list--item .page-link .arrow-left_txt {
  padding-left: 20px;
}
#page-event .pl_event .pagination--inner--list--item .page-link .arrow-left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
#page-event .pl_event .pagination--inner--list--item .page-link .arrow-left img {
  width: 10px;
}
#page-event .pl_event .pagination--inner--list--item .page-link .arrow-right_txt {
  padding-right: 20px;
}
#page-event .pl_event .pagination--inner--list--item .page-link .arrow-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
#page-event .pl_event .pagination--inner--list--item .page-link .arrow-right img {
  width: 10px;
}
#page-event .pl_event .pagination--inner--list--item .page-link.is-active {
  border-bottom: 1px solid #231815;
}
#page-event .pl_event .pagination--inner--list--item:first-child {
  margin-left: 0;
}

/*event-detail*/
#event-dtl .l_header--logo {
  position: relative;
  top: 40px;
  left: 140px;
  z-index: 999;
}
@media (max-width: 480px) {
  #event-dtl .l_header--logo {
    position: absolute;
    top: 30px;
    left: 24px;
  }
}
#event-dtl .l_breadcrumb {
  width: 90%;
  position: relative;
  padding: 20px 0 100px;
  margin-top: 60px;
}
@media (max-width: 480px) {
  #event-dtl .l_breadcrumb {
    display: none;
  }
}
#event-dtl .pl_event-detail {
  /*detail-pagination*/
}
#event-dtl .pl_event-detail--inner--head {
  background-color: #80b3a5;
  text-align: center;
}
#event-dtl .pl_event-detail--inner--head .head_inner {
  padding: 36px 0 70px;
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--head .head_inner {
    padding: 150px 0 70px;
  }
}
#event-dtl .pl_event-detail--inner--head .head_inner .category h2 {
  font-size: 18px;
  letter-spacing: 0.28em;
  line-height: 1.85;
  font-weight: 500;
  color: #fff;
  margin-bottom: 15px;
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--head .head_inner .category h2 {
    font-size: 18px;
  }
}
#event-dtl .pl_event-detail--inner--head .head_inner .cate_ttl h3 {
  font-size: 21px;
  letter-spacing: 0.28em;
  line-height: 1.75;
  font-weight: 500;
  color: #fff;
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--article {
    margin-bottom: 40px;
  }
}
#event-dtl .pl_event-detail--inner--article .article_inner--img .img_wrap.img-s {
  width: 246px;
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--article .article_inner--img .img_wrap.img-s {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--article .article_inner--img .img_wrap.img-s:last-child {
    margin-bottom: 0px;
  }
}
#event-dtl .pl_event-detail--inner--article .article_inner--ttl {
  width: 782px;
  margin: 0 auto 40px;
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--article .article_inner--ttl {
    width: 100%;
    margin: 0 auto 20px;
  }
}
#event-dtl .pl_event-detail--inner--article .article_inner--ttl h2 {
  font-size: 22px;
  letter-spacing: 0.28em;
  line-height: 1.75;
  font-weight: 500;
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--article .article_inner--ttl h2 {
    font-size: 18px;
  }
}
#event-dtl .pl_event-detail--inner--item .item_inner {
  width: 86.81%;
  margin-left: auto;
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--item .item_inner {
    width: 92%;
    margin: 0 auto;
  }
}
#event-dtl .pl_event-detail--inner--item .item_inner .item_box {
  width: 782px;
  margin: 0 auto 40px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--item .item_inner .item_box {
    width: 100%;
    display: block;
  }
}
#event-dtl .pl_event-detail--inner--item .item_inner .item_box--left {
  width: 400px;
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--item .item_inner .item_box--left {
    width: 100%;
    margin-bottom: 20px;
  }
}
#event-dtl .pl_event-detail--inner--item .item_inner .item_box--left p {
  font-size: 12px;
  letter-spacing: 0.28em;
  line-height: 2.1;
  font-weight: 400;
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--item .item_inner .item_box--left p {
    font-size: 10px;
  }
}
#event-dtl .pl_event-detail--inner--item .item_inner .item_box--left .sec_ttl p {
  font-size: 17px;
  letter-spacing: 0.28em;
  line-height: 2.41;
  font-weight: 500;
}
#event-dtl .pl_event-detail--inner--item .item_inner .item_box--left .box_table {
  display: flex;
}
#event-dtl .pl_event-detail--inner--item .item_inner .item_box--right--inner a {
  width: 375px;
  height: 140px;
  background-color: #80b3a5;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--item .item_inner .item_box--right--inner a {
    width: 100%;
  }
}
#event-dtl .pl_event-detail--inner--item .item_inner .item_box--right--inner a .insta_logo {
  width: 30px;
}
#event-dtl .pl_event-detail--inner--item .item_inner .item_box--right--inner a .txt {
  font-size: 10px;
  letter-spacing: 0.1em;
  line-height: 1.8;
  font-weight: 400;
  margin-left: 6.58%;
  color: #fff;
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--item .item_inner .item_box--right--inner a .txt {
    margin-left: 10px;
  }
}
#event-dtl .pl_event-detail--inner--item .item_inner .item_box--right--inner a .txt .em_txt {
  font-size: 13px;
  letter-spacing: 0.1em;
  line-height: 1.38;
  font-weight: 500;
  padding-top: 5px;
}
#event-dtl .pl_event-detail--inner--item .item_inner .reserv_area {
  width: 782px;
  height: 154px;
  margin: 0 auto 80px;
  background-color: #9da6ac;
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--item .item_inner .reserv_area {
    width: 100%;
    height: 135px;
  }
}
#event-dtl .pl_event-detail--inner--item .item_inner .reserv_area--inner {
  color: #fff;
  height: 154px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  margin: 0 70px;
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--item .item_inner .reserv_area--inner {
    margin: 0;
    height: 135px;
  }
}
#event-dtl .pl_event-detail--inner--item .item_inner .reserv_area--inner .txt {
  font-size: 16px;
  letter-spacing: 0.26em;
  line-height: 1;
  font-weight: 500;
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--item .item_inner .reserv_area--inner .txt {
    font-size: 16px;
  }
}
#event-dtl .pl_event-detail--inner--item .item_inner .reserv_area--inner .tel {
  font-size: 41px;
  letter-spacing: 0.12em;
  line-height: 1;
  font-weight: 500;
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--item .item_inner .reserv_area--inner .tel {
    font-size: 37px;
    margin-bottom: 10px;
  }
}
#event-dtl .pl_event-detail--inner--item .item_inner .reserv_area--inner .time {
  font-size: 11px;
  letter-spacing: 0.46em;
  line-height: 1;
  font-weight: 500;
}
#event-dtl .pl_event-detail--inner--item .item_inner .reserv_area--inner .txt_en {
  font-size: 16px;
  letter-spacing: 0.26em;
  line-height: 1;
  font-weight: 500;
}
@media (max-width: 480px) {
  #event-dtl .pl_event-detail--inner--item .item_inner .reserv_area--inner .txt_en {
    font-size: 16px;
  }
}
#event-dtl .pl_event-detail .pagination--inner--list .list_txt {
  margin: 0 auto;
}
#event-dtl .pl_event-detail .pagination--inner--list .list_txt p {
  color: #fff;
  background-color: #9da6ac;
}

/*
---------------------------------------
company
---------------------------------------
*/
/*mainvisual*/
#page-company .bg_green {
  width: 100%;
  background-color: #80b3a5;
}
#page-company .bg_gray {
  width: 100%;
  background-color: #b2b8b9;
}
#page-company .l_contents {
  width: 90%;
  position: relative;
}
@media (max-width: 480px) {
  #page-company .l_contents {
    width: 100%;
    margin-bottom: 60px;
  }
}
#page-company .l_contents--mv-top {
  width: 100%;
  height: 767px;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 480px) {
  #page-company .l_contents--mv-top {
    height: 490px;
  }
}
#page-company .l_contents--mv-top--catch {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#page-company .l_contents--mv-top--catch h2 {
  font-size: 7.578vw;
  color: #fff;
  letter-spacing: 0.24em;
  line-height: 1;
  position: relative;
}
@media (max-width: 480px) {
  #page-company .l_contents--mv-top--catch h2 {
    font-size: 40px;
  }
}
#page-company .l_contents--mv-top--catch .sub-ttl {
  font-size: 1.171vw;
  color: #fff;
  letter-spacing: 0.9em;
  line-height: 1;
  font-weight: 400;
  position: absolute;
  bottom: -3.47vh;
  left: 50%;
  transform: translate(0%, -50%);
}
@media (max-width: 480px) {
  #page-company .l_contents--mv-top--catch .sub-ttl {
    font-size: 16px;
  }
}
#page-company .pl_company {
  width: 100%;
  margin-bottom: 120px;
}
@media (max-width: 480px) {
  #page-company .pl_company {
    margin-bottom: 0;
  }
}
#page-company .pl_company--inner {
  width: 90%;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner {
    width: 92%;
    margin: 0 auto;
    padding-bottom: 40px;
  }
}
#page-company .pl_company--inner--link_area {
  width: 86.81%;
  margin-left: auto;
  margin-bottom: 80px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--link_area {
    width: 100%;
    margin: 0 auto;
  }
}
#page-company .pl_company--inner--link_area .link_btn {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--link_area .link_btn {
    margin-bottom: 20px;
  }
}
#page-company .pl_company--inner--link_area .link_btn--item {
  width: 250px;
  border-bottom: 1px solid #231815;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--link_area .link_btn--item {
    width: 100%;
    margin-bottom: 40px;
  }
}
#page-company .pl_company--inner--link_area .link_btn--item .btn {
  display: block;
}
#page-company .pl_company--inner--link_area .link_btn--item .btn span {
  display: block;
  text-align: center;
}
#page-company .pl_company--inner--link_area .link_btn--item .btn .txt {
  font-size: 15px;
  letter-spacing: 0.28em;
  line-height: 1;
  font-weight: 500;
}
#page-company .pl_company--inner--link_area .link_btn--item .btn .img {
  width: 15px;
  display: block;
  margin: 0 auto 30px;
}
#page-company .pl_company--inner--staff {
  width: 90%;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff {
    width: 100%;
  }
}
#page-company .pl_company--inner--staff .txt {
  color: #fff;
  font-weight: 500;
}
#page-company .pl_company--inner--staff--item {
  width: 86.81%;
  margin-left: auto;
  padding: 60px 0 70px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
}
#page-company .pl_company--inner--staff--item .staff_item01 {
  margin-bottom: 110px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item01 {
    margin-bottom: 60px;
  }
}
#page-company .pl_company--inner--staff--item .staff_item01 .ttl {
  font-size: 31px;
  letter-spacing: 0.28em;
  line-height: 2;
  margin-bottom: 60px;
  position: relative;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item01 .ttl {
    font-size: 26px;
    padding-top: 20px;
    text-align: center;
    padding-left: 10px;
    margin-bottom: 40px;
  }
}
#page-company .pl_company--inner--staff--item .staff_item01 .ttl:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #fff;
  width: 153px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item01 .ttl:after {
    left: 50%;
    transform: translateX(-50%);
    width: 135px;
  }
}
#page-company .pl_company--inner--staff--item .staff_item01--inner {
  display: flex;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item01--inner {
    display: block;
  }
}
#page-company .pl_company--inner--staff--item .staff_item01--inner .img {
  width: 400px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item01--inner .img {
    width: 100%;
  }
}
#page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box {
  width: 475px;
  margin-left: 20px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box {
    width: 100%;
    margin-left: 0;
  }
}
#page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .name_item {
  margin-bottom: 30px;
}
#page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .name_item .name_ja {
  font-size: 31px;
  letter-spacing: 0.28em;
  line-height: 2;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .name_item .name_ja {
    font-size: 22px;
  }
}
#page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .name_item .name_en {
  font-size: 11px;
  letter-spacing: 0.28em;
  line-height: 1;
}
#page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .ttl_item {
  margin-bottom: 25px;
}
#page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .ttl_item p {
  font-size: 21px;
  letter-spacing: 0.1em;
  line-height: 1.95;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .ttl_item p {
    font-size: 16px;
  }
}
#page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .txt_area p {
  font-size: 11px;
  letter-spacing: 0.15em;
  line-height: 2.272;
  margin-bottom: 30px;
  text-align: justify;
}
#page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .txt_area .btn_area {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
#page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .txt_area .btn_area .y_tube {
  width: 228px;
  height: 83px;
  border: solid 1px #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .txt_area .btn_area .y_tube {
    width: 160px;
    height: 75px;
  }
}
#page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .txt_area .btn_area .y_tube .txt {
  font-size: 16px;
  letter-spacing: 0.2em;
  line-height: 1.375;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .txt_area .btn_area .y_tube .txt {
    font-size: 14px;
  }
}
#page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .txt_area .btn_area .y_tube .icon {
  width: 52px;
  margin-left: 20px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .txt_area .btn_area .y_tube .icon {
    width: 40px;
    margin-left: 5px;
  }
}
#page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .txt_area .btn_area .sns_btn {
  width: 228px;
  height: 83px;
  border: solid 1px #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .txt_area .btn_area .sns_btn {
    width: 160px;
    height: 75px;
  }
}
#page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .txt_area .btn_area .sns_btn .txt {
  font-size: 16px;
  letter-spacing: 0.2em;
  line-height: 1.375;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .txt_area .btn_area .sns_btn .txt {
    font-size: 14px;
  }
}
#page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .txt_area .btn_area .sns_btn .icon {
  width: 18px;
  margin-left: 15px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item01--inner .txt_box .txt_area .btn_area .sns_btn .icon {
    margin-left: 5px;
  }
}
#page-company .pl_company--inner--staff--item .staff_item02 {
  margin-top: -60px;
  padding-top: 60px;
}
#page-company .pl_company--inner--staff--item .staff_item02 .ttl {
  font-size: 31px;
  letter-spacing: 0.28em;
  line-height: 2;
  position: relative;
  margin-bottom: 60px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item02 .ttl {
    font-size: 26px;
    padding-top: 20px;
    text-align: center;
    padding-left: 10px;
    margin-bottom: 40px;
  }
}
#page-company .pl_company--inner--staff--item .staff_item02 .ttl:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #fff;
  width: 196px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item02 .ttl:after {
    left: 50%;
    transform: translateX(-50%);
    width: 190px;
  }
}
#page-company .pl_company--inner--staff--item .staff_item02--inner {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item02--inner {
    display: block;
  }
}
#page-company .pl_company--inner--staff--item .staff_item02--inner .staff_box {
  width: 460px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item02--inner .staff_box {
    width: 100%;
    margin-bottom: 40px;
  }
}
#page-company .pl_company--inner--staff--item .staff_item02--inner .staff_box--inner {
  display: flex;
  margin-bottom: 30px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item02--inner .staff_box--inner {
    display: block;
  }
}
#page-company .pl_company--inner--staff--item .staff_item02--inner .staff_box--inner .img {
  width: 230px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item02--inner .staff_box--inner .img {
    width: 100%;
    margin-bottom: 20px;
  }
}
#page-company .pl_company--inner--staff--item .staff_item02--inner .staff_box--inner .name_item {
  align-self: flex-end;
  margin-left: 30px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item02--inner .staff_box--inner .name_item {
    display: flex;
    margin-left: 0;
  }
}
#page-company .pl_company--inner--staff--item .staff_item02--inner .staff_box--inner .name_item .name_ttl {
  display: block;
  font-size: 12px;
  letter-spacing: 0.28em;
  line-height: 1.2;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item02--inner .staff_box--inner .name_item .name_ttl {
    display: none;
  }
}
#page-company .pl_company--inner--staff--item .staff_item02--inner .staff_box--inner .name_item .name_ja {
  display: block;
  font-size: 31px;
  letter-spacing: 0.28em;
  line-height: 2;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item02--inner .staff_box--inner .name_item .name_ja {
    font-size: 22px;
  }
}
#page-company .pl_company--inner--staff--item .staff_item02--inner .staff_box--inner .name_item .name_en {
  display: block;
  font-size: 11px;
  letter-spacing: 0.28em;
  line-height: 1;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item02--inner .staff_box--inner .name_item .name_en {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
}
#page-company .pl_company--inner--staff--item .staff_item02--inner .staff_box .txt_area p {
  font-size: 13px;
  letter-spacing: 0.28em;
  line-height: 1.92;
  margin-bottom: 30px;
  text-align: justify;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--staff--item .staff_item02--inner .staff_box .txt_area p:last-child {
    margin-bottom: 0;
  }
}
#page-company .pl_company--inner--staff--item .staff_item02--inner .staff_box:last-child {
  margin-bottom: 0;
}
#page-company .pl_company--inner--info {
  width: 90%;
  padding: 80px 0 100px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--info {
    width: 100%;
    padding: 40px 0 0;
  }
}
#page-company .pl_company--inner--info--inner {
  width: 86.81%;
  margin-left: auto;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--info--inner {
    width: 100%;
    margin: 0 auto;
  }
}
#page-company .pl_company--inner--info--inner .ttl {
  font-size: 31px;
  letter-spacing: 0.28em;
  line-height: 2;
  margin-bottom: 70px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--info--inner .ttl {
    font-size: 26px;
    margin-bottom: 20px;
    text-align: center;
  }
}
#page-company .pl_company--inner--info--inner--item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
#page-company .pl_company--inner--info--inner--item .txt_area {
  width: 590px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--info--inner--item .txt_area {
    width: 100%;
    margin-bottom: 30px;
  }
}
#page-company .pl_company--inner--info--inner--item .txt_area dl {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  border-bottom: 1px solid #9da6ac;
  padding: 24px 0;
  letter-spacing: 0.32em;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--info--inner--item .txt_area dl {
    padding: 20px 0;
  }
}
#page-company .pl_company--inner--info--inner--item .txt_area dl dt {
  width: 130px;
  font-weight: 700;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--info--inner--item .txt_area dl dt {
    width: 100px;
  }
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--info--inner--item .txt_area dl dd {
    width: calc(100% - 100px);
  }
}
#page-company .pl_company--inner--info--inner--item .txt_area dl:first-child {
  border-top: 1px solid #9da6ac;
}
#page-company .pl_company--inner--info--inner--item .img_area {
  margin-left: 30px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--info--inner--item .img_area {
    margin-left: 0;
  }
}
#page-company .pl_company--inner--info--inner--item .img_area .img {
  width: 277px;
  margin-bottom: 30px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--info--inner--item .img_area .img {
    width: 100%;
  }
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--info--inner--item .img_area .img:last-child {
    margin-bottom: 0;
  }
}
#page-company .pl_company--inner--history {
  width: 90%;
  padding: 80px 0 100px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--history {
    width: 100%;
    padding: 40px 0 0;
  }
}
#page-company .pl_company--inner--history--inner {
  width: 86.81%;
  margin-left: auto;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--history--inner {
    width: 100%;
    margin: 0 auto;
  }
}
#page-company .pl_company--inner--history--inner .ttl {
  font-size: 31px;
  letter-spacing: 0.28em;
  line-height: 2;
  margin-bottom: 70px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--history--inner .ttl {
    font-size: 26px;
    margin-bottom: 20px;
    text-align: center;
  }
}
#page-company .pl_company--inner--history--inner--item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
#page-company .pl_company--inner--history--inner--item .txt_area {
  width: 590px;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--history--inner--item .txt_area {
    width: 100%;
  }
}
#page-company .pl_company--inner--history--inner--item .txt_area dl {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  border-bottom: 1px solid #231815;
  padding: 24px 0;
  letter-spacing: 0.32em;
}
#page-company .pl_company--inner--history--inner--item .txt_area dl dt {
  width: 130px;
  font-weight: 700;
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--history--inner--item .txt_area dl dt {
    width: 100px;
  }
}
@media (max-width: 480px) {
  #page-company .pl_company--inner--history--inner--item .txt_area dl dd {
    width: calc(100% - 100px);
  }
}
#page-company .pl_company--inner--history--inner--item .txt_area dl:first-child {
  border-top: 1px solid #231815;
}

/*
---------------------------------------
contact
---------------------------------------
*/
/*mainvisual*/
#page-contact .l_contents {
  width: 90%;
  position: relative;
}
@media (max-width: 480px) {
  #page-contact .l_contents {
    width: 100%;
    margin-bottom: 40px;
  }
}
#page-contact .l_contents--mv-top {
  width: 100%;
  height: 767px;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 480px) {
  #page-contact .l_contents--mv-top {
    height: 490px;
  }
}
#page-contact .l_contents--mv-top--catch {
  position: absolute;
  bottom: -23px;
  left: 160px;
}
@media (max-width: 480px) {
  #page-contact .l_contents--mv-top--catch {
    left: 15px;
    bottom: -6px;
  }
}
#page-contact .l_contents--mv-top--catch h2 {
  font-size: 104px;
  color: #fff;
  letter-spacing: 0.24em;
  line-height: 1.17;
  position: relative;
}
@media (max-width: 480px) {
  #page-contact .l_contents--mv-top--catch h2 {
    font-size: 40px;
    letter-spacing: 0.14em;
    line-height: 1;
    font-weight: 500;
  }
}
#page-contact .l_contents--mv-top--catch .sub-ttl {
  font-size: 17px;
  color: #fff;
  letter-spacing: 0.9em;
  line-height: 1;
  font-weight: 400;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 720px;
  width: 200px;
}
@media (max-width: 480px) {
  #page-contact .l_contents--mv-top--catch .sub-ttl {
    font-size: 16px;
    letter-spacing: 0.1em;
    line-height: 1.25;
    font-weight: 500;
    left: 240px;
    top: auto;
    transform: inherit;
    bottom: 2px;
    width: 110px;
  }
}
#page-contact .pl_contact {
  margin-bottom: 180px;
  width: 92%;
  margin: 0 auto;
}
#page-contact .pl_contact--inner--item .reserv_area {
  width: 900px;
  height: 160px;
  margin: 0 auto 80px;
  background-color: #80b3a5;
}
@media (max-width: 480px) {
  #page-contact .pl_contact--inner--item .reserv_area {
    width: 100%;
    height: 135px;
    margin: 0 auto 40px;
  }
}
#page-contact .pl_contact--inner--item .reserv_area--inner {
  color: #fff;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5.46%;
}
@media (max-width: 480px) {
  #page-contact .pl_contact--inner--item .reserv_area--inner {
    height: 135px;
    padding: 20px 0;
  }
}
#page-contact .pl_contact--inner--item .reserv_area--inner .txtarea {
  text-align: center;
  margin-left: 16px;
}
#page-contact .pl_contact--inner--item .reserv_area--inner .txt {
  font-size: 23px;
  letter-spacing: 0.26em;
  line-height: 1;
  font-weight: 500;
  display: block;
  margin-bottom: 15px;
}
@media (max-width: 480px) {
  #page-contact .pl_contact--inner--item .reserv_area--inner .txt {
    font-size: 16px;
  }
}
#page-contact .pl_contact--inner--item .reserv_area--inner .time {
  font-size: 15px;
  letter-spacing: 0.46em;
  line-height: 1;
  font-weight: 500;
  display: block;
}
@media (max-width: 480px) {
  #page-contact .pl_contact--inner--item .reserv_area--inner .time {
    font-size: 14px;
  }
}
#page-contact .pl_contact--inner--item .reserv_area--inner .mobile_img {
  width: 37px;
}
#page-contact .pl_contact--inner--item .reserv_area--inner .mobile_img img {
  width: 100%;
}
#page-contact .pl_contact--inner--item .form_step {
  margin-bottom: 80px;
}
@media (max-width: 480px) {
  #page-contact .pl_contact--inner--item .form_step {
    margin-bottom: 40px;
  }
}
#page-contact .pl_contact--inner--item .form_step .step_ttl h2 {
  font-size: 23px;
  letter-spacing: 0.26em;
  line-height: 2.79;
  font-weight: 500;
  text-align: center;
}
@media (max-width: 480px) {
  #page-contact .pl_contact--inner--item .form_step .step_ttl h2 {
    font-size: 14px;
  }
}
#page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap {
  width: 900px;
  height: 50px;
  margin: 0 auto 80px;
  display: flex;
  position: relative;
}
@media (max-width: 480px) {
  #page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap {
    width: 100%;
    margin: 0 auto 40px;
  }
}
#page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap .ribbon {
  display: block;
  width: 300px;
  height: 50px;
  position: relative;
}
@media (max-width: 480px) {
  #page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap .ribbon {
    width: 33.333%;
  }
}
#page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap .ribbon p {
  display: flex;
  width: 300px;
  height: 50px;
  clip: rect(0px 340px 50px 0px);
  position: absolute;
  color: #fff;
  align-items: center;
  justify-content: center;
}
@media (max-width: 480px) {
  #page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap .ribbon p {
    width: 123px;
    flex-direction: column;
    align-content: center;
  }
}
#page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap .ribbon p span {
  display: block;
}
#page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap .ribbon p .num {
  font-size: 10px;
  letter-spacing: 0.26em;
  line-height: 1;
  font-weight: 500;
}
@media (max-width: 480px) {
  #page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap .ribbon p .num {
    font-size: 9px;
    margin-bottom: 5px;
  }
}
#page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap .ribbon p .txt {
  font-size: 18px;
  letter-spacing: 0.26em;
  line-height: 1;
  font-weight: 500;
  margin-left: 10px;
}
@media (max-width: 480px) {
  #page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap .ribbon p .txt {
    font-size: 14px;
    margin-left: 0;
  }
}
#page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap .ribbon:first-child {
  background: #727171;
}
#page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap .ribbon:nth-child(2) {
  background: #949495;
}
#page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap .ribbon:nth-child(3) {
  background: #b5b5b6;
}
#page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap .ribbon:first-child :after {
  border-left: 30px solid #727171;
}
#page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap .ribbon:nth-child(2) :after {
  border-left: 30px solid #949495;
}
#page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap .ribbon p:after {
  content: "";
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  position: absolute;
  top: -50%;
  left: 100%;
  z-index: 1;
}
@media (max-width: 480px) {
  #page-contact .pl_contact--inner--item .form_step .step_ribbon .ribbon_wrap .ribbon p:after {
    left: 93%;
  }
}
#page-contact .pl_contact .form__cont {
  width: 900px;
  margin: 0 auto;
}
@media (max-width: 480px) {
  #page-contact .pl_contact .form__cont {
    width: 100%;
  }
}
#page-contact .pl_contact .form__cont dl .row.table {
  margin-bottom: 40px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media (max-width: 480px) {
  #page-contact .pl_contact .form__cont dl .row.table {
    border-bottom: 1px dashed #9fa0a0;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }
}
#page-contact .pl_contact .form__cont dl .row.table dt {
  font-size: 15px;
  letter-spacing: 0.32em;
  font-weight: 400;
  width: 160px;
}
@media (max-width: 480px) {
  #page-contact .pl_contact .form__cont dl .row.table dt {
    display: flex;
    line-height: 1;
    margin-bottom: 10px;
    width: 100%;
  }
}
#page-contact .pl_contact .form__cont dl .row.table dt .req-label {
  color: #fff;
  width: 40px;
  height: 16px;
  font-size: 11px;
  letter-spacing: 0.32em;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 480px) {
  #page-contact .pl_contact .form__cont dl .row.table dt .req-label {
    margin-right: 10px;
  }
}
#page-contact .pl_contact .form__cont dl .row.table dt .label_gray {
  background-color: #9da6ac;
}
#page-contact .pl_contact .form__cont dl .row.table dt .label_green {
  background-color: #80b3a5;
}
@media (max-width: 480px) {
  #page-contact .pl_contact .form__cont dl .row.table .cat-ttl {
    margin-bottom: 25px;
  }
}
#page-contact .pl_contact .form__cont dl .row.table dd {
  width: 490px;
  display: flex;
  align-items: center;
}
@media (max-width: 480px) {
  #page-contact .pl_contact .form__cont dl .row.table dd {
    width: 100%;
  }
}
#page-contact .pl_contact .form__cont dl .row.table dd .yuubin_icon {
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  margin-right: 15px;
}
#page-contact .pl_contact .form__cont dl .row.table dd .yuubin_txt {
  font-size: 8px;
  letter-spacing: 0.1em;
  line-height: 1.75;
  font-weight: 400;
  margin-left: 15px;
  width: 100%;
}
#page-contact .pl_contact .form__cont dl .row.table .yuubin input {
  width: 190px;
}
@media (max-width: 480px) {
  #page-contact .pl_contact .form__cont dl .row.table .yuubin input {
    width: 100%;
  }
}
#page-contact .pl_contact .form__cont dl .row.table .visit {
  width: 490px;
}
#page-contact .pl_contact .form__cont dl .row.table .d-t {
  justify-content: space-between;
}
#page-contact .pl_contact .form__cont dl .row.table .date {
  width: 270px;
}
#page-contact .pl_contact .form__cont dl .row.table .time {
  width: 205px;
}
@media (max-width: 480px) {
  #page-contact .pl_contact .form__cont dl .row.table:last-child {
    border-bottom: none;
  }
}
#page-contact .pl_contact .submit-box {
  text-align: center;
  margin-bottom: 60px;
}
#page-contact .pl_contact .submit-box .submit-btn {
  font-size: 18px;
  letter-spacing: 0.26em;
  line-height: 3.45;
  font-weight: 500;
  color: #fff;
  background-color: #727171;
  width: 375px;
  height: 70px;
}
@media (max-width: 480px) {
  #page-contact .pl_contact .submit-box .submit-btn {
    width: 275px;
    height: 65px;
  }
}
#page-contact .pl_contact .alert_txt {
  text-align: center;
}
#page-contact .pl_contact .alert_txt p {
  font-size: 11px;
  letter-spacing: 0.1em;
  line-height: 1.75;
  font-weight: 400;
  color: #727171;
  margin-bottom: 60px;
}
@media (max-width: 480px) {
  #page-contact input[type=radio] + label, #page-contact input[type=checkbox] + label {
    padding-left: 30px;
  }
}
@media (max-width: 480px) {
  #page-contact input[type=radio] + label::before, #page-contact input[type=checkbox] + label::before {
    width: 21px;
    height: 21px;
  }
}

/*
---------------------------------------
contact-confirm
---------------------------------------
*/
#contact .l_contents {
  width: 90%;
  position: relative;
  margin-bottom: 100px;
}
@media (max-width: 480px) {
  #contact .l_contents {
    width: 100%;
    margin-bottom: 40px;
  }
}
#contact .l_contents--mv-top {
  width: 100%;
  height: 767px;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 480px) {
  #contact .l_contents--mv-top {
    height: 490px;
  }
}
#contact .l_contents--mv-top--catch {
  position: absolute;
  bottom: -23px;
  left: 160px;
}
@media (max-width: 480px) {
  #contact .l_contents--mv-top--catch {
    left: 15px;
    bottom: -6px;
  }
}
#contact .l_contents--mv-top--catch h2 {
  font-size: 104px;
  color: #fff;
  letter-spacing: 0.24em;
  line-height: 1.17;
  position: relative;
}
@media (max-width: 480px) {
  #contact .l_contents--mv-top--catch h2 {
    font-size: 40px;
    letter-spacing: 0.14em;
    line-height: 1;
    font-weight: 500;
  }
}
#contact .l_contents--mv-top--catch .sub-ttl {
  font-size: 17px;
  color: #fff;
  letter-spacing: 0.9em;
  line-height: 1;
  font-weight: 400;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 720px;
  width: 200px;
}
@media (max-width: 480px) {
  #contact .l_contents--mv-top--catch .sub-ttl {
    font-size: 16px;
    letter-spacing: 0.1em;
    line-height: 1.25;
    font-weight: 500;
    left: 240px;
    top: auto;
    transform: inherit;
    bottom: 2px;
    width: 110px;
  }
}
#contact .sec-mail {
  margin-bottom: 180px;
  width: 92%;
  margin: 0 auto;
}
#contact .sec-mail .txt_area {
  margin-bottom: 60px;
}
#contact .sec-mail .txt_area .ttl {
  font-size: 23px;
  letter-spacing: 0.26em;
  line-height: 2.79;
  font-weight: 500;
  text-align: center;
}
#contact .sec-mail .form__cont {
  width: 900px;
  margin: 0 auto;
}
@media (max-width: 480px) {
  #contact .sec-mail .form__cont {
    width: 100%;
  }
}
#contact .sec-mail .form__cont dl .row.table {
  margin-bottom: 40px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media (max-width: 480px) {
  #contact .sec-mail .form__cont dl .row.table {
    border-bottom: 1px dashed #9fa0a0;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }
}
#contact .sec-mail .form__cont dl .row.table dt {
  font-size: 15px;
  letter-spacing: 0.32em;
  font-weight: 400;
  width: 160px;
}
@media (max-width: 480px) {
  #contact .sec-mail .form__cont dl .row.table dt {
    display: flex;
    line-height: 1;
    margin-bottom: 10px;
    width: 100%;
  }
}
#contact .sec-mail .form__cont dl .row.table dt .req-label {
  background-color: #9fa0a0;
  color: #fff;
  width: 40px;
  height: 16px;
  font-size: 11px;
  letter-spacing: 0.32em;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 480px) {
  #contact .sec-mail .form__cont dl .row.table dt .req-label {
    margin-right: 10px;
  }
}
@media (max-width: 480px) {
  #contact .sec-mail .form__cont dl .row.table .cat-ttl {
    margin-bottom: 25px;
  }
}
#contact .sec-mail .form__cont dl .row.table dd {
  width: 490px;
  display: flex;
  align-items: center;
}
@media (max-width: 480px) {
  #contact .sec-mail .form__cont dl .row.table dd {
    width: 100%;
  }
}
#contact .sec-mail .form__cont dl .row.table dd .yuubin_icon {
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  margin-right: 15px;
}
#contact .sec-mail .form__cont dl .row.table dd .yuubin_txt {
  font-size: 8px;
  letter-spacing: 0.1em;
  line-height: 1.75;
  font-weight: 400;
  margin-left: 15px;
  width: 100%;
}
#contact .sec-mail .form__cont dl .row.table .yuubin input {
  width: 190px;
}
@media (max-width: 480px) {
  #contact .sec-mail .form__cont dl .row.table .yuubin input {
    width: 100%;
  }
}
#contact .sec-mail .form__cont dl .row.table .visit {
  width: 490px;
}
#contact .sec-mail .form__cont dl .row.table .d-t {
  justify-content: space-between;
}
#contact .sec-mail .form__cont dl .row.table .date {
  width: 270px;
}
#contact .sec-mail .form__cont dl .row.table .time {
  width: 205px;
}
@media (max-width: 480px) {
  #contact .sec-mail .form__cont dl .row.table:last-child {
    border-bottom: none;
  }
}
#contact .sec-mail .submit-box {
  text-align: center;
  margin-bottom: 60px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media (max-width: 480px) {
  #contact .sec-mail .submit-box {
    justify-content: center;
  }
}
#contact .sec-mail .submit-box .submit-btn {
  font-size: 18px;
  letter-spacing: 0.26em;
  line-height: 3.45;
  font-weight: 500;
  color: #fff;
  background-color: #727171;
  width: 375px;
  height: 70px;
  display: block;
}
@media (max-width: 480px) {
  #contact .sec-mail .submit-box .submit-btn {
    width: 275px;
    height: 65px;
    margin-bottom: 30px;
  }
}
#contact .sec-mail .alert_txt {
  text-align: center;
}
#contact .sec-mail .alert_txt p {
  font-size: 11px;
  letter-spacing: 0.1em;
  line-height: 1.75;
  font-weight: 400;
  color: #727171;
  margin-bottom: 60px;
}
@media (max-width: 480px) {
  #contact input[type=radio] + label, #contact input[type=checkbox] + label {
    padding-left: 30px;
  }
}
@media (max-width: 480px) {
  #contact input[type=radio] + label::before, #contact input[type=checkbox] + label::before {
    width: 21px;
    height: 21px;
  }
}

/*
---------------------------------------
model_plan
---------------------------------------
*/
/*mainvisual*/
#page-model_plan .l_contents {
  width: 90%;
  position: relative;
}
@media (max-width: 480px) {
  #page-model_plan .l_contents {
    width: 100%;
    margin-bottom: 40px;
  }
}
#page-model_plan .l_contents--mv-top {
  width: 100%;
  height: 767px;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 480px) {
  #page-model_plan .l_contents--mv-top {
    height: 490px;
  }
}
#page-model_plan .l_contents--mv-top--catch {
  position: absolute;
  bottom: -15px;
  left: 140px;
}
@media (max-width: 480px) {
  #page-model_plan .l_contents--mv-top--catch {
    left: 15px;
    bottom: 0;
  }
}
#page-model_plan .l_contents--mv-top--catch h2 {
  font-size: 94px;
  color: #231815;
  letter-spacing: 0.16em;
  line-height: 1;
  position: relative;
}
@media (max-width: 480px) {
  #page-model_plan .l_contents--mv-top--catch h2 {
    font-size: 40px;
    letter-spacing: 0.2em;
    color: #fff;
  }
}
#page-model_plan .l_contents--mv-top--catch h2 .img {
  width: 311px;
  position: absolute;
  top: -114px;
  left: 0;
}
@media (max-width: 480px) {
  #page-model_plan .l_contents--mv-top--catch h2 .img {
    width: 125px;
    top: -44px;
    left: 0;
  }
}
#page-model_plan .l_breadcrumb--inner--item {
  color: #fff;
}
#page-model_plan .l_breadcrumb--inner--item:after {
  background-image: linear-gradient(to left top, transparent 49.5%, white 49.5%, white 50.5%, transparent 50.5%);
}
#page-model_plan .pl_model_plan {
  width: 100%;
}
#page-model_plan .pl_model_plan--intro {
  margin-bottom: 100px;
}
@media (max-width: 480px) {
  #page-model_plan .pl_model_plan--intro {
    margin-bottom: 60px;
  }
}
#page-model_plan .pl_model_plan--intro .bg_green {
  position: relative;
}
#page-model_plan .pl_model_plan--intro .bg_green:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #80b3a5;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 230px;
  margin-top: -230px;
  z-index: -1;
}
@media (max-width: 480px) {
  #page-model_plan .pl_model_plan--intro .bg_green:after {
    padding-top: 40px;
    margin-top: -40px;
  }
}
#page-model_plan .pl_model_plan--intro--txt_area {
  width: 90%;
  padding-bottom: 80px;
}
@media (max-width: 480px) {
  #page-model_plan .pl_model_plan--intro--txt_area {
    width: 92%;
    margin: 0 auto;
    padding-bottom: 40px;
  }
}
#page-model_plan .pl_model_plan--intro--txt_area .intro_ttl {
  width: 100%;
}
#page-model_plan .pl_model_plan--intro--txt_area .intro_ttl h2 {
  text-align: center;
  margin-bottom: 60px;
}
@media (max-width: 480px) {
  #page-model_plan .pl_model_plan--intro--txt_area .intro_ttl h2 {
    margin-bottom: 40px;
  }
}
#page-model_plan .pl_model_plan--intro--txt_area .intro_ttl h2 .img {
  width: 850px;
}
@media (max-width: 480px) {
  #page-model_plan .pl_model_plan--intro--txt_area .intro_ttl h2 .img {
    width: 100%;
  }
}
#page-model_plan .pl_model_plan--intro--txt_area .intro_txt {
  width: auto;
  margin: 0 auto;
}
@media (max-width: 480px) {
  #page-model_plan .pl_model_plan--intro--txt_area .intro_txt {
    width: 100%;
  }
}
#page-model_plan .pl_model_plan--intro--txt_area .intro_txt p {
  font-size: 19px;
  letter-spacing: 0.22em;
  line-height: 2.11;
  font-weight: 500;
  color: #fff;
  text-align: center;
}
@media (max-width: 480px) {
  #page-model_plan .pl_model_plan--intro--txt_area .intro_txt p {
    font-size: 14px;
    line-height: 1.75;
    text-align: justify;
  }
}
#page-model_plan .pl_model_plan--intro--img_area .img_inner {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
#page-model_plan .pl_model_plan--intro--img_area .img_inner li {
  width: 20%;
}
@media (max-width: 480px) {
  #page-model_plan .pl_model_plan--intro--img_area .img_inner li {
    width: 50%;
  }
}
/*
---------------------------------------
coming_soon
---------------------------------------
*/
#page-comingsoon .pl_coming {
  width: 90%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}
#page-comingsoon .pl_coming--intro {
  margin-bottom: 120px;
}
#page-comingsoon .pl_coming--intro .img_logo {
  margin: 160px auto 60px;
  width: 200px;
}
#page-comingsoon .pl_coming--intro .ttl {
  text-align: center;
  margin-bottom: 40px;
}
#page-comingsoon .pl_coming--intro .ttl p {
  font-size: 32px;
  letter-spacing: 0.075em;
  line-height: 1.85;
  font-weight: 500;
}
#page-comingsoon .pl_coming--intro .txt {
  text-align: center;
}
#page-comingsoon .pl_coming--intro .txt p {
  font-size: 18px;
  letter-spacing: 0.175em;
  line-height: 2;
  font-weight: 500;
}

/*
---------------------------------------
header
---------------------------------------
*/
.l_header {
  position: relative;
}
.l_header--logo {
  position: absolute;
  top: 40px;
  left: 140px;
  z-index: 999;
}
@media (max-width: 480px) {
  .l_header--logo {
    top: 30px;
    left: 24px;
  }
}
.l_header--logo .img {
  width: 183px;
}
@media (max-width: 480px) {
  .l_header--logo .img {
    width: 160px;
  }
}

/*
---------------------------------------
a-side
---------------------------------------
*/
#SIDE {
  width: 10%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  overflow: hidden;
}
@media (max-width: 480px) {
  #SIDE {
    display: none;
  }
}
#SIDE .l_a_side {
  text-align: center;
  overflow: hidden;
}
#SIDE .l_a_side a:hover {
  opacity: 0.6;
}
#SIDE .l_a_side--menu {
  position: absolute;
  top: 40px;
  width: 28px;
  height: 28px;
  left: 50%;
  transform: translateX(-50%);
}
#SIDE .l_a_side--side_nav {
  padding-top: 68px;
}
#SIDE .l_a_side--side_nav ul > li > a {
  margin: 0 auto;
}
#SIDE .l_a_side--side_nav .nav {
  position: relative;
  width: 100%;
  height: 220px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
#SIDE .l_a_side--side_nav .nav:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 1px solid #231815;
  width: 35px;
}
#SIDE .l_a_side--side_nav .nav:last-child:after {
  border-bottom: none;
}
#SIDE .l_a_side--side_nav .nav_ja {
  font-size: 14px;
  letter-spacing: 0.32em;
  line-height: 1.75;
  font-weight: 400;
}
#SIDE .l_a_side--side_nav .nav_en {
  font-size: 11px;
  letter-spacing: 0.32em;
  line-height: 1.75;
  font-weight: 400;
}
#SIDE .l_a_side--side_nav .nav_en1 {
  font-size: 11px;
  margin-top: -72px;
}
#SIDE .l_a_side--side_nav .nav_en2 {
  font-size: 11px;
  margin-top: -17px;
}
#SIDE .l_a_side--side_nav .nav_en3 {
  font-size: 11px;
  margin-top: 0px;
}
#SIDE .l_a_side--sns_btn {
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
#SIDE .l_a_side--sns_btn .insta {
  width: 18px;
}
#SIDE .l_a_side--sns_btn .fb {
  width: 10px;
  margin-left: 14px;
}
#SIDE .l_a_side .kozuka {
  font-weight: 400;
}

/*
---------------------------------------
breadcrumb
---------------------------------------
*/
.l_breadcrumb {
  width: 90%;
  position: relative;
  padding: 20px 0 100px;
}
@media (max-width: 480px) {
  .l_breadcrumb {
    display: none;
  }
}
.l_breadcrumb--inner {
  width: 86.81%;
  margin-left: auto;
  text-align: right;
}
.l_breadcrumb--inner--item {
  position: relative;
  margin-left: 35px;
  color: #595757;
  font-size: 0.571em;
  letter-spacing: 0.28em;
  line-height: 1;
  font-weight: 500;
}
.l_breadcrumb--inner--item:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -25px;
  content: "";
  width: 8px;
  height: 8px;
  background-image: linear-gradient(to left top, transparent 49.5%, gray 49.5%, gray 50.5%, transparent 50.5%);
}
.l_breadcrumb--inner--item:last-child:after {
  display: none;
}

/*contact-parts*/
@media (min-width: 1281px) and (max-width: 3000px) {
  .pl_contact-parts--inner {
    margin-right: auto;
  }
}
.pl_contact-parts {
  width: 90%;
  height: auto;
  margin-bottom: 150px;
}
.pl_contact-parts .sp {
  display: none;
}
@media (max-width: 480px) {
  .pl_contact-parts {
    width: 100%;
    margin-bottom: 70px;
  }
  .pl_contact-parts .pc {
    display: none;
  }
  .pl_contact-parts .sp {
    display: block;
  }
}
.pl_contact-parts--inner {
  content: "";
  background-image: url("/images/common/contact_bg.jpg");
  background-position: center center;
  background-size: cover;
  width: 923px;
  height: 525px;
  margin-left: auto;
}
@media (max-width: 480px) {
  .pl_contact-parts--inner {
    background-image: url("/images/common/sp/contact_bg.jpg");
    width: 100%;
    height: 316px;
    margin: 0 auto;
  }
}
.pl_contact-parts--inner--ttl {
  font-size: 2.421vw;
  font-weight: 500;
  letter-spacing: 0.6em;
  line-height: 1.894;
  padding-top: 4.8%;
  color: #fff;
  text-align: center;
  margin-bottom: 4.8%;
}
@media (max-width: 480px) {
  .pl_contact-parts--inner--ttl {
    font-size: 20px;
  }
}
.pl_contact-parts--inner--item .item {
  background-color: #fff;
  opacity: 0.85;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pl_contact-parts--inner--item .item_side {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  width: 730px;
  margin: 0 auto 40px;
}
@media (max-width: 480px) {
  .pl_contact-parts--inner--item .item_side {
    width: 323px;
    margin: 0 auto 20px;
  }
}
.pl_contact-parts--inner--item .item_side .item01, .pl_contact-parts--inner--item .item_side .item02, .pl_contact-parts--inner--item .item_side .item03 {
  width: 200px;
  height: 200px;
}
@media (max-width: 480px) {
  .pl_contact-parts--inner--item .item_side .item01, .pl_contact-parts--inner--item .item_side .item02, .pl_contact-parts--inner--item .item_side .item03 {
    width: 100%;
    height: auto;
    padding: 20px 0;
    justify-content: space-around;
  }
}
.pl_contact-parts--inner--item .item_side p {
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 0.78em;
  line-height: 2;
  text-align: center;
  margin-left: 0.78em;
}
@media (max-width: 480px) {
  .pl_contact-parts--inner--item .item_side p {
    font-size: 17px;
    margin-left: 0;
  }
}
.pl_contact-parts--inner--item .item_side .item_txt01 {
  font-size: 12px;
  letter-spacing: 0.2em;
  line-height: 1.75;
  font-weight: 500;
  margin-left: 0;
  text-align-last: left;
}
.pl_contact-parts--inner--item .item04 {
  width: 730px;
  height: 87px;
  margin: 0 auto;
}
@media (max-width: 480px) {
  .pl_contact-parts--inner--item .item04 {
    width: 323px;
  }
}
@media (max-width: 480px) {
  .pl_contact-parts--inner--item .item04 .item_inner {
    padding: 15px 0;
  }
}
@media (max-width: 480px) {
  .pl_contact-parts--inner--item .item04 .item_inner span {
    display: block;
    text-align: center;
  }
}
.pl_contact-parts--inner--item .txt {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.26em;
  line-height: 3.45;
}
@media (max-width: 480px) {
  .pl_contact-parts--inner--item .txt {
    font-size: 13px;
    line-height: 1;
    margin-bottom: 10px;
  }
}
.pl_contact-parts--inner--item .tel {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.28em;
  line-height: 2.26;
  margin-left: 20px;
}
@media (max-width: 480px) {
  .pl_contact-parts--inner--item .tel {
    font-size: 24px;
    line-height: 1;
    margin-bottom: 10px;
  }
}
.pl_contact-parts--inner--item .time {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.46em;
  line-height: 5.49;
  margin-left: 20px;
}
@media (max-width: 480px) {
  .pl_contact-parts--inner--item .time {
    font-size: 11px;
    line-height: 1;
  }
}

/*
---------------------------------------
contact-complete
---------------------------------------
*/
/*mainvisual*/
#page-contact_complete .l_contents {
  width: 90%;
  position: relative;
}
@media (max-width: 480px) {
  #page-contact_complete .l_contents {
    width: 100%;
    margin-bottom: 40px;
  }
}
#page-contact_complete .l_contents--mv-top {
  width: 100%;
  height: 767px;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 480px) {
  #page-contact_complete .l_contents--mv-top {
    height: 490px;
  }
}
#page-contact_complete .l_contents--mv-top--catch {
  position: absolute;
  bottom: -23px;
  left: 160px;
}
@media (max-width: 480px) {
  #page-contact_complete .l_contents--mv-top--catch {
    left: 15px;
    bottom: -6px;
  }
}
#page-contact_complete .l_contents--mv-top--catch h2 {
  font-size: 104px;
  color: #fff;
  letter-spacing: 0.24em;
  line-height: 1.17;
  position: relative;
}
@media (max-width: 480px) {
  #page-contact_complete .l_contents--mv-top--catch h2 {
    font-size: 40px;
    letter-spacing: 0.14em;
    line-height: 1;
    font-weight: 500;
  }
}
#page-contact_complete .l_contents--mv-top--catch .sub-ttl {
  font-size: 17px;
  color: #fff;
  letter-spacing: 0.9em;
  line-height: 1;
  font-weight: 400;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 720px;
  width: 200px;
}
@media (max-width: 480px) {
  #page-contact_complete .l_contents--mv-top--catch .sub-ttl {
    font-size: 16px;
    letter-spacing: 0.1em;
    line-height: 1.25;
    font-weight: 500;
    left: 240px;
    top: auto;
    transform: inherit;
    bottom: 2px;
    width: 110px;
  }
}
#page-contact_complete .pl_contact-complete {
  padding: 120px 0;
}
@media (max-width: 480px) {
  #page-contact_complete .pl_contact-complete {
    width: 92%;
    margin: 0 auto;
    padding: 0 0 80px;
  }
}
#page-contact_complete .pl_contact-complete--inner--item {
  text-align: center;
}
#page-contact_complete .pl_contact-complete--inner--item h2 {
  font-size: 32px;
  letter-spacing: 0.2em;
  line-height: 1.75;
  font-weight: 500;
  margin-bottom: 60px;
}
@media (max-width: 480px) {
  #page-contact_complete .pl_contact-complete--inner--item h2 {
    font-size: 26px;
    margin-bottom: 40px;
  }
}
#page-contact_complete .pl_contact-complete--inner--item .txt_box p {
  font-size: 18px;
  letter-spacing: 0.2em;
  line-height: 2;
  font-weight: 500;
}
@media (max-width: 480px) {
  #page-contact_complete .pl_contact-complete--inner--item .txt_box p {
    font-size: 15px;
    letter-spacing: 0.075em;
    margin-bottom: 20px;
  }
}

/*
---------------------------------------
detail
---------------------------------------
*/
.l_detail {
  width: 90%;
  /*pagination*/
}
@media (max-width: 480px) {
  .l_detail {
    width: 100%;
  }
}
.l_detail--inner--head .head_inner {
  width: 86.81%;
  margin-left: auto;
}
@media (max-width: 480px) {
  .l_detail--inner--head .head_inner {
    width: 100%;
  }
}
.l_detail--inner--head .head_inner .head_ttl h2 {
  font-size: 16px;
  letter-spacing: 0.28em;
  line-height: 1.43;
  font-weight: 500;
  color: #fff;
  margin-bottom: 15px;
}
.l_detail--inner--head .head_inner .head_txt .txt {
  font-size: 8px;
  letter-spacing: 0.1em;
  line-height: 1.71;
  font-weight: 400;
  color: #fff;
  margin-bottom: 10px;
}
.l_detail--inner--head .head_inner .head_label .label1 {
  font-size: 10px;
  letter-spacing: 0.28em;
  line-height: 1;
  font-weight: 500;
  color: #231815;
  background-color: #fff;
  display: inline-block;
  padding: 3px 4px;
}
.l_detail--inner--head .head_inner .head_label .label2 {
  font-size: 8px;
  letter-spacing: 0.32em;
  line-height: 1;
  font-weight: 400;
  color: #fff;
  border: solid 1px #fff;
  display: inline-block;
  padding: 2px 4px;
  margin-left: 10px;
}
.l_detail--inner--article {
  margin-top: -40px;
  margin-bottom: 140px;
}
@media (max-width: 480px) {
  .l_detail--inner--article {
    margin-bottom: 60px;
  }
}
.l_detail--inner--article .article_inner {
  width: 86.81%;
  margin-left: auto;
}
@media (max-width: 480px) {
  .l_detail--inner--article .article_inner {
    width: 92%;
    margin: 0 auto;
  }
}
.l_detail--inner--article .article_inner--img {
  width: 782px;
  margin: 0 auto 40px;
}
@media (max-width: 480px) {
  .l_detail--inner--article .article_inner--img {
    width: 100%;
  }
}
.l_detail--inner--article .article_inner--txt {
  width: 782px;
  margin: 0 auto 40px;
}
@media (max-width: 480px) {
  .l_detail--inner--article .article_inner--txt {
    width: 100%;
  }
}
.l_detail--inner--article .article_inner--txt p {
  font-size: 12px;
  letter-spacing: 0.28em;
  line-height: 2;
  font-weight: 500;
  text-align: justify;
}
.l_detail .pagination {
  margin-bottom: 140px;
}
@media (max-width: 480px) {
  .l_detail .pagination {
    margin-bottom: 80px;
  }
}
.l_detail .pagination--inner {
  width: 86.81%;
  margin-left: auto;
}
@media (max-width: 480px) {
  .l_detail .pagination--inner {
    width: 92%;
    margin: 0 auto;
  }
}
.l_detail .pagination--inner--list {
  width: 782px;
  margin: 0 auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media (max-width: 480px) {
  .l_detail .pagination--inner--list {
    width: 80%;
  }
}
.l_detail .pagination--inner--list--item .txt {
  font-size: 11px;
  letter-spacing: 0.28em;
  line-height: 2.18;
  font-weight: 500;
  color: #231815;
}
@media (max-width: 480px) {
  .l_detail .pagination--inner--list--item .txt {
    display: none;
  }
}
.l_detail .pagination--inner--list--item .ttl {
  font-size: 9px;
  letter-spacing: 0.28em;
  line-height: 2.66;
  font-weight: 500;
  color: #231815;
}
@media (max-width: 480px) {
  .l_detail .pagination--inner--list--item .ttl {
    display: none;
  }
}
.l_detail .pagination--inner--list .prev_txt {
  position: relative;
  text-align: left;
  padding-left: 30px;
}
.l_detail .pagination--inner--list .prev_txt .arrow_left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.l_detail .pagination--inner--list .prev_txt .arrow_left img {
  width: 16px;
}
.l_detail .pagination--inner--list .list_txt {
  width: 117px;
  height: 37px;
  display: inline-flex;
}
.l_detail .pagination--inner--list .list_txt p {
  font-size: 10px;
  letter-spacing: 0.28em;
  line-height: 2.4;
  font-weight: 500;
  width: 117px;
  height: 37px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.l_detail .pagination--inner--list .next_txt {
  position: relative;
  text-align: right;
  padding-right: 30px;
}
.l_detail .pagination--inner--list .next_txt .arrow_right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.l_detail .pagination--inner--list .next_txt .arrow_right img {
  width: 16px;
}
/*
---------------------------------------
footer
---------------------------------------
*/
.l_footer {
  width: 90%;
  position: relative;
}
.l_footer .sp {
  display: none;
}
@media (min-width: 1281px) and (max-width: 2560px) {
  .l_footer {
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 480px) {
  .l_footer {
    width: 92%;
    margin: 0 auto 20px;
    padding-bottom: 90px;
  }
  .l_footer .pc {
    display: none;
  }
  .l_footer .sp {
    display: block;
  }
}
.l_footer .top_btn {
  font-style: italic;
  font-size: 15px;
  color: #80b3a5;
  position: absolute;
  bottom: 80px;
  right: 0;
  transform: rotate(90deg);
}
@media (max-width: 480px) {
  .l_footer .top_btn {
    display: none;
  }
}
.l_footer .top_btn .btn {
  position: relative;
}
.l_footer .top_btn .btn:before, .l_footer .top_btn .btn:after {
  content: "";
  display: block;
  position: absolute;
  top: 120%;
  right: -50px;
  height: 1px;
  background: #80b3a5;
}
.l_footer .top_btn .btn:before {
  width: 127px;
  transform: translate(30px, 0px);
}
.l_footer .top_btn .btn:after {
  width: 10px;
  transform-origin: right center;
  transform: translate(-97px, 0px) rotate(150deg);
}
.l_footer--inner {
  width: 86.81%;
  margin-left: auto;
}
@media (max-width: 480px) {
  .l_footer--inner {
    width: 100%;
  }
}
.l_footer--inner small {
  font-size: 10px;
  font-weight: 400;
  color: #727171;
  font-style: italic;
  letter-spacing: 0.18em;
}
@media (max-width: 480px) {
  .l_footer--inner small {
    font-size: 3px;
    text-align: center;
  }
}
.l_footer--inner--nav {
  width: 750px;
  letter-spacing: 0.32em;
  margin-bottom: 80px;
}
@media (max-width: 480px) {
  .l_footer--inner--nav {
    width: 100%;
    font-size: 14px;
  }
}
.l_footer--inner--nav ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  margin-bottom: 10px;
}
@media (max-width: 480px) {
  .l_footer--inner--nav .nav_item02 {
    justify-content: flex-start;
  }
}
.l_footer--inner--nav .nav_item02 li:last-child {
  margin-left: 40px;
}
.l_footer--inner--sub_nav {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  justify-content: space-between;
  font-size: 9px;
  width: 710px;
  margin-bottom: 100px;
  letter-spacing: 0.32em;
}
@media (max-width: 480px) {
  .l_footer--inner--sub_nav {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
}
.l_footer--inner--sub_nav .nav_box {
  width: 345px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media (max-width: 480px) {
  .l_footer--inner--sub_nav .nav_box {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 60px;
  }
}
.l_footer--inner--sub_nav .nav_box .txt ul li {
  margin-bottom: 10px;
}
.l_footer--inner--sub_nav .nav_box .txt ul li:last-child {
  margin-bottom: 0;
}
.l_footer--inner--sub_nav .nav_box .txt .txt_mid {
  font-size: 14px;
}
@media (max-width: 480px) {
  .l_footer--inner--sub_nav .nav_box .txt .txt_mid {
    font-size: 13px;
  }
}
@media (max-width: 480px) {
  .l_footer--inner--sub_nav .nav_box .company_txt {
    margin-left: 23.19%;
  }
}
.l_footer--inner--sub_nav .icon_area {
  display: flex;
}
@media (max-width: 480px) {
  .l_footer--inner--sub_nav .icon_area {
    display: flex;
    flex-direction: column-reverse;
  }
}
.l_footer--inner--sub_nav .icon_area--inner {
  display: flex;
}
@media (max-width: 480px) {
  .l_footer--inner--sub_nav .icon_area--inner {
    justify-content: center;
    margin-bottom: 40px;
  }
}
.l_footer--inner--sub_nav .icon_area--inner--insta {
  width: 30px;
  display: flex;
  align-items: flex-end;
}
@media (max-width: 480px) {
  .l_footer--inner--sub_nav .icon_area--inner--insta {
    display: block;
  }
}
.l_footer--inner--sub_nav .icon_area--inner--fb {
  width: 15px;
  display: flex;
  align-items: flex-end;
  margin-left: 25px;
}
@media (max-width: 480px) {
  .l_footer--inner--sub_nav .icon_area--inner--fb {
    display: block;
    margin-left: 25px;
  }
}
.l_footer--inner--sub_nav .icon_area--foot_logo {
  width: 165px;
  display: flex;
  align-items: flex-end;
  margin-left: 40px;
}
@media (max-width: 480px) {
  .l_footer--inner--sub_nav .icon_area--foot_logo {
    display: block;
    margin: 0 auto 25px;
    width: 52.17%;
  }
}

.l_footer--fix_btn {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
}
.l_footer--fix_btn .fix_btn-inner {
  width: 100vw;
  height: 65px;
  display: flex;
}
.l_footer--fix_btn .fix_btn-inner .left_item {
  width: 58.67vw;
  background-color: #00AA82;
  display: flex;
  justify-content: center;
  align-items: center;
}
.l_footer--fix_btn .fix_btn-inner .left_item .icon {
  width: 27px;
}
.l_footer--fix_btn .fix_btn-inner .left_item .txt_area {
  color: #fff;
  font-weight: 500;
  margin-left: 15px;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 0.1em;
}
.l_footer--fix_btn .fix_btn-inner .left_item .txt_area .txt {
  font-size: 12px;
  display: block;
}
.l_footer--fix_btn .fix_btn-inner .left_item .txt_area .time {
  font-size: 11px;
  display: block;
}
.l_footer--fix_btn .fix_btn-inner .right_item {
  width: 41.07vw;
  background-color: #FFAD00;
  display: flex;
  justify-content: center;
  align-items: center;
}
.l_footer--fix_btn .fix_btn-inner .right_item .icon {
  width: 31px;
}
.l_footer--fix_btn .fix_btn-inner .right_item .txt {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  margin-left: 10px;
  line-height: 1.5;
  letter-spacing: 0.1em;
}

.drawer {
  position: fixed;
  width: 10%;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 99999;
}
@media (max-width: 480px) {
  .drawer {
    right: 25px;
    width: 46px;
    height: 120px;
  }
}
.drawer--inner {
  /*　ハンバーガーボタン　*/
}
.drawer--inner .hamburger {
  display: block;
  position: absolute;
  z-index: 99999;
  left: 50%;
  transform: translateX(-50%);
  top: 35px;
  width: 42px;
  height: 42px;
  text-align: center;
}
@media (max-width: 480px) {
  .drawer--inner .hamburger {
    top: 30px;
    width: 20px;
    height: 20px;
  }
}
.drawer--inner .hamburger span {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 6px;
  background: #000;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
@media (max-width: 480px) {
  .drawer--inner .hamburger span {
    background: #fff;
    width: 20px;
  }
}
@media (max-width: 480px) {
  .drawer--inner .icon_area {
    position: absolute;
    top: 85px;
    right: 0px;
    width: 46px;
  }
}
.drawer--inner .icon_area ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
.drawer--inner .icon_area ul li a .insta {
  width: 21px;
}
.drawer--inner .icon_area ul li a .fb {
  width: 12px;
}

.hamburger span:nth-child(1) {
  top: 10px;
}

.hamburger span:nth-child(2) {
  top: 20px;
}

.hamburger span:nth-child(3) {
  top: 30px;
}

/* ナビ開いてる時のボタン */
.hamburger.active span:nth-child(1) {
  top: 16px;
  left: 6px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger.active span:nth-child(2),
.hamburger.active span:nth-child(3) {
  top: 16px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (max-width: 480px) {
  .hamburger.active {
    background-color: transparent;
  }
}

@media (max-width: 480px) {
  .hamburger {
    background-color: transparent;
  }
}

.globalMenuSp {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background: #80b3a5;
  transform: translateY(-100%);
  transition: all 0.6s;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 480px) {
  .globalMenuSp {
    width: 100%;
  }
}
.globalMenuSp--inner {
  width: 100%;
  margin-right: auto;
  height: 100vh;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 480px) {
  .globalMenuSp--inner {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 70px;
  }
}
.globalMenuSp--inner .menu_left .img {
  width: 225px;
}
@media (max-width: 480px) {
  .globalMenuSp--inner .menu_left .img {
    width: 100px;
    margin-bottom: 40px;
  }
}
.globalMenuSp--inner ul {
  margin-left: 100px;
}
@media (max-width: 480px) {
  .globalMenuSp--inner ul {
    margin-left: 0;
  }
}
.globalMenuSp--inner ul li {
  list-style-type: none;
  padding: 0;
  width: 100%;
}
.globalMenuSp--inner ul li a {
  display: inline-block;
  color: #fff;
  font-size: 28px;
  letter-spacing: 0.35em;
  line-height: 2.5;
  font-weight: 400;
}
@media (max-width: 480px) {
  .globalMenuSp--inner ul li a {
    font-size: 18px;
    display: block;
    text-align: center;
  }
}
.globalMenuSp .side {
  width: 10%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  background-color: #fff;
}

/* このクラスを、jQueryで付与・削除する */
.globalMenuSp.active {
  transform: translateY(0%);
}

/* 画面外にいる状態 */
.fadein {
  opacity: 0.1;
  transform: translate(0, 40px);
  transition: all 500ms;
}

/* 画面内に入った状態 */
.fadein.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}