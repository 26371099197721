/*
---------------------------------------
variables
---------------------------------------
*/
$blue: #00A0C8;
$lightblue: #EFF6FB;
$gray: #9da6ac;
$lightgray: #F8F8F8;
$orange: #ED8900;
$yellow: #F5FF00;
$green: #80b3a5;
$l-gray: #b2b8b9;

// Device Width
$pc: 1280px;
$tab-lg: 1024px;
$tab: 768px;
$sp: 480px;
