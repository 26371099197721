/*
---------------------------------------
a-side
---------------------------------------
*/
#SIDE{
    width: 10%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    overflow: hidden;
    @include sp{
      display: none;
    }
    .l_a_side {
        text-align: center;
        overflow: hidden;
        a:hover{
            opacity: 0.6;
        }
        &--menu{
            position: absolute;
            top: 40px;
            width: 28px;
            height: 28px;
            left: 50%;
            transform: translateX(-50%);
      }
        &--side_nav{
            padding-top: 68px;
            ul > li> a{
              margin: 0 auto;
            }
            .nav{
                position: relative;
                width: 100%;
                height: 220px;
                @include flex($justify:center);
            }
            .nav:after{
                content:'';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                border-bottom: 1px solid #231815;
                width: 35px;
            }
            .nav:last-child:after{
                border-bottom:none;
            }
            .nav_ja{
                font-size: 14px;
                letter-spacing: 0.32em;
                line-height: 1.75;
                font-weight: 400;
            }
            .nav_en{
                font-size: 11px;
                letter-spacing: 0.32em;
                line-height: 1.75;
                font-weight: 400;
            }
            .nav_en1{
                font-size: 11px;
                margin-top: -72px;
            }
            .nav_en2{
                font-size: 11px;
                margin-top: -17px;
            }
            .nav_en3{
                font-size: 11px;
                margin-top: 0px;
            }
        }
        &--sns_btn{
            width: 100%;
            @include flex($justify:center);
            .insta{
                width: 18px;
            }
            .fb{
                width: 10px;
                margin-left: 14px;
            }
        }
        .kozuka{
            font-weight: 400;
        }
    }
}


