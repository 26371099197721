/*
---------------------------------------
icon
---------------------------------------
*/
@font-face {
    font-family: 'icomoon';
    src:  url('/../icon/icomoon.eot?bx8fym');
    src:  url('/../icon/icomoon.eot?bx8fym#iefix') format('embedded-opentype'),
        url('/../icon/icomoon.ttf?bx8fym') format('truetype'),
        url('/../icon/icomoon.woff?bx8fym') format('woff'),
        url('/../icon/icomoon.svg?bx8fym#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    display: inline-block;
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    letter-spacing: 0;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
    display: inline-block;
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    letter-spacing: 0;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]::before, [class*=" icon-"]::before {
    font-family: inherit!important;
    font-size: inherit;
    color: inherit;
    line-height: inherit;
    letter-spacing: inherit;
}

.icon-arrow-down:before {
    content: "\e900";
}
.icon-arrow-left:before {
    content: "\e901";
}
.icon-arrow-right:before {
    content: "\e902";
}
.icon-arrow-up:before {
    content: "\e903";
}
.icon-book:before {
    content: "\e904";
}
.icon-calc:before {
    content: "\e905";
}
.icon-circle-arrow-right:before {
    content: "\e906";
}
.icon-home:before {
    content: "\e907";
}
.icon-mail:before {
    content: "\e908";
}
.icon-pin:before {
    content: "\e909";
}
.icon-write:before {
    content: "\e90a";
}
