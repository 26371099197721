/*
---------------------------------------
text
---------------------------------------
*/
.p_txt {
    p {
        @include txt;
        margin-bottom: 1.75em;

        &:last-child {
            margin-bottom: 0;
        }
    }
    @include sp {
        p {
            font-size: 13px;
        }
    }

}

