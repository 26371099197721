/*
---------------------------------------
base
---------------------------------------
*/

body {
    min-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    @include sp {
        min-width: auto;
    }
}

// コンテナー
.inner {
    width: 1000px;
    margin-right: auto;
    margin-left: auto;

    @include sp {
        width: 345px;
    }
}

// 画像系
.img {
    img {
        width: 100%;
    }
}

.thumb {
    @include bg;
    @include aspect;
}

// マーカー
.marker {
    background: linear-gradient(transparent 80%, $yellow 80%);
}

.elem {
    &--center {
        @include absolute($top: 50%);
        transform: translateY(-50%);
    }
}

.font {
    &--blue {
        color: $blue;
    }
}

html{
  scroll-behavior: smooth;
}

@media (min-width: 1281px) and (max-width: 2560px){
    .page_inner{
        max-width: 1280px;
        margin-right: auto;
        margin-left: auto;
    }
    .pl_contact-parts--inner{
        margin-right: auto;
    }
}