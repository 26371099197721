/*
---------------------------------------
breadcrumb
---------------------------------------
*/
.l_breadcrumb {
    width: 90%;
    position: relative;
    padding: 20px 0 100px;
    @include sp{
        display: none;
    }

    &--inner{
        width: 86.81%;
        margin-left: auto;
        text-align: right;

        &--item {
            position: relative;
            margin-left: 35px;
            color: #595757;
            font-size: 0.571em;
            letter-spacing: 0.28em;
            line-height: 1;
            font-weight: 500;
        }
        &--item:after{
            position: absolute;
            top: 50%;
            transform:translateY(-50%);
            right: -25px;
            content:'';
            width: 8px;
            height: 8px;
            background-image: linear-gradient(
                to left top,
                transparent 49.5%,
                gray 49.5%,
                gray 50.5%,
                transparent 50.5%
                );
        }
        &--item:last-child:after{
            display: none;
        }
    }
}
