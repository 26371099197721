/*
---------------------------------------
ttl
---------------------------------------
*/
.p_ttl--page--wrap {
    @include dt-box($width: 480px, $height: 150px, $ta: left);
    @include absolute($bottom: 75px, $left: 0);
    padding-left: 50px;
    background-color: #fff;
    border-radius: 0 5px 5px 0;
    @include ie{
        left: -1px;
    }

    @include sp {
        width: 270px;
        height: 100px;
        padding-left: 20px;
        bottom: 45px;
    }

    .p_ttl--page {
        & > * {
            display: block;
        }
        &--en {
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 0.175em;
            line-height: 1.41666667em;
            color: $gray;
            margin-bottom: 5px;

            @include sp {
                font-size: 16px;
                line-height: 1.4375em;
            }
        }
        &--ja {
            font-size: 30px;
            font-weight: 700;
            letter-spacing: 0.225em;
            line-height: 1.5em;
            color: $blue;

            @include sp {
                font-size: 20px;
                line-height: 1.45em;
            }
        }
    }
}

.p_ttl--basic {
    &--ja {
        @include ttl-txt($fz: 30px);
        @include sp {
            font-size: 21px;
        }
    }
}

.p_ttl--heading {
    &--ja {
        @include ttl-txt;
        @include sp {
            font-size: 19px;
        }
    }
}

.p_ttl--sec {
    & > * {
        display: block;
        text-align: center;
    }
    &--en {
        @include ttl-txt($fz: 38px, $lts: 0.05em, $lh: 1.42105213em, $color: $blue);
        margin-bottom: 5px;

        @include sp {
            font-size: 26px;
            line-height: 1.42307692em;
        }
    }
    &--ja {
        @include ttl-txt($fz: 16px, $lts: 0.225em, $lh: 1.5em);

        @include sp {
            font-size: 13px;
            line-height: 1.46153846em;
        }
    }
}

.p_ttl--border {
    padding: 0 0 10px 20px;
    border-bottom: 1px solid $gray;
    @include sp {
        padding: 0 0 10px 0;
    }

    &--ja {
        @include ttl-txt($fz: 28px, $lh: 1.46428571em, $color: $blue);
        @include sp {
            font-size: 17px;
        }
    }
}

.p_ttl--num {
    & > * {
        display: block;
        text-align: center;
    }
    &--num {
        font-size: 60px;
        font-weight: 700;
        letter-spacing: 0.05em;
        line-height: 1em;
        color: $gray;
        margin-bottom: 10px;
        @include sp {
            font-size: 50px;
        }
    }
    &--en {
        @include ttl-txt($fz: 21px, $lts: 0.125em, $lh: 1.42857143em, $color: $gray);
        margin-bottom: 15px;
        @include sp {
            font-size: 15px;
            line-height: 1.46666667em;
        }
    }
    &--ja {
        @include ttl-txt($lts: 0.125em, $lh: 1.85em);
        @include sp {
            @include ttl-txt($fz: 17px, $lh: 1.58823529em);
        }
    }
}

.p_ttl--num-horizon {
    @include flex($justify: start, $items: center);
    &--wrap {
        & > * {
            display: block;
            text-align: center;
        }
    }
    &--num {
        @include ttl-txt($fz: 60px, $lts: 0.05em, $lh: 1em, $color: $gray);
        margin: -37px 25px 0 0;
    }
    &--en {
        @include ttl-txt($fz: 34px, $lts: 0.125em, $lh: 1.44117647em, $color: $gray);
        margin-bottom: 10px;
    }
    &--ja {
        @include ttl-txt($fz: 18px, $lts: 0.225em, $lh: 1.5em);
    }
}

.p_ttl--icon {

}

