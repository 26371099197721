/*
---------------------------------------
concept_detail
---------------------------------------
*/

	/*mainvisual*/
	#page-concept_dtl{

		.l_header{
			width: 100%;
			height: 140px;
			position: relative;
		}

		.l_contents{
			width: 90%;
			position: relative;
			&--mv-top{
				width: 100%;
				height: 627px;
				background-size: cover;
				background-position: center center;
				&--catch{
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					width: 100%;
					text-align: center;
					h2{
						font-size: 23px;
						color: #fff;
						letter-spacing: 0.28em;
						line-height: 1;
						font-weight: 500;
					}
				}
			}
		}

		.pl_concept{
			width: 90%;

			&--inner{
				width: 100%;
				&--link_btn{
					width: 86.81%;
					@include flex;
					margin-left: auto;
					padding: 40px 0;
					.btn{
						width: 33.333%;
						height: 120px;
						border-left: 1px solid #808080;
						display: flex;
						justify-content: center;
						align-items: center;
						a{
							span{
								display: block;
							}
							.txt{
								font-size: 21px;
								letter-spacing: 0.28em;
								line-height: 1;
								color: #808080;
								margin-bottom: 20px;
							}
							.icon{
								width: 20px;
								margin: 0 auto;
							}
						}
					}
					.btn:first-child{
						border-left: none;
					}
				}
			}

			.sec_basic{
				width: 100%;
				&--inner{
					h2{
						font-size: 66px;
						letter-spacing: 0.28em;
						line-height: 1.156;
						font-weight: 500;
						margin-bottom: 45px;
					}
					h3{
						font-size: 17px;
						letter-spacing: 0.28em;
						line-height: 2.3;
						font-weight: 500;
					}
					p{
						font-size: 14px;
						letter-spacing: 0.28em;
						line-height: 2.538;
						font-weight: 500;
						text-align: justify;
						margin-bottom: 30px;
					}
					span{
						font-size: 14px;
						letter-spacing: 0.28em;
						line-height: 1;
						font-weight: 500;
					}
				}
			}

			&--sec_basic01{
				width: 100%;
				height: auto;
				margin-bottom: 140px;

				&--inner{
					position: relative;
					width: 100%;
					height: 800px;
					margin-bottom: 40px;
					.inner_left{
						&--ttl{
							position: relative;
							h2{
								width: 86.81%;
								margin-left: auto;
							}
							h2:after{
								content: '';
								position: absolute;
								bottom: 0;
								left: 0;
								border-bottom: solid 33px $green;
								width: 542px;
								z-index: -1;
							}
						}
						&--txt{
							width: 86.81%;
							margin-left: auto;
							.txt{
								p{
									font-size: 17px;
									letter-spacing: 0.28em;
									line-height: 2.3;
									font-weight: 500;
									span{
										line-height: 2.538;
									}
								}
							}
							.t-txt{
								p{
									margin-bottom: 80px;
								}
							}
							.b-txt{
								p{}
							}
						}
					}
					.inner_right{
						.img{
							width: 595px;
							position: absolute;
							bottom: 0;
							right: 0;
							z-index: -1;
						}
					}
				}

				&--inner02{
					width: 86.81%;
					margin-left: auto;
					.arrow-d{
						width: 214px;
						margin-bottom: 60px;
						.img{
							width: 74px;
							margin: 0 auto;
						}
					}
					&--item{
						ul{
							@include flex($justify:start);
							li{
								width: 214px;
								@include flex;
								justify-content: center;
								margin-right: 15px;
								.img{
									width: 214px;
									margin-bottom: 30px;
								}
								.txt_area{
									text-align: center;
									.ttl{
										position: relative;
										margin-bottom: 15px;
										height: 60px;
										display: flex;
										align-items: center;
										p{
											font-size: 18px;
											letter-spacing: 0.075em;
											line-height: 1.333;
											font-weight: 500;
											display: block;
											margin-bottom: 20px;
											text-align: center;
										}
									}
									.ttl:after{
									content:'';
									position: absolute;
									bottom: 0;
									left: 50%;
									transform: translateX(-50%);
									border-top: 1px solid #231815;
									width: 180px;
									}
									.txt{
										p{
											font-size: 11px;
											letter-spacing: 0.1em;
											line-height: 2.18;
											font-weight: 500;
											display: block;
										}
									}
								}
							}
						}
					}
				}
			}

			&--sec_basic02{
				width: 100%;
				height: 800px;
				margin-bottom: 140px;

				&--inner{
					position: relative;
					width: 100%;
					height: 800px;
					margin-bottom: 40px;
					.inner_left{
						&--ttl{
							position: relative;
							h2{
								width: 86.81%;
								margin-left: auto;
							}
							h2:after{
								content: '';
								position: absolute;
								bottom: 0;
								left: 0;
								border-bottom: solid 33px $green;
								width: 542px;
								z-index: -1;
							}
						}
						&--txt{
							width: 86.81%;
							margin-left: auto;
							.txt{
								p{
									font-size: 17px;
									letter-spacing: 0.28em;
									line-height: 2.3;
									font-weight: 500;
									span{
										line-height: 2.538;
									}
								}
							}
							.t-txt{
								p{
									margin-bottom: 80px;
								}
							}
							.b-txt{
								p{}
							}
						}
					}
					.inner_right{
						.img{
							width: 595px;
							position: absolute;
							bottom: 0;
							right: 0;
							z-index: -1;
						}
					}
				}
			}

			&--sec_basic03{
				width: 100%;
				height: auto;
				margin-bottom: 140px;

				&--inner{
					position: relative;
					width: 100%;
					height: 287px;
					margin-bottom: 40px;
					@include flex;
					align-content: flex-start;
					.inner_left{
						width: 100%;
						.ttl_area{
							.inner_left-ttl{
							position: relative;
							h2{
								width: 86.81%;
								margin-left: auto;
							}
							h2:after{
								content: '';
								position: absolute;
								bottom: 0;
								left: 0;
								border-bottom: solid 33px $green;
								width: 542px;
								z-index: -1;
							}
						}
						.inner_left-sub_ttl{
							width: 86.81%;
							margin-left: auto;
							h3{
								font-size: 36px;
								letter-spacing: 0.2em;
								line-height: 1.43;
								font-weight: 500;

							}
						}
						}
					}
					.inner_right{
						position: absolute;
						top: 0;
						right: 0;
						z-index: -1;
						width: 745px;
						.img{
							width: 100%;
						}
					}
				}

				&--inner02{
					width: 100%;
					height: 455px;
					margin-bottom: 40px;
					@include flex;
					position: relative;
					&--item{
						width: 86.81%;
						margin-left: auto;
						display: flex;
					.inner_left{
						width: 445px;
						.img{
							width: 100%;
						}
					}
					.inner_right{
						left: 515px;
						width: 745px;
						margin-left: auto;
						.txt_area{
							width: 480px;
							margin-right: auto;
							.txt{
								p{
									font-size: 13px;
									letter-spacing: 0.28em;
									line-height: 2.58;
									font-weight: 500;
								}
							}
							.btn{
								width: 290px;
								height: 96px;
								text-align: center;
								background-color: $gray;
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
								color: #fff;
								margin: 0 auto;
								.ttl_en{
									font-size: 16px;
									letter-spacing: 0.3em;
									line-height: 1.75;
									font-weight: 500;
									display: block;
								}
								.ttl_ja{
									font-size: 9px;
									letter-spacing: 0.32em;
									line-height: 1.75;
									font-weight: 500;
									display: block;
								}
							}
						}
					}
				}
				}

			}


		}

	}


