/*
---------------------------------------
btn
---------------------------------------
*/
.p_btn {
    @include dt-box;
    position: relative;
    border: 1px solid #CAD4DB;
    background-color: #fff;
    line-height: 1;
    opacity: 1;
    transition: opacity .4s;

    &:hover {
        opacity: .75;
    }
}

.p_btn {
    &--large {
        max-width: 540px;
        height: 110px;
        font-size: 21px;
        font-weight: 500;
        letter-spacing: 0.225em;

        @include sp {
            height: 75px;
            font-size: 14px;
        }

        .icon-calc,
        .icon-book {
            font-size: 32px;
            color: $blue;
            left: 50px;

            @include sp {
                font-size: 21px;
                left: 30px;
            }
        }
        .icon-arrow-right {
            font-size: 16px;
            right: 20px;

            @include sp {
                font-size: 10px;
            }
        }
    }

    &--middle {
        max-width: 425px;
        height: 75px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.125em;

        @include sp {
            max-width: 345px;
            font-size: 14px;
        }

        .pu_circle {
            right: 20px;
            @include sp {
                width: 28px;
                height: 28px;
                right: 15px;
            }

            .icon-arrow-right {
                font-size: 10px;
                color: $blue;
                transform: scale(.7);
                @include sp {
                    transform: scale(.85);
                }
            }
        }
    }

    &--radius {
        max-width: 165px;
        height: 35px;
        border-radius: 17px;
        background-color: transparent;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.125em;

        .pu_circle {
            width: 18px;
            height: 18px;
            border: 1px solid #fff;
            background-color: transparent;
            right: 8px;

            .icon-arrow-right {
                font-size: 10px;
                color: #fff;
                transform: scale(.5);
            }
        }
    }

    &--blue {
        background-color: $blue;
        border-color: $blue;
        color: #fff;
    }
    &--orange {
        background-color: $orange;
        border-color: $orange;
        color: #fff;
    }
    &--gray {
        background-color: $gray;
        border-color: $gray;
        color: #fff;
    }
}

