/*
---------------------------------------
detail
---------------------------------------
*/
.l_detail{
    width: 90%;
    @include sp{
        width: 100%;
    }
    &--inner{
        &--head{
            .head_inner{
                width: 86.81%;
                margin-left: auto;
                @include sp{
                    width: 100%;
                }
                .head_ttl{
                    h2{
                        font-size: 16px;
                        letter-spacing: 0.28em;
                        line-height: 1.43;
                        font-weight: 500;
                        color: #fff;
                        margin-bottom: 15px;
                    }
                }
                .head_txt{
                    .txt{
                        font-size: 8px;
                        letter-spacing: 0.1em;
                        line-height: 1.71;
                        font-weight: 400;
                        color: #fff;
                        margin-bottom: 10px;
                    }
                }
                .head_label{
                    .label1{
                        font-size: 10px;
                        letter-spacing: 0.28em;
                        line-height: 1;
                        font-weight: 500;
                        color: #231815;
                        background-color: #fff;
                        display: inline-block;
                        padding: 3px 4px;
                    }
                    .label2{
                        font-size: 8px;
                        letter-spacing: 0.32em;
                        line-height: 1;
                        font-weight: 400;
                        color: #fff;
                        border: solid 1px #fff;
                        display: inline-block;
                        padding: 2px 4px;
                        margin-left: 10px;
                    }
                }
            }
        }
        &--article{
            margin-top: -40px;
            margin-bottom: 140px;
            @include sp{
                margin-bottom: 60px;
            }
            .article_inner{
                width: 86.81%;
                margin-left: auto;
                @include sp{
                        width: 92%;
                        margin: 0 auto;
                    }
                &--img{
                    width: 782px;
                    margin: 0 auto 40px;
                    @include sp{
                        width: 100%;
                    }
                }
                &--txt{
                    width: 782px;
                    margin: 0 auto 40px;
                    @include sp{
                        width: 100%;
                    }
                    p{
                        font-size: 12px;
                        letter-spacing: 0.28em;
                        line-height: 2;
                        font-weight: 500;
                        text-align: justify;
                    }
                }
            }
        }
    }

    /*pagination*/
    .pagination{
        margin-bottom: 140px;
        @include sp{
            margin-bottom: 80px;
        }
        &--inner{
            width: 86.81%;
            margin-left: auto;
            @include sp{
                width: 92%;
                margin: 0 auto;
            }
            &--list{
                width: 782px;
                margin: 0 auto;
                @include flex($items:center);
                @include sp{
                    width: 80%;
                }
                &--item{
                    .txt{
                        font-size: 11px;
                        letter-spacing: 0.28em;
                        line-height: 2.18;
                        font-weight: 500;
                        color: #231815;
                        @include sp{
                            display: none;
                        }
                    }
                    .ttl{
                        font-size: 9px;
                        letter-spacing: 0.28em;
                        line-height: 2.66;
                        font-weight: 500;
                        color: #231815;
                        @include sp{
                            display: none;
                        }
                    }
                }
                .prev_txt{
                    position: relative;
                    text-align: left;
                    padding-left: 30px;
                    .arrow_left{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        img{
                            width: 16px;
                        }
                    }
                    a{}
                }
                .list_txt{
                    width: 117px;
                    height: 37px;
                    display: inline-flex;
                    p{
                        font-size: 10px;
                        letter-spacing: 0.28em;
                        line-height: 2.4;
                        font-weight: 500;
                        width: 117px;
                        height: 37px;
                        display: table-cell;
                        vertical-align: middle;
                        text-align: center;
                    }
                }
                .next_txt{
                    position: relative;
                    text-align: right;
                    padding-right: 30px;
                    .arrow_right{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                        img{
                            width: 16px;
                        }
                    }
                    a{}
                }
            }
        }
    }

}