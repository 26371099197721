
@keyframes shake{
  0%{
    bottom:10px
  }
  50%{
    bottom:25px;
  }
  100%{
    bottom:10px;
  }
}

/*mainvisual*/
#page-top{
.l_contents{
	width: 90%;
	position: relative;
	@include sp{
        width: 100%;
        margin-bottom: 35px;
    }
	&--mv-top{
		width: 100%;
		height: 767px;
		background-size: cover;
		background-position: center center;
		@include sp{
        width: 100%;
        height: 360px;
    }
		&--catch{
			position: absolute;
			bottom: -14px;
			left: 140px;
			@include sp{
				bottom: -7px;
				left: 15px;
			}
			h2{
				font-size: 97px;
				color: #fff;
				letter-spacing: 0.12em;
				line-height: 1.09;
				@include sp{
					font-size: 40px;
				}
			}
		}
	}
}

@media (min-width: 1281px) and (max-width: 2560px){
	.page_inner{
		max-width: 1280px;
		margin-right: auto;
		margin-left: auto;
	}
	.pl_contact-parts--inner{
		margin-right: auto;
	}
}

/*concept*/
.pl_top_concept{
	width: 90%;
	height: 835px;
	position: relative;
	padding-top: 70px;
	@include sp{
		width: 100%;
		padding-top: 0;
		margin-bottom: 60px;
		height: 695px;
	}
	.scroll-arrow{
		@include sp{
			display: none;
		}
		.arrow{
			position: relative;
			width: 45px;
			animation:3s shake infinite ease-in;
			transform: rotate(90deg);
			margin-left: 13%;
		}
		.arrow:before,.arrow:after{
			content: '';
			display: block;
			position: absolute;
			top: 25px;
			right: -50px;
			height: 1px;
			background: $green;
		}
		.arrow:before{
			width: 66px;
			transform: translate(-30px, 0px);
		}
		.arrow:after{
			width: 10px;
			transform-origin: right center;
			transform: translate(-30px, 0px) rotate(45deg);
		}
		p{
			font-style: italic;
			color: $green;
			font-size: 1.071em;
			letter-spacing: 0.1em;
			line-height: 1;
		}
	}
	&--inner{
		display: flex;
		flex-flow: column-reverse;
		.left_box{
			.img{
				width: 726px;
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 10;
				height: auto;
				mix-blend-mode: multiply;
				@include sp{
					width: 100%;
					position: relative;
				}
			}
		}
		.right_box{
			width: 100%;
			margin-bottom: 40px;
			&--inner{
				.box1{
					.img{
						width: 504px;
						position: absolute;
						right: 0;
						top: 94px;
						height: auto;
						@include sp{
							display: none;
						}
					}
				}
				.box2{
					.img{
						width: 737px;
						position: absolute;
						right: 0;
						top: 163px;
						z-index: 20;
						@include sp{
							top: 80px;
							left: 50%;
							transform: translateX(-50%);
							width: 92%;
						}
					}
				}
				.box3{
					width: 375px;
					height: auto;
					position: absolute;
					right: 0;
					bottom: 0;
					@include sp{
						width: 100%;
						position: relative;
					}
					&--inner{
						position: relative;
						&--ttl{
							margin-bottom: 30px;
							@include sp{
								width: 300px;
								margin: 0 auto 15px;
							}
							.img{
								width: 100%;
							}
						}
						&--txt{
							margin-bottom: 30px;
							@include sp{
								width: 80vw;
								margin: 0 auto 20px;
							}
							p{
								font-size: 12px;
								letter-spacing: 0.1em;
								line-height: 2;
								font-weight: bold;
								text-align: justify;
								@include sp{
									text-align: center;
									letter-spacing: 0.15em;
								}
							}
						}
						&--btn{
							a{
								width: 165px;
								height: 54px;
								border: solid 1px $green;
								display: block;
								margin: 0 auto;
								position: relative;
								@include sp{
									width: 150px;
									height: 50px;
								}
								span{
									font-size: 11px;
									letter-spacing: 0.48em;
									line-height: 1;
									font-weight: 700;
									color:$green;
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%,-50%);
								}
							}
						}
					}
				}
			}
		}
	}
}

/*works*/
.pl_top_works{
	width: 90%;
	margin-bottom: 120px;
	@include sp{
		width: 100%;
		margin-bottom: 40px;
	}
	&--inner{
		&--ttl{
			.ttl_inner{
				position: relative;
				height: 300px;
				@include sp{
					height: auto;
					margin-bottom: 50px;
				}
				h2{
					font-size: 3.214em;
					letter-spacing: 0.6em;
					line-height: 1.155;
					display: block;
					position: absolute;
					top: 50%;
					left: 150px;
					margin-top: -25px;
					@include sp{
						font-size: 20px;
						position: relative;
						text-align: center;
						top: 0;
						left: 0;
						margin-top: 0;
						margin-left: 0;
					}
				}
			}
			.ttl_inner:before{
				content:"";
				position: absolute;
				top: 50%;
				left: 0;
				border-top: solid 34px $green;
				width: 480px;
				z-index: -1;
				mix-blend-mode: multiply;
				@include sp{
					width: 50.67%;
					border-top: solid 10px $green;
					top: 60%;
					left: 50%;
					transform: translateX(-50%);
				}
			}
			/*.ttl_inner:after{
				content:"";
				position: absolute;
				top: 0;
				left: 16%;
				border-left: solid 34px $gray;
				height: 300px;
				z-index: -2;
				mix-blend-mode: multiply;
				@include sp{
					display: none;
				}
				}*/
			}
			&--item{
				width: 86.81%;
				margin-left: auto;
				display: flex;
				justify-content: space-between;
				margin-bottom: 60px;
				@include sp{
					width: 100%;
					display: block;
					margin-bottom: 0;
				}
				&--item_wrap{
					position: relative;
					transition: all 1s;
					@include flex;
					@include sp{
						width: 260px;
						padding: 0 5px;
					}
					.img{
						width: 488px;
						height: 488px;
						overflow: hidden;
						@include sp{
							width: 100%;
							height: auto;
							margin-bottom: 20px;
						}
					}
					&--box{
						position: absolute;
						bottom: 0;
						left: 0;
						display: flex;
						width: 100%;
						height: 100%;
						opacity: 0;
						.mask{
							position: absolute;
							top: 417px;
							left: 24px;
							display: flex;
							justify-content: space-between;
							width: 445px;
							p{
								color: #fff;
							}
							.item_ttl{
								z-index: 1;
								.ttl{
									font-size: 16px;
									letter-spacing: 0.2em;
									line-height: 1.4375;
									font-weight: 500;
								}
								.label{
									font-size: 11px;
									letter-spacing: 0.28em;
									line-height: 1;
									font-weight: 500;
									background-color: #fff;
									color: rgba(33,39,98,0.5);
									display: inline-block;
									padding: 2% 3%;
									font-weight: 500;
									color: #000;
								}
							}
							.item_txt{
								z-index: 1;
								dl{
									display: flex;
									color: #fff;
									dt,dd{
										font-size: 11px;
										letter-spacing: 0.1em;
										line-height: 2;
										font-weight: 400;
									}
								}
							}
						}
					}
					&--box:after{
						background-color: #80b3a5;
						mix-blend-mode: multiply;
						content:'';
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 80px;
					}
					&--box:hover{
						opacity: 1;
					}
				}
				&--item_wrap img{
					transition: all 2s;
				}
				&--item_wrap:hover img{
					transform: scale(1.1);
					transition: all 2s;
					@include sp{
						transform: none;
					}
				}
				ul{
					@include flex;
				}
				li{
					margin-bottom: 25px;
					@include sp{
						margin-bottom: 0;
					}
				}
			}
			&--btn{
				width: 86.81%;
				margin-left: auto;
				display: flex;
				justify-content: center;
				@include sp{
					width: 100%;
					margin: 0 auto;
				}
				a{
					width: 165px;
					height: 54px;
					border: solid 1px $green;
					@include flex($justify:center,$items:center);
					span{
						font-size: 0.857em;
						letter-spacing: 0.48em;
						line-height: 1;
						color: $green;
					}
				}
			}
		}
		.slider .ttl{
			text-align: center;
			margin-bottom: 10px;
		}
		.slick-num{
			margin-bottom: 60px;
			text-align: center;
			&--inner{
				span{}
			}
		}
		.slick-arrow{
			width: 10px;
			position: absolute;
			bottom: -15px;
		}
		.arrow_left{
			left: 26px;
		}
		.arrow_right{
			right: 26px;
		}
		.slick-next, .slick-prev{
			display: none;
		}
	}

/*dit*/
.pl_top_dit{
	width: 90%;
	margin-bottom: 160px;
	@include sp{
		width: 100%;
		margin-bottom: 40px;
	}
	&--inner{
		width: 86.81%;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    @include sp{
		width: 100%;
		margin: 0 auto;
		display: block;
	}
		&--left{
			position: relative;
			.left_box{
				width: 488px;
				position: relative;
				@include sp{
					width: 75.47%;
					margin: 0 auto 30px;
				}
				.img{}
				&--inner{
					h2{}
					h3{}
				}
			}
		}

		&--right{
			.right_box{
				width: 488px;
				position: relative;
				height: 0;
				padding-top: 81.15%;
				display: block;
				@include sp{
					width: 75.47%;
					margin: 0 auto;
					padding-top: 61%;
				}
				iframe{
					width: 100%;
					height: 100%;
					position: absolute;
					content: '';
					top: 0;
				}
				img{
					position: absolute;
					top: 0;
					left: 0;
					z-index: 999;
				}
			}
		}
	}
}

/*event*/
.pl_top_event{
	width: 100%;
	height: 775px;
	display: flex;
	@include sp{
		width: 100%;
		padding-top: 0;
		height: auto;
		margin-bottom: 60px;
	}
	&--inner{
		margin: 0 auto;
		@include flex;
		align-items: center;
		@include sp{
			position: relative;
			width: 100%;
		}
		&--left{
			@include sp{
				display: none;
			}
			.img{
				width: 437px;
			}
		}
		&--right{
			margin-left: 50px;
			height: 640px;
			@include sp{
				width: 100%;
				display: block;
				margin-left: 0;
				height: auto;
			}
			&--ttl{
				width: 400px;
				height: 190px;
				display: flex;
				justify-content: space-between;
				margin-bottom: 70px;
				@include sp{
				width: 240px;
				margin: 0 auto;
				height: 165px;
				align-content: center;
			}
				h2{
					color: #fff;
					text-align: center;
					.ttl_en{
						font-size: 2.285em;
						letter-spacing: 0.34em;
						line-height: 1.1875;
						font-weight: 500;
						margin-bottom: 10px;
						@include sp{
						font-size: 20px;
						}
					}
					.ttl_ja{
						font-size: 0.714em;
						letter-spacing: 0.34em;
						line-height: 1;
						font-weight: 500;
						display: block;
						@include sp{
						font-size: 10px;
						}
					}
				}
				.event_ttl{
					position: relative;
					margin-top: 30px;
					@include sp{
						position: relative;
						top: 0;
						left: 0;
						align-content: flex-start;
						margin-top: 50px;
					}
				}
				.event_ttl:after{
					position: absolute;
					top: -30px;
					left: 70%;
					content:'';
					width: 160px;
					height: 190px;
					background-image: linear-gradient(
						to left top,
						transparent 49.5%,
						white 49.5%,
						white 50.5%,
						transparent 50.5%
						);
					@include sp{
						width: 98px;
						height: 122px;
						top: 40px;
						left: 60%;
						transform: translateY(-50%);
					}
				}
				.ws_ttl{
					margin-top: 90px;
					@include sp{
						margin-top: 75px;
					}
				}
			}
			&--article{
				width: 400px;
				color: #fff;
				@include sp{
					width: 100%;
					position: relative;
					top: -5px;
					left: 0;
					background: $green;
					padding: 40px 15px 20px;
					mix-blend-mode: multiply;
				}
				ul>li{
					border-bottom: 1px dashed #fff;
					@include flex;
					margin-bottom: 16px;
				}
				ul>li:last-child{
					border-bottom: 1px dashed #fff;
				}
				.article_btn{
					@include flex;
					width: 100%;
				}
				.left{
					width: 300px;
					@include sp{
						width: 250px;
					}
					.cate_ttl{
						background-color: #fff;
						color: $green;
						display: inline-block;
						font-size: 0.714em;
						letter-spacing: 0.28em;
						line-height: 1;
						font-weight: 500;
						padding: 5px 8px;
						margin-bottom: 8px;
					}
					.ttl{
						display: block;
						font-size: 0.642em;
						letter-spacing: 0.28em;
						line-height: 1.5;
						font-weight: 500;
						margin-bottom: 5px;
					}
					.txt{
						display: block;
						font-size: 0.928em;
						letter-spacing: 0.28em;
						line-height: 1.76;
						font-weight: 500;
						margin-bottom: 10px;
					}
				}
				.right{
					.img{
						width: 86px;
					}
				}
				&--btn{
					display: flex;
					justify-content: center;
					align-items: center;
					width: 85px;
					height: 25px;
					background-color: #fff;
					margin-left: auto;
					a{
						color: $green;
						span{
							font-size: 12px;
							letter-spacing: 0.48em;
							line-height: 1;
							font-weight: 700;
						}
					}
				}
			}
		}
	}
}
.pl_top_event:before{
	position: absolute;
	content:'';
	top: 0;
	left: 0;
	width: 100%;
	height: 290px;
	background-color: $gray;
	z-index: -1;
	mix-blend-mode: multiply;
	@include sp{
		height: 165px;
	}
}
.pl_top_event:after{
	position: absolute;
	content:'';
	bottom: 0;
	left: 0;
	width: 100%;
	height: 500px;
	background-color: $green;
	z-index: -1;
	mix-blend-mode: multiply;
	@include sp{
		display: none;
	}
}
.event_item{
	width: 90%;
	display: inline-flex;
	@include sp{
		width: 100%;
	}
}

/*catalog*/
.pl_top_catalog{
	width: 90%;
	margin: 80px auto 120px;
		position: relative;
		@include sp{
			width: 100%;
			margin-bottom: 80px;
		}
	&--inner{
		content:'';
		background-image: url('/images/common/catalog_bg.jpg');
		background-position: center left;
		background-size: cover;
		width: 715px;
		height: 592px;

		margin-right: auto;
		@include sp{
			background-size: 188px ,185px;
			width: 100%;
			height: auto;
			background-position: 0 285px;
			padding-bottom: 30px;
		}
		&--txt_box{
			width: 630px;
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			@include sp{
				width: 92%;
				margin: 0 auto;
				position: relative;
				top: 0;
				left: 0;
				transform: inherit;
			}
			&--ttl{
				width: 100%;
				height: auto;
				text-align: right;
				.ttl_en{
					font-size: 25px;
					font-weight: 500;
					letter-spacing: 0.6em;
					line-height: 1;
					margin-bottom: 40px;
					@include sp{
						font-size: 20px;
						text-align: center;
						position: relative;
					}
				}
				.ttl_en:after{
				@include sp{
					content:"";
					position: absolute;
					bottom: -3px;
					left: 48.5%;
					transform: translateX( -50%);
					width: 55.07%;
					border-top: solid 10px $gray;
					z-index: -1;
				}
			}
				.ttl_ja{
					font-size: 40px;
					font-weight: 500;
					letter-spacing: 0.48em;
					line-height: 1.89;
					margin-bottom: 40px;
					@include sp{
						font-size: 27px;
						letter-spacing: 0.28em;
						line-height: 1.77;
						text-align: left;
						margin-bottom: 20px;
					}
				}
			}
			.box{
				width: 100%;
				@include flex;
				align-items: center;
				.txt{
					font-size: 14px;
					font-weight: 500;
					letter-spacing: 0.28em;
					line-height: 1.92;
					margin-bottom: 30px;
				}
				.btn{
					a{
						width: 180px;
						height: 60px;
						border: solid 1px $green;
						@include flex($justify:center,$items:center);
						@include sp{
							width: 150px;
							height: 50px;
							margin-left: 155px;
						}
						span{
							font-size: 13px;
							color: $green;
							letter-spacing: 0.48em;
						}
					}
				}
			}
		}
	}
}

/*news*/
.pl_top_news{
	width: 90%;
	margin-bottom: 100px;
	@include sp{
		width: 100%;
		margin: 0 auto 60px;
	}
	&--inner{
		&--ttl{
			position: relative;
			width: 100%;
			height: 40px;
			margin-bottom: 100px;
			@include sp{
				height: auto;
				margin-bottom: 60px;
			}
			h2{
				font-size: 3.214em;
				letter-spacing: 0.6em;
				line-height: 0.86;
				font-weight: 500;
				position: absolute;
				top: -40%;
				left: 47%;
				margin-left: -260px;
				@include sp{
					font-size: 20px;
					position: relative;
					margin-left: 0;
					top: 0;
					left: 0;
					text-align: center;
				}
			}
		}
		&--ttl:after{
			content:"";
			position: absolute;
			top: 0;
			left: 0;
			border-top: solid 34px $green;
			width: 47%;
			z-index: -1;
			@include sp{
				top: 60%;
				left: 50%;
				transform: translateX( -50%);
				width: 50.67%;
				border-top: solid 10px $green;
			}
		}
		&--item{
			width: 86.81%;
			margin-left: auto;
			@include sp{
				width: 92%;
				margin: 0 auto;
			}
			.news_area{
				&--inner{
					@include flex($justify:flex-start);
					@include sp{
						display: block;
					}
					li{
						width: 47.15%;
						border-bottom: 1px solid $gray;
						margin-bottom: 30px;
						@include sp{
							width: 100%;
						}
					}
					.date{
						display: block;
						font-size: 10px;
						letter-spacing: 0.28em;
						line-height: 2.1;
						margin-bottom: 5px;
					}
					.txt{
						display: block;
						font-size: 15px;
						letter-spacing: 0.28em;
						line-height: 2.73;
						font-weight: 500;
						margin-bottom: 20px;
						@include sp{
							font-size: 14px;
							letter-spacing: 0.2em;
							line-height: 1.6;
						}
					}
				}
				&--btn{
					display: flex;
					justify-content: flex-end;
					a{
						width: 85px;
						height: 25px;
						border: solid 1px $green;
						@include flex($justify:center,$items:center);
						span{
							font-size: 13px;
							color: $green;
							letter-spacing: 0.48em;
							font-weight: 700;
						}
					}
				}
			}
		}
	}
}

}