/*
---------------------------------------
fonts
---------------------------------------
*/
.brandon {
    font-family: brandon-grotesque, sans-serif;
    font-style: normal;
}
.din {
	font-family: din-2014, sans-serif;
	font-style: normal;
}
.caslon{
	font-family: adobe-caslon-pro, serif;
	font-weight: 400;
}
.kozuka{
	font-family: '小塚ゴシック Pro','Kozuka Gothic Pro','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',sans-serif;
}

.vertical{
	-webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
