/*contact-parts*/

@media (min-width: 1281px) and (max-width: 3000px){
  .pl_contact-parts--inner{
    margin-right: auto;
  }
}
.pl_contact-parts{
  width: 90%;
  height: auto;
  margin-bottom: 150px;
  .sp{
    display: none;
  }
    @include sp{
      width: 100%;
      margin-bottom: 70px;
      .pc{
        display: none;
      }
    .sp{
      display: block;
    }
    }
  &--inner{
    content:'';
    background-image: url('/images/common/contact_bg.jpg');
    background-position: center center;
    background-size: cover;
    width: 923px;
    height: 525px;
    margin-left: auto;
    @include sp{
      background-image: url('/images/common/sp/contact_bg.jpg');
      width: 100%;
      height: 316px;
      margin: 0 auto;
    }
    &--ttl{
    font-size: 2.421vw;
    font-weight: 500;
    letter-spacing: 0.6em;
    line-height: 1.894;
    padding-top: 4.8%;
    color: #fff;
    text-align: center;
    margin-bottom: 4.8%;
    @include sp{
      font-size: 20px;
    }
  }
    &--item{
      .item{
          background-color: #fff;
          opacity: 0.85;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      .item_side{
        @include flex;
        width: 730px;
        margin: 0 auto 40px;
        @include sp{
          width: 323px;
          margin: 0 auto 20px;
        }
        .item01, .item02, .item03{
          width: 200px;
          height: 200px;
          @include sp{
          width: 100%;
          height: auto;
          padding: 20px 0;
          justify-content: space-around;
          }
        }
        p{
          font-size: 19px;
          font-weight: 500;
          letter-spacing: 0.78em;
          line-height: 2;
          text-align: center;
          margin-left: 0.78em;
          @include sp{
            font-size: 17px;
            margin-left: 0;
          }
        }
        .item_txt01{
          font-size: 12px;
          letter-spacing: 0.2em;
          line-height: 1.75;
          font-weight: 500;
          margin-left: 0;
          text-align-last: left;
        }
      }
      .item04{
          width: 730px;
          height: 87px;
          margin: 0 auto;
          @include sp{
            width: 323px;
          }
          .item_inner{
            @include sp{
              padding: 15px 0;
            }
            span{
              @include sp{
                display: block;
                text-align: center;
              }
            }
          }
        }
        .txt{
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 0.26em;
          line-height: 3.45;
          @include sp{
            font-size: 13px;
            line-height: 1;
            margin-bottom: 10px;
          }
        }
        .tel{
          font-size: 28px;
          font-weight: 500;
          letter-spacing: 0.28em;
          line-height: 2.26;
          margin-left: 20px;
          @include sp{
            font-size: 24px;
            line-height: 1;
            margin-bottom: 10px;
          }
        }
        .time{
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 0.46em;
          line-height: 5.49;
          margin-left: 20px;
          @include sp{
            font-size: 11px;
            line-height: 1;
          }
        }
    }
  }
}