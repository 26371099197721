/*
---------------------------------------
coming_soon
---------------------------------------
*/

#page-comingsoon{

    .pl_coming{
        width: 90%;
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
        &--intro{
            margin-bottom: 120px;
            .img_logo{
                margin: 160px auto 60px;
                width: 200px;
            }
            .ttl{
                text-align: center;
                margin-bottom: 40px;
                p{
                    font-size: 32px;
                    letter-spacing: 0.075em;
                    line-height: 1.85;
                    font-weight: 500;
                }
            }
            .txt{
                text-align: center;
                p{
                    font-size: 18px;
                    letter-spacing: 0.175em;
                    line-height: 2;
                    font-weight: 500;
                }
            }
        }
    }
}