/*
---------------------------------------
contact
---------------------------------------
*/

/*mainvisual*/
#page-contact{

    .l_contents{
        width: 90%;
        position: relative;
        @include sp{
            width: 100%;
            margin-bottom: 40px;
        }
        &--mv-top{
            width: 100%;
            height: 767px;
            background-size: cover;
            background-position: center center;
            @include sp{
                height: 490px;
            }
            &--catch{
                position: absolute;
                bottom: -23px;
                left: 160px;
                @include sp{
                    left: 15px;
                    bottom: -6px;
                }
                h2{
                    font-size: 104px;
                    color: #fff;
                    letter-spacing: 0.24em;
                    line-height: 1.17;
                    position: relative;
                    @include sp{
                        font-size: 40px;
                        letter-spacing: 0.14em;
                        line-height: 1;
                        font-weight: 500;
                    }
                }
                .sub-ttl{
                    font-size: 17px;
                    color: #fff;
                    letter-spacing: 0.9em;
                    line-height: 1;
                    font-weight: 400;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 720px;
                    width: 200px;
                    @include sp{
                        font-size: 16px;
                        letter-spacing: 0.1em;
                        line-height: 1.25;
                        font-weight: 500;
                        left: 240px;
                        top: auto;
                        transform: inherit;
                        bottom: 2px;
                        width: 110px;
                    }
                }
            }
        }
    }

    .pl_contact{
        margin-bottom: 180px;
        width: 92%;
        margin: 0 auto;
        &--inner{
            &--item{
                .reserv_area{
                    width: 900px;
                    height: 160px;
                    margin: 0 auto 80px;
                    background-color: $green;
                    @include sp{
                        width: 100%;
                        height: 135px;
                        margin: 0 auto 40px;
                    }
                    &--inner{
                        color: #fff;
                        height: 160px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 5.46%;
                        @include sp{
                            height: 135px;
                            padding: 20px 0;
                        }
                        .txtarea{
                            text-align: center;
                            margin-left: 16px;
                        }
                        .txt{
                            font-size: 23px;
                            letter-spacing: 0.26em;
                            line-height: 1;
                            font-weight: 500;
                            display: block;
                            margin-bottom: 15px;
                            @include sp{
                                font-size: 16px;
                            }
                        }
                        .time{
                            font-size: 15px;
                            letter-spacing: 0.46em;
                            line-height: 1;
                            font-weight: 500;
                            display: block;
                            @include sp {
                                font-size: 14px;
                            }
                        }
                        .mobile_img{
                            width: 37px;
                            img{
                                width: 100%;
                            }
                        }
                    }
                }
                .form_step{
                    margin-bottom: 80px;
                    @include sp{
                        margin-bottom: 40px;
                    }
                    .step_ttl{
                        h2{
                            font-size: 23px;
                            letter-spacing: 0.26em;
                            line-height: 2.79;
                            font-weight: 500;
                            text-align: center;
                            @include sp{
                                font-size: 14px;
                            }
                        }
                    }
                    .step_ribbon{
                        .ribbon_wrap{
                            width:900px;
                            height:50px;
                            margin: 0 auto 80px;
                            display: flex;
                            position: relative;
                            @include sp{
                                width: 100%;
                                margin: 0 auto 40px;
                            }
                            .ribbon{
                                display:block;
                                width:300px;
                                height:50px;
                                position:relative;
                                @include sp{
                                    width: 33.333%;
                                }
                                p{
                                    display: flex;
                                    width:300px;
                                    height:50px;
                                    clip:rect(0px 340px 50px 0px);
                                    position:absolute;
                                    color:#fff;
                                    align-items: center;
                                    justify-content: center;
                                    @include sp{
                                    width: 123px;
                                    flex-direction: column;
                                    align-content: center;
                                    }
                                    span {
                                        display:block;
                                        @include sp{}
                                    }
                                    .num{
                                        font-size: 10px;
                                        letter-spacing: 0.26em;
                                        line-height: 1;
                                        font-weight: 500;
                                        @include sp{
                                            font-size: 9px;
                                            margin-bottom: 5px;
                                        }
                                    }
                                    .txt{
                                        font-size: 18px;
                                        letter-spacing: 0.26em;
                                        line-height: 1;
                                        font-weight: 500;
                                        margin-left: 10px;
                                        @include sp{
                                            font-size: 14px;
                                            margin-left: 0;
                                        }
                                    }
                                }
                            }
                            .ribbon:first-child {
                                background:#727171;
                            }
                            .ribbon:nth-child(2) {
                                background:#949495;
                            }
                            .ribbon:nth-child(3) {
                                background:#b5b5b6;
                            }
                            .ribbon:first-child :after{
                                border-left: 30px solid #727171;
                            }
                            .ribbon:nth-child(2) :after{
                                border-left: 30px solid #949495;
                            }
                            .ribbon p:after{
                                content:'';
                                border-top: 50px solid transparent;
                                border-bottom: 50px solid transparent;
                                position: absolute;
                                top: -50%;
                                left: 100%;
                                z-index: 1;
                                @include sp{
                                    left: 93%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .form__cont{
            width: 900px;
            margin: 0 auto;
            @include sp{
                width: 100%;
            }
            dl{
                .row.table{
                    margin-bottom: 40px;
                    @include flex;
                    @include sp{
                        border-bottom: 1px dashed #9fa0a0;
                        padding-bottom: 15px;
                        margin-bottom: 20px;
                    }
                    dt{
                        font-size: 15px;
                        letter-spacing: 0.32em;
                        font-weight: 400;
                        width: 160px;
                        @include sp{
                            display: flex;
                            line-height: 1;
                            margin-bottom: 10px;
                            width: 100%;
                        }
                        .req-label{
                            color: #fff;
                            width: 40px;
                            height: 16px;
                            font-size: 11px;
                            letter-spacing: 0.32em;
                            font-weight: 400;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @include sp{
                                margin-right: 10px;
                            }
                        }
                        .label_gray{
                            background-color: $gray;
                        }
                        .label_green{
                            background-color: $green;
                        }
                    }
                    .cat-ttl{
                        @include sp{
                            margin-bottom: 25px;
                        }
                    }
                    dd{
                        width: 490px;
                        display: flex;
                        align-items: center;
                        @include sp{
                            width: 100%;
                        }
                        .yuubin_icon{
                            font-size: 14px;
                            line-height: 1;
                            font-weight: 400;
                            margin-right: 15px;
                        }
                        .yuubin_txt{
                            font-size: 8px;
                            letter-spacing: 0.1em;
                            line-height: 1.75;
                            font-weight: 400;
                            margin-left: 15px;
                            width: 100%;
                        }
                    }
                    .yuubin input{
                        width: 190px;
                        @include sp{
                            width: 100%;
                        }
                    }
                    .visit{
                        width: 490px;
                    }
                    .d-t{
                        justify-content: space-between;
                    }
                    .date{
                        width: 270px;
                    }
                    .time{
                        width: 205px;
                    }
                }
                .row.table:last-child{
                    @include sp{
                        border-bottom: none;
                    }
                }
            }
        }
        .submit-box{
            text-align: center;
            margin-bottom: 60px;
            .submit-btn{
                font-size: 18px;
                letter-spacing: 0.26em;
                line-height: 3.45;
                font-weight: 500;
                color: #fff;
                background-color: #727171;
                width: 375px;
                height: 70px;
                @include sp{
                    width: 275px;
                    height: 65px;
                }
            }
        }
        .alert_txt{
            text-align: center;
            p{
                font-size: 11px;
                letter-spacing: 0.1em;
                line-height: 1.75;
                font-weight: 400;
                color: #727171;
                margin-bottom: 60px;
            }
        }
    }

    input {
        &[type="radio"],
        &[type="checkbox"] {
            & + label {
                @include sp{
                    padding-left: 30px;
                }
                &::before {
                    @include sp{
                        width: 21px;
                        height: 21px;
                    }
                }
            }
        }
    }

}


/*
---------------------------------------
contact-confirm
---------------------------------------
*/

#contact{
    .l_contents{
        width: 90%;
        position: relative;
        margin-bottom: 100px;
        @include sp{
            width: 100%;
            margin-bottom: 40px;
        }
        &--mv-top{
            width: 100%;
            height: 767px;
            background-size: cover;
            background-position: center center;
            @include sp{
                height: 490px;
            }
            &--catch{
                position: absolute;
                bottom: -23px;
                left: 160px;
                @include sp{
                    left: 15px;
                    bottom: -6px;
                }
                h2{
                    font-size: 104px;
                    color: #fff;
                    letter-spacing: 0.24em;
                    line-height: 1.17;
                    position: relative;
                    @include sp{
                        font-size: 40px;
                        letter-spacing: 0.14em;
                        line-height: 1;
                        font-weight: 500;
                    }
                }
                .sub-ttl{
                    font-size: 17px;
                    color: #fff;
                    letter-spacing: 0.9em;
                    line-height: 1;
                    font-weight: 400;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 720px;
                    width: 200px;
                    @include sp{
                        font-size: 16px;
                        letter-spacing: 0.1em;
                        line-height: 1.25;
                        font-weight: 500;
                        left: 240px;
                        top: auto;
                        transform: inherit;
                        bottom: 2px;
                        width: 110px;
                    }
                }
            }
        }
    }

    .sec-mail{
        margin-bottom: 180px;
        width: 92%;
        margin: 0 auto;
        .txt_area{
            margin-bottom: 60px;
            .ttl{
                font-size: 23px;
                letter-spacing: 0.26em;
                line-height: 2.79;
                font-weight: 500;
                text-align: center;
            }
        }
        .form__cont{
            width: 900px;
            margin: 0 auto;
            @include sp{
                width: 100%;
            }
            dl{
                .row.table{
                    margin-bottom: 40px;
                    @include flex;
                    @include sp{
                        border-bottom: 1px dashed #9fa0a0;
                        padding-bottom: 15px;
                        margin-bottom: 20px;
                    }
                    dt{
                        font-size: 15px;
                        letter-spacing: 0.32em;
                        font-weight: 400;
                        width: 160px;
                        @include sp{
                            display: flex;
                            line-height: 1;
                            margin-bottom: 10px;
                            width: 100%;
                        }
                        .req-label{
                            background-color: #9fa0a0;
                            color: #fff;
                            width: 40px;
                            height: 16px;
                            font-size: 11px;
                            letter-spacing: 0.32em;
                            font-weight: 400;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            @include sp{
                                margin-right: 10px;
                            }
                        }
                    }
                    .cat-ttl{
                        @include sp{
                            margin-bottom: 25px;
                        }
                    }
                    dd{
                        width: 490px;
                        display: flex;
                        align-items: center;
                        @include sp{
                            width: 100%;
                        }
                        .yuubin_icon{
                            font-size: 14px;
                            line-height: 1;
                            font-weight: 400;
                            margin-right: 15px;
                        }
                        .yuubin_txt{
                            font-size: 8px;
                            letter-spacing: 0.1em;
                            line-height: 1.75;
                            font-weight: 400;
                            margin-left: 15px;
                            width: 100%;
                        }
                    }
                    .yuubin input{
                        width: 190px;
                        @include sp{
                            width: 100%;
                        }
                    }
                    .visit{
                        width: 490px;
                    }
                    .d-t{
                        justify-content: space-between;
                    }
                    .date{
                        width: 270px;
                    }
                    .time{
                        width: 205px;
                    }
                }
                .row.table:last-child{
                    @include sp{
                        border-bottom: none;
                    }
                }
            }
        }
        .submit-box{
            text-align: center;
            margin-bottom: 60px;
            @include flex;
            @include sp{
                justify-content: center;
            }
            .submit-btn{
                font-size: 18px;
                letter-spacing: 0.26em;
                line-height: 3.45;
                font-weight: 500;
                color: #fff;
                background-color: #727171;
                width: 375px;
                height: 70px;
                display: block;
                @include sp{
                    width: 275px;
                    height: 65px;
                    margin-bottom: 30px;
                }
            }
        }
        .alert_txt{
            text-align: center;
            p{
                font-size: 11px;
                letter-spacing: 0.1em;
                line-height: 1.75;
                font-weight: 400;
                color: #727171;
                margin-bottom: 60px;
            }
        }
    }

    input {
        &[type="radio"],
        &[type="checkbox"] {
            & + label {
                @include sp{
                    padding-left: 30px;
                }
                &::before {
                    @include sp{
                        width: 21px;
                        height: 21px;
                    }
                }
            }
        }
    }

}
