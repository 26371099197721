/*
---------------------------------------
mixin
---------------------------------------
*/

// mediaquery
@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}
@mixin tab-lg {
  @media (max-width: ($tab-lg)) {
    @content;
  }
}
@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

@mixin ie {
    @media all and (-ms-high-contrast: none) {
        @content;
    }
}

// flex
@mixin flex($justify: space-between, $items: stretch) {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    @if $justify == start {
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    } @else if $justify == center  {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    } @else if $justify == end {
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    } @else {
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    @if $items == start {
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    } @else if $items == center {
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    } @else if $items == end {
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
    } @else {
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    }

    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
}

// absolute
@mixin absolute($top: null, $right: null, $bottom: null, $left: null) {
    position: absolute;
    @if $top != null {
        top: $top;
    }
    @if $right != null {
        right: $right;
    }
    @if $bottom != null {
        bottom: $bottom;
    }
    @if $left != null {
        left: $left;
    }
}

// aspect比固定
@mixin aspect($width: 100%, $pt: 100%) {
    position: relative;
    width: $width;
    &::before {
        content: "";
        display: block;
        padding-top: $pt;
    }
    & > * {
        @include absolute(0, 0, 0, 0);
    }
}

// background
@mixin bg($size: cover, $position: center center, $repeat: no-repeat) {
    background-size: $size;
    background-position: $position;
    background-repeat: $repeat;
}

// ttl
@mixin ttl-txt($fz: 24px, $fw: 700, $lts: 0.2em, $lh: 1.5em, $color: #000) {
    font-size: $fz;
    font-weight: $fw;
    letter-spacing: $lts;
    line-height: $lh;
    color: $color;
}

@mixin txt($fz: 15px, $fw: 500, $lts: 0.075em, $lh: 1.85em, $color: #000) {
    font-size: $fz;
    font-weight: $fw;
    letter-spacing: $lts;
    line-height: $lh;
    color: $color;
}

// display: table
@mixin dt-box($width: 100%, $height: null, $ta: center, $va: middle) {
    display: table;
    width: $width;
    height: $height;
    text-align: $ta;

    & > * {
        display: table-cell;
        vertical-align: $va;
    }
}
