/*
---------------------------------------
contact-complete
---------------------------------------
*/

/*mainvisual*/
#page-contact_complete{

    .l_contents{
        width: 90%;
        position: relative;
        @include sp{
            width: 100%;
            margin-bottom: 40px;
        }
        &--mv-top{
            width: 100%;
            height: 767px;
            background-size: cover;
            background-position: center center;
            @include sp{
                height: 490px;
            }
            &--catch{
                position: absolute;
                bottom: -23px;
                left: 160px;
                @include sp{
                    left: 15px;
                    bottom: -6px;
                }
                h2{
                    font-size: 104px;
                    color: #fff;
                    letter-spacing: 0.24em;
                    line-height: 1.17;
                    position: relative;
                    @include sp{
                        font-size: 40px;
                        letter-spacing: 0.14em;
                        line-height: 1;
                        font-weight: 500;
                    }
                }
                .sub-ttl{
                    font-size: 17px;
                    color: #fff;
                    letter-spacing: 0.9em;
                    line-height: 1;
                    font-weight: 400;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 720px;
                    width: 200px;
                    @include sp{
                        font-size: 16px;
                        letter-spacing: 0.1em;
                        line-height: 1.25;
                        font-weight: 500;
                        left: 240px;
                        top: auto;
                        transform: inherit;
                        bottom: 2px;
                        width: 110px;
                    }
                }
            }
        }
    }
    .pl_contact-complete{
        padding: 120px 0;
        @include sp{
            width: 92%;
            margin: 0 auto;
            padding: 0 0 80px;
        }
        &--inner{
            &--item{
                text-align: center;
                h2{
                    font-size: 32px;
                    letter-spacing: 0.2em;
                    line-height: 1.75;
                    font-weight: 500;
                    margin-bottom: 60px;
                    @include sp{
                        font-size: 26px;
                        margin-bottom: 40px;
                    }
                }
                .txt_box{
                    p{
                        font-size: 18px;
                        letter-spacing: 0.2em;
                        line-height: 2;
                        font-weight: 500;
                        @include sp{
                        font-size: 15px;
                        letter-spacing: 0.075em;
                        margin-bottom: 20px;
                    }
                    }
                }
            }
        }
    }
}

