/*
---------------------------------------
reset
---------------------------------------
*/
html{
    height: 100%;
    overflow-x: hidden;
    -webkit-text-size-adjust: 100%;
}
body {
    height: 100%;
    margin: 0 auto;
}
body,div,dl,dt,dd,ul,ol,li,p,th,td,figure,section,article,aside,main {
    margin: 0;
    padding: 0;
}
main{
    display: block;
}
span{
    display: inline-block;
}
table{
    border-collapse: collapse;
    border-spacing: 0;
    font-size: inherit;
    font-size: 100%;
}
img {
    margin: 0;
    padding: 0;
    border: none;
    line-height: 0;
    vertical-align: bottom;
    outline: none;
}
ul li{
    list-style:none;
}
h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
    font-size: 100%;
    font-weight: normal;
    font-style: normal;
}
body,*:before,*:after{
    font-size: 14px;
    *font-size: small;
    *font: x-small;
    font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN","Hiragino Mincho Pro",HGS明朝E,メイリオ,Meiryo,serif,noto-sans-cjk-jp, YuGothic, '游ゴシック','Noto Sans JP', "Helvetica Neue","Helvetica Neue LT Std","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","Helvetica Neue",Arial,"メイリオ",Meiryo,"MS PGothic",sans-serif;
    color: #000000;
}
input,select,button,textarea,table{
    font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN","Hiragino Mincho Pro",HGS明朝E,メイリオ,Meiryo,serif,noto-sans-cjk-jp, YuGothic, '游ゴシック','Noto Sans JP', "Helvetica Neue","Helvetica Neue LT Std","ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","Helvetica Neue",Arial,"メイリオ",Meiryo,"MS PGothic",sans-serif;
    line-height: 1.75;
    font-size: 100%;
    box-sizing: border-box;
    color: #000000;
}
cite,address,em {
    font-style: normal;
}
a{
    text-decoration: none;
    color: inherit;
}
*{
    background-repeat: no-repeat;
    box-sizing: border-box;
}
input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type=radio],
input[type=checkbox] {
    display: none;
}
