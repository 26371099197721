/*
---------------------------------------
company
---------------------------------------
*/

/*mainvisual*/
#page-company{
    .bg_green{
            width: 100%;
            background-color: $green;
        }
        .bg_gray{
            width: 100%;
            background-color: #b2b8b9;
        }

    .l_contents{
        width: 90%;
        position: relative;
        @include sp{
            width: 100%;
            margin-bottom: 60px;
        }
        &--mv-top{
            width: 100%;
            height: 767px;
            background-size: cover;
            background-position: center center;
            @include sp{
                height: 490px;
            }
            &--catch{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                h2{
                    font-size: 7.578vw;
                    color: #fff;
                    letter-spacing: 0.24em;
                    line-height: 1;
                    position: relative;
                    @include sp{
                        font-size: 40px;
                    }
                }
                .sub-ttl{
                    font-size: 1.171vw;
                    color: #fff;
                    letter-spacing: 0.9em;
                    line-height: 1;
                    font-weight: 400;
                    position: absolute;
                    bottom: -3.47vh;
                    left: 50%;
                    transform: translate(0% ,-50%);
                    @include sp{
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .pl_company{
        width: 100%;
        margin-bottom: 120px;
        @include sp{
            margin-bottom: 0;
        }
        &--inner{
            width: 90%;
            @include sp{
                width: 92%;
                margin: 0 auto;
                padding-bottom: 40px;
            }
        &--link_area{
            width: 86.81%;
            margin-left: auto;
            margin-bottom: 80px;
            @include sp{
                width: 100%;
                margin: 0 auto;
            }
            .link_btn{
                @include flex;
                @include sp{
                    margin-bottom: 20px;
                }
                &--item{
                    width: 250px;
                    border-bottom: 1px solid #231815;
                    @include sp{
                        width: 100%;
                        margin-bottom: 40px;
                    }
                    .btn{
                        display: block;
                        span{
                            display: block;
                            text-align: center;
                        }
                        .txt{
                            font-size: 15px;
                            letter-spacing: 0.28em;
                            line-height: 1;
                            font-weight: 500;
                        }
                        .arrow-icon{}
                        .img{
                            width: 15px;
                            display: block;
                            margin: 0 auto 30px;
                        }
                    }
                }
            }
        }
        &--staff{
            width: 90%;
            @include sp{
                width: 100%;
            }
            .txt{
                color: #fff;
                font-weight: 500;
            }
            &--item{
                width: 86.81%;
                margin-left: auto;
                padding: 60px 0 70px;
                @include sp{
                    width: 100%;
                    margin: 0 auto;
                    padding: 0;
                }
                .staff_item01{
                    margin-bottom: 110px;
                    @include sp{
                        margin-bottom: 60px;
                    }
                    .ttl{
                        font-size: 31px;
                        letter-spacing: 0.28em;
                        line-height: 2;
                        margin-bottom: 60px;
                        position: relative;
                        @include sp{
                            font-size: 26px;
                            padding-top: 20px;
                            text-align: center;
                            padding-left: 10px;
                            margin-bottom: 40px;
                        }
                    }
                    .ttl:after{
                        content:'';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        border-top: 1px solid #fff;
                        width: 153px;
                        @include sp{
                            left: 50%;
                            transform: translateX(-50%);
                            width: 135px;
                        }
                    }
                    &--inner{
                        display: flex;
                        @include sp{
                            display: block;
                        }
                        .img{
                            width: 400px;
                            @include sp{
                                width: 100%;
                            }
                        }
                        .txt_box{
                            width: 475px;
                            margin-left: 20px;
                            @include sp{
                                width: 100%;
                                margin-left: 0;
                            }
                            .name_item{
                                margin-bottom: 30px;
                                .name_ja{
                                    font-size: 31px;
                                    letter-spacing: 0.28em;
                                    line-height: 2;
                                    @include sp{
                                        font-size: 22px;
                                    }
                                }
                                .name_en{
                                    font-size: 11px;
                                    letter-spacing: 0.28em;
                                    line-height: 1;
                                }
                            }
                            .ttl_item{
                                margin-bottom: 25px;
                                p{
                                    font-size: 21px;
                                    letter-spacing: 0.1em;
                                    line-height: 1.95;
                                    @include sp{
                                        font-size: 16px;
                                    }
                                }
                            }
                            .txt_area{
                                p{
                                    font-size: 11px;
                                    letter-spacing: 0.15em;
                                    line-height: 2.272;
                                    margin-bottom: 30px;
                                    text-align: justify;
                                }
                                .btn_area{
                                    @include flex;
                                    .y_tube{
                                        width: 228px;
                                        height: 83px;
                                        border: solid 1px #fff;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        @include sp{
                                            width: 160px;
                                            height: 75px;
                                        }
                                        .txt{
                                            font-size: 16px;
                                            letter-spacing: 0.2em;
                                            line-height: 1.375;
                                            @include sp{
                                                font-size: 14px;
                                            }
                                        }
                                        .icon{
                                            width: 52px;
                                            margin-left: 20px;
                                            @include sp{
                                                width: 40px;
                                                margin-left: 5px;
                                            }
                                        }
                                    }
                                    .sns_btn{
                                        width: 228px;
                                        height: 83px;
                                        border: solid 1px #fff;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        @include sp{
                                            width: 160px;
                                            height: 75px;
                                        }
                                        .txt{
                                            font-size: 16px;
                                            letter-spacing: 0.2em;
                                            line-height: 1.375;
                                            @include sp{
                                                font-size: 14px;
                                            }
                                        }
                                        .icon{
                                            width: 18px;
                                            margin-left: 15px;
                                            @include sp{
                                                margin-left: 5px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .staff_item02{
                    margin-top: -60px;
                    padding-top: 60px;
                    .ttl{
                        font-size: 31px;
                        letter-spacing: 0.28em;
                        line-height: 2;
                        position: relative;
                        margin-bottom: 60px;
                        @include sp{
                            font-size: 26px;
                            padding-top: 20px;
                            text-align: center;
                            padding-left: 10px;
                            margin-bottom: 40px;
                        }
                    }
                    .ttl:after{
                        content:'';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        border-top: 1px solid #fff;
                        width: 196px;
                        @include sp{
                            left: 50%;
                            transform: translateX(-50%);
                            width: 190px;
                        }
                    }
                    &--inner{
                        @include flex;
                        @include sp{
                            display: block;
                        }
                        .staff_box{
                            width: 460px;
                            @include sp{
                                width: 100%;
                                margin-bottom: 40px;
                            }
                            &--inner{
                                display: flex;
                                margin-bottom: 30px;
                                @include sp{
                                    display: block;
                                }
                                .img{
                                    width: 230px;
                                    @include sp{
                                        width: 100%;
                                        margin-bottom: 20px;
                                    }
                                }
                                .name_item{
                                    align-self: flex-end;
                                    margin-left: 30px;
                                    @include sp{
                                        display: flex;
                                        margin-left: 0;
                                    }
                                    .name_ttl{
                                        display: block;
                                        font-size: 12px;
                                        letter-spacing: 0.28em;
                                        line-height: 1.2;
                                        @include sp{
                                            display: none;
                                        }
                                    }
                                    .name_ja{
                                        display: block;
                                        font-size: 31px;
                                        letter-spacing: 0.28em;
                                        line-height: 2;
                                        @include sp{
                                            font-size: 22px;
                                        }
                                    }
                                    .name_en{
                                        display: block;
                                        font-size: 11px;
                                        letter-spacing: 0.28em;
                                        line-height: 1;
                                        @include sp{
                                            display: flex;
                                            align-items: center;
                                            margin-top: 5px;
                                        }
                                    }
                                }
                            }
                            .txt_area{
                                p{
                                    font-size: 13px;
                                    letter-spacing: 0.28em;
                                    line-height: 1.92;
                                    margin-bottom: 30px;
                                    text-align: justify;
                                }
                                p:last-child{
                                    @include sp{
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                        .staff_box:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        &--info{
            width: 90%;
            padding: 80px 0 100px;
            @include sp{
                width: 100%;
                padding: 40px 0 0;
            }
            &--inner{
                width: 86.81%;
                margin-left: auto;
                @include sp{
                    width: 100%;
                    margin: 0 auto;
                }
                .ttl{
                    font-size: 31px;
                    letter-spacing: 0.28em;
                    line-height: 2;
                    margin-bottom: 70px;
                    @include sp{
                        font-size: 26px;
                        margin-bottom: 20px;
                        text-align: center;
                    }
                }
                &--item{
                    @include flex($justify:start);
                    .txt_area{
                        width: 590px;
                        @include sp{
                            width: 100%;
                            margin-bottom: 30px;
                        }
                        dl{
                            @include flex($justify:start);
                            border-bottom: 1px solid $gray;
                            padding: 24px 0;
                            letter-spacing: 0.32em;
                            @include sp{
                                padding: 20px 0;
                            }
                            dt{
                                width: 130px;
                                font-weight: 700;
                                @include sp{
                                    width: 100px;
                                }
                            }
                            dd{
                                @include sp{
                                    width: calc(100% - 100px);
                                }
                            }
                        }
                        dl:first-child{
                            border-top: 1px solid $gray;
                        }
                    }
                    .img_area{
                        margin-left: 30px;
                        @include sp{
                            margin-left: 0;
                        }
                        .img{
                            width: 277px;
                            margin-bottom: 30px;
                            @include sp{
                                width: 100%;
                            }
                        }
                        .img:last-child{
                            @include sp{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
        &--history{
            width: 90%;
            padding: 80px 0 100px;
            @include sp{
                width: 100%;
                padding: 40px 0 0;
            }
            &--inner{
                width: 86.81%;
                margin-left: auto;
                @include sp{
                    width: 100%;
                    margin: 0 auto;
                }
                .ttl{
                    font-size: 31px;
                    letter-spacing: 0.28em;
                    line-height: 2;
                    margin-bottom: 70px;
                    @include sp{
                        font-size: 26px;
                        margin-bottom: 20px;
                        text-align: center;
                    }
                }
                &--item{
                    @include flex($justify:start);
                    .txt_area{
                        width: 590px;
                        @include sp{
                            width: 100%;
                        }
                        dl{
                            @include flex($justify:start);
                            border-bottom: 1px solid #231815;
                            padding: 24px 0;
                            letter-spacing: 0.32em;
                            dt{
                                width: 130px;
                                font-weight: 700;
                                @include sp{
                                    width: 100px;
                                }
                            }
                            dd{
                                @include sp{
                                    width: calc(100% - 100px);
                                }
                            }
                        }
                        dl:first-child{
                            border-top: 1px solid #231815;
                        }
                    }
                }
            }
        }
    }
}
}