/*
---------------------------------------
model_plan
---------------------------------------
*/

/*mainvisual*/
#page-model_plan{

    .l_contents{
        width: 90%;
        position: relative;
        @include sp{
            width: 100%;
            margin-bottom: 40px;
        }
        &--mv-top{
            width: 100%;
            height: 767px;
            background-size: cover;
            background-position: center center;
            @include sp{
                height: 490px;
            }
            &--catch{
                position: absolute;
                bottom: -15px;
                left: 140px;
                @include sp{
                    left: 15px;
                    bottom: 0;
                }
                h2{
                    font-size: 94px;
                    color: #231815;
                    letter-spacing: 0.16em;
                    line-height: 1;
                    position: relative;
                    @include sp{
                        font-size: 40px;
                        letter-spacing: 0.2em;
                        color: #fff;
                    }
                    .img{
                        width: 311px;
                        position: absolute;
                        top: -114px;
                        left: 0;
                        @include sp{
                            width: 125px;
                            top: -44px;
                            left: 0;
                        }
                    }
                }
            }
        }
    }

    .l_breadcrumb{
        &--inner{
            &--item{
                color: #fff;
            }
            &--item:after{
                background-image: linear-gradient(to left top, transparent 49.5%, white 49.5%, white 50.5%, transparent 50.5%);
            }
        }
    }

    .pl_model_plan{
        width: 100%;
        &--intro{
            margin-bottom: 100px;
            @include sp{
                margin-bottom: 60px;
            }
            .bg_green{
                position: relative;
            }
            .bg_green:after{
                content:'';
                width: 100%;
                height: 100%;
                background-color: $green;
                position: absolute;
                top: 0;
                left: 0;
                padding-top: 230px;
                margin-top: -230px;
                z-index: -1;
                @include sp{
                    padding-top: 40px;
                    margin-top: -40px;
                }
            }
            &--txt_area{
                width: 90%;
                padding-bottom: 80px;
                @include sp{
                    width: 92%;
                    margin: 0 auto;
                    padding-bottom: 40px;
                }
                .intro_ttl{
                    width: 100%;
                    h2{
                        text-align: center;
                        margin-bottom: 60px;
                        @include sp{
                            margin-bottom: 40px;
                        }
                        .img{
                            width: 850px;
                            @include sp{
                                width: 100%;
                            }
                        }
                    }
                }
                .intro_txt{
                    width: auto;
                    margin: 0 auto;
                    @include sp{
                        width: 100%;
                    }
                    p{
                        font-size: 19px;
                        letter-spacing: 0.22em;
                        line-height: 2.11;
                        font-weight: 500;
                        color: #fff;
                        text-align: center;
                        @include sp{
                            font-size: 14px;
                            line-height: 1.75;
                            text-align: justify;
                        }
                    }
                }
            }
            &--img_area{
                .img_inner{
                    @include flex;
                    li{
                        width: 20%;
                        @include sp{
                            width: 50%;
                        }
                        .img{}
                    }
                }
            }
        }
    }
}