/*
---------------------------------------
form
---------------------------------------
*/

input {
    &[type="text"],
    &[type="email"] {
        width: 490px;
        height: 65px;
        border: 1px solid $gray;
        padding: 17px 25px;
        background-color: #fff;
        @include txt($fz: 16px, $fw: 500, $color: #231815);

        @include sp {
            width: 100%;
            height: 42px;
            font-size: 15px;
            font-size: 15px;
            padding: 0 15px;
        }

        &::placeholder {
            @include txt($fz: 16px, $fw: 500, $color: #E0E0E0);
            font-size: 15px;
        }
    }
}

select {
    width: 345px;;
    height: 65px;
    border: 1px solid $gray;
    padding: 0 65px 0 25px;
    background: url('/images/contact/arrow-gr.svg');
    background-size: 16px auto, 100% 100%;
    background-position: right 22px center, center center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    @include txt($fz: 16px, $fw: 500, $color: #231815);

    @include ie {
        background-image: none;
        padding: 0 0 0 25px;
    }

    @include sp {
        width: 100%;
        height: 42px;
        background: url('/images/contact/arrow-gr.svg');
        background-size: 16px auto, 100% 100%;
        background-position: right 19px center, center center;
        background-repeat: no-repeat;
        font-size: 15px;
        padding: 0 56px 0 15px;
    }
}

input[type="date"] {
    width: 345px;;
    height: 65px;
    border: 1px solid $gray;
    padding: 0 25px 0 25px;
    background-color: #fff;
    @include txt($fz: 16px, $fw: 500, $color: #231815);

    @include sp {
        width: 150px;
        height: 42px;
        font-size: 15px;
        padding: 0 58px 0 15px;
    }

    &::placeholder {
        @include txt($fz: 16px, $fw: 500, $color: #E0E0E0);
        font-size: 15px;
    }
}

textarea {
    width: 100%;
    min-height: 280px;
    border: 1px solid $gray;
    padding: 10px 25px;
    background-color: #fff;

    @include sp {
        min-height: 200px;
        padding: 10px 15px;
    }
}

input {
    &[type="radio"],
    &[type="checkbox"] {
        display: none;

        & + label {
            position: relative;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.32em;
            line-height: 1.6;
            padding-left: 45px;

            &::before,
            &::after {
                @include absolute($top: 50%);
                content: "";
                display: block;
                transform: translate3d(0, -50%, 0);
                border-radius: 50%;
                box-sizing: border-box;
            }

            &::before {
                width: 30px;
                height: 30px;
                left: 0;
                background-color: #fff;
                border: 1px solid $gray;
            }
            &::after {
                width: 12px;
                height: 12px;
                left: 9px;
                background-color: transparent;
            }
        }

        &:checked {
            & + label {
                &::after {
                    background-color: $gray;
                }
            }
        }
    }
}


