/*
---------------------------------------
footer
---------------------------------------
*/
.l_footer {
    width: 90%;
    position: relative;
    .sp{
        display: none;
    }
    @media (min-width: 1281px) and (max-width: 2560px){
        max-width: 1280px;
        margin-right: auto;
        margin-left: auto;
    }
    @include sp{
        width: 92%;
        margin: 0 auto 20px;
        padding-bottom: 90px;
        .pc{
            display: none;
        }
        .sp{
            display: block;
        }
    }
        .top_btn{
            font-style: italic;
            font-size: 15px;
            color: $green;
            position: absolute;
            bottom: 80px;
            right: 0;
            transform: rotate(90deg);
            @include sp{
                display: none;
            }
            .btn{
            	position: relative;
            }
            .btn:before,.btn:after{
            	content: '';
            	display: block;
            	position: absolute;
            	top: 120%;
            	right: -50px;
            	height: 1px;
            	background: $green;
            }
            .btn:before{
            	width: 127px;
            	transform: translate(30px, 0px);
            }
            .btn:after{
            	width: 10px;
            	transform-origin: right center;
            	transform: translate(-97px, 0px) rotate(150deg);
            }
        }

    &--inner {
        width: 86.81%;
        margin-left: auto;
        @include sp {
            width: 100%;
        }
        small {
            font-size: 10px;
            font-weight: 400;
            color: #727171;
            font-style: italic;
            letter-spacing: 0.18em;
            @include sp {
                font-size: 3px;
                text-align: center;
            }
        }
        &--nav{
            width: 750px;
            letter-spacing: 0.32em;
            margin-bottom: 80px;
            @include sp{
                width: 100%;
                font-size: 14px;
            }
            ul{
            	@include flex;
            	margin-bottom: 10px;
            }
            .nav_item02{
                @include sp{
                    justify-content: flex-start;
                }
                li:last-child{
                    margin-left: 40px;
                }
            }
        }
        &--sub_nav{
            @include flex;
            justify-content: space-between;
            font-size: 9px;
            width: 710px;
            margin-bottom: 100px;
            letter-spacing: 0.32em;
            @include sp{
                width: 100%;
                display: block;
                margin: 0 auto;
            }
            .nav_box{
                width: 345px;
                @include flex;
                @include sp{
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 60px;
                }
                .txt{
                    ul li{
                        margin-bottom: 10px;
                    }
                    ul li:last-child{
                        margin-bottom: 0;
                    }
                    .txt_mid{
                        font-size: 14px;
                        @include sp{
                            font-size: 13px;
                        }
                    }
                }
                .company_txt{
                    @include sp{
                        margin-left: 23.19%;
                    }
                }
            }
            .icon_area{
                display: flex;
                @include sp{
                    display: flex;
                    flex-direction: column-reverse;
                }
                &--inner{
                    display: flex;
                    @include sp{
                        justify-content: center;
                        margin-bottom: 40px;
                    }
                    &--insta{
                        width: 30px;
                        display: flex;
                        align-items: flex-end;
                        @include sp{
                            display: block;
                        }
                    }
                    &--fb{
                        width: 15px;
                        display: flex;
                        align-items: flex-end;
                        margin-left: 25px;
                        @include sp{
                            display: block;
                            margin-left: 25px;
                        }
                    }
                }
                &--foot_logo{
                    width: 165px;
                    display: flex;
                    align-items: flex-end;
                    margin-left: 40px;
                    @include sp{
                        display: block;
                        margin: 0 auto 25px;
                        width: 52.17%;
                    }
                }
            }
        }
    }
}
.l_footer--fix_btn{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    .fix_btn-inner{
        width: 100vw;
        height: 65px;
        display: flex;
        .left_item{
            width: 58.67vw;
            background-color: #00AA82;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon{
                width: 27px;
            }
            .txt_area{
                color: #fff;
                font-weight: 500;
                margin-left: 15px;
                text-align: center;
                line-height: 1.5;
                letter-spacing: 0.1em;
                .txt{
                    font-size: 12px;
                    display: block;
                }
                .time{
                    font-size: 11px;
                    display: block;
                }
            }
        }
        .right_item{
            width: 41.07vw;
            background-color: #FFAD00;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon{
                width: 31px;
            }
            .txt{
                font-size: 12px;
                color: #fff;
                font-weight: 500;
                margin-left: 10px;
                line-height: 1.5;
                letter-spacing: 0.1em;
            }
        }
    }
}
