/*
---------------------------------------
utility
---------------------------------------
*/
.pu_circle {
    @include dt-box($width: 24px, $height: 24px);
    border-radius: 50%;
    background-color: #fff;

    & > * {
        line-height: 0;
    }

    .icon-arrow-right {
        font-size: 10px;
        color: $blue;
        transform: scale(.6);
    }

}
.pu_square {
    @include dt-box($width: 58px, $height: 58px);
    background-color: #fff;

    @include sp {
        width: 35px;
        height: 35px;
    }

    & > * {
        line-height: 0;
    }

    .icon-arrow-right {
        font-size: 16px;
        @include sp {
            font-size: 10px;
            transform: scale(.9);
        }
    }
}


