/*
---------------------------------------
header
---------------------------------------
*/
.l_header {
	position: relative;
    &--logo{
    	position: absolute;
    	top: 40px;
    	left: 140px;
        z-index: 999;
        @include sp{
            top: 30px;
            left: 24px;
        }
        .img{
            width: 183px;
            @include sp{
                width: 160px;
            }
        }
    }
}