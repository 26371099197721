/*
---------------------------------------
works
---------------------------------------
*/

	/*mainvisual*/
	#page-works{

		.l_contents{
			width: 90%;
			height: 803px;
			position: relative;
			display: flex;
			align-items: center;
			padding-top: 2.5%;
			@include sp{
				width: 100%;
				height: auto;
				padding-top: 0;
				margin-bottom: 60px;
			}
			&--mv-top{
				width: 86.81%;
				height: 556px;
				background-size: cover;
				background-position: center center;
				margin-left: auto;
				position: relative;
				@include sp{
				width: 100%;
				height: 490px;
			}
				&--catch{
					position: absolute;
					bottom: 0;
					left: 6.94%;
					display: flex;
					margin-bottom: -50px;
					@include sp{
						left: 15px;
						bottom: 0;
						margin-bottom: 0;
						width: 92%;
					}
					h2{
						font-size: 90px;
						color: #fff;
						letter-spacing: 0.32em;
						line-height: 1;
						font-weight: 500;
						position: relative;
						@include sp{
							font-size: 40px;
							bottom: -6px;
						}
					}
					.sub-ttl{
						font-size: 18px;
						color: #fff;
						letter-spacing: 0.9em;
						line-height: 1;
						font-weight: 400;
						position: absolute;
						bottom: 15%;
						right: -9vw;
						@include sp{
							font-size: 16px;
							bottom: 0;
							left: 215px;
							width: 130px;
						}
					}
				}
			}
		}
		.l_contents:after{
				content: '';
				width: 69.92%;
				height: 804px;
				background-color: $green;
				position: absolute;
				top: 0;
				left: 0;
				z-index: -2;
				@include sp{
					display: none;
				}
			}

			@media (min-width: 1281px) and (max-width: 2560px){
				.page-works{
					max-width: 1280px;
					margin-right: auto;
					margin-left: auto;
				}
				.pl_contact-parts{}
				.pl_contact-parts--inner{
					margin-right: auto;
				}
			}

		/*カテゴリーボタン*/
		.pl_works{
			width: 90%;
			@include sp{
				width: 92%;
				margin: 0 auto;
			}
			&--category{
				width: 90%;
				max-width: 1280px;
				margin-left: auto;
				margin-bottom: 100px;
				@include sp{
					width: 100%;
					margin: 0 auto;
					margin-bottom: 30px;
				}
				.cate_btn{
					@include flex($justify:start);
					@include sp{
						@include flex($justify:space-between);
					}
					.btn{
						margin-right: 20px;
						@include sp{
							margin-bottom: 15px;
							margin-right: 0;
						}
						a{
							width: 236px;
							height: 106px;
							display: table-cell;
							vertical-align: middle;
							background-color: $gray;
							@include sp{
								width: 165px;
								height: 90px;
							}
							span{
								display: block;
								text-align: center;
								color: #fff;
							}
							.btn_en{
								font-size: 16px;
								letter-spacing: 0.3em;
								line-height: 1.75;
								font-weight: 500;
								@include sp{
									font-size: 14px;
								}
							}
							.btn_ja{
								font-size: 9px;
								letter-spacing: 0.32em;
								line-height: 1.75;
								font-weight: 400;
							}
						}
						a:hover{
							opacity: 0.8;
						}
					}
					.btn:last-child{
						margin-right: 0;
					}
				}
			}

			/*施工事例アイテム*/
			&--inner{
				width: 100%;
				&--list{
					width: 90%;
					margin-left: auto;
					margin-bottom: 100px;
					@include flex;
					@include sp{
						width: 100%;
						margin-bottom: 60px;
					}
					&--item{
						width: 488px;
						margin-bottom: 40px;
						@include sp{
							width: 100%;
						}
						a{
							.thumb--wrap{
								position: relative;
								margin-bottom: 15px;
								.thumb{
									&::before {
										padding-top: 100%;
									}
								}
							}
							.txt_area{
								.ttl{
									font-size: 11px;
									letter-spacing: 0.16em;
									line-height: 2;
									font-weight: 500;
									color: #231815;
									margin-bottom: 5px;
								}
								.label{
									font-size: 8px;
									letter-spacing: 0.32em;
									line-height: 1;
									font-weight: 400;
									color: #a2a2a2;
									border: solid 1px #a2a2a2;
									display: inline-block;
									padding: 4px 5px;
								}
							}
						}
					}
					&--item:last-child{
						@include sp{
							margin-bottom: 0;
						}
					}
				}
			}

			/*pagination*/
			.pagination{
				margin-bottom: 140px;
				@include sp{
					margin-bottom: 60px;
				}
				&--inner{
					width: 90%;
					margin-left: auto;
					@include sp{
						width: 100%;
					}
					&--list{
						@include flex($justify:start);
						@include sp{
							justify-content: center;
						}
						&--item{
							margin: 0 1.56vw;
							.page-link{
								color: #231815;
								padding: 0;
								display: inline-block;
								line-height: 1;
								position: relative;
								.arrow-left_txt{
									padding-left: 20px;
								}
								.arrow-left{
									position: absolute;
									top: 50%;
									transform: translateY(-50%);
									left: 0;
									img{
										width: 10px;
									}
								}
								.arrow-right_txt{
									padding-right: 20px;
								}
								.arrow-right{
									position: absolute;
									top: 50%;
									transform: translateY(-50%);
									right: 0;
									img{
										width: 10px;
									}
								}
							}
							.page-link.is-active{
								border-bottom: 1px solid #231815;
							}
						}
						&--item:first-child{
							margin-left: 0;
						}
					}
				}
			}
		}

		/*works-detail*/
		.page-works-dtl{
			.l_header--logo{
				position: relative;
				top: 40px;
				left: 140px;
				z-index: 999;
				@include sp{
					position: absolute;
					top: 30px;
					left: 24px;
				}
			}
			.l_breadcrumb {
				width: 90%;
				position: relative;
				padding: 20px 0 100px;
				margin-top: 60px;
				@include sp{
					display: none;
				}
			}
		}
		.pl_works-detail{
			&--inner{
				&--head{
					background-color: $green;
					text-align: center;
					height: 203px;
					@include sp{
						height: 320px;
					}
					.head_inner{
						padding: 36px 0 70px;
						@include sp{
							padding: 150px 0 70px;
						}
					}
				}
			}
			/*detail-pagination*/
			.pagination{
				&--inner{
					&--list{
						&--item{}
						.list_txt{
							margin: 0 auto;
							p{
								color: #fff;
								background-color: $green;
							}
						}
					}
				}
			}
		}

	}