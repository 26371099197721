/*
---------------------------------------
event/work shop
---------------------------------------
*/

/*mainvisual*/
#page-event{

    .l_contents{
        width: 90%;
        position: relative;
        @include sp{
            width: 100%;
            margin-bottom: 40px;
        }
        &--mv-top{
            width: 100%;
            height: 767px;
            background-size: cover;
            background-position: center center;
            @include sp{
                height: 490px;
            }
            &--catch{
                position: absolute;
                bottom: -23px;
                left: 11%;
                @include sp{
                    bottom: -6px;
                    left: 15px;
                }
                h2{
                    font-size: 104px;
                    color: #fff;
                    letter-spacing: 0.24em;
                    line-height: 1.17;
                    position: relative;
                    @include sp{
                        font-size: 40px;
                        letter-spacing: 0.18em;
                        line-height: 1;
                    }
                }
                .sub-ttl{
                    font-size: 17px;
                    color: #fff;
                    letter-spacing: 0.9em;
                    line-height: 1;
                    font-weight: 400;
                    position: absolute;
                    top: 20%;
                    left: 70%;
                    @include sp{
                        font-size: 16px;
                        letter-spacing: 0.4em;
                        line-height: 1.25;
                        width: 160px;
                        top: 0;
                        left: 200px;
                    }
                }
            }
        }
    }

    .pl_event{
        width: 100%;
        &--inner{
            width: 90%;
            @include sp{
                width: 92%;
                margin: 0 auto;
            }
        &--sec_ttl{
            width: 86.81%;
            margin-left: auto;
            margin-bottom: 80px;
            @include sp{
                width: 100%;
                margin-bottom: 40px;
            }
            h3{
                font-size: 24px;
                letter-spacing: 0.3em;
                line-height: 2.33;
                font-weight: 500;
                text-align: center;
                @include sp{
                    font-size: 18px;
                    letter-spacing: 0.1em;
                }
            }
        }
        &--cat_btn{
            width: 86.81%;
            margin-left: auto;
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            @include sp{
                width: 100%;
                flex-direction: column;
            }
            .cat_ttl{
                @include sp{
                    display: block;
                    margin-right: auto;
                    margin-bottom: 5px;
                }
                span{
                    font-size: 13px;
                    letter-spacing: 0.28em;
                    line-height: 1;
                    font-weight: 500;
                    @include sp{
                        font-size: 16px;
                    }
                }
            }
            .btn_area{
                margin-left: 20px;
                @include sp{
                    width: 100%;
                    margin-left: 0;
                }
                ul{
                    display: flex;
                    @include sp{
                        width: 100%;
                        justify-content: flex-start;
                    }
                    .btn{
                        margin-left: 16px;
                        @include sp{
                            margin-left: 0;
                            margin-right: 15px;
                        }
                        a{
                            width: 148px;
                            height: 36px;
                            border: solid 1px #231815;
                            display: table-cell;
                            vertical-align: middle;
                            text-align: center;
                            @include sp{
                                width: 100px;
                                height: 40px;
                            }
                            span{
                                font-size: 15px;
                                letter-spacing: 0.28em;
                                line-height: 1;
                                font-weight: 500;
                                display: block;
                                @include sp{
                                    font-size: 13px;
                                }
                            }
                        }
                        a.is-active{
                            background-color: #231815;
                            color: #fff;
                        }
                    }
                    .btn:last-child{
                        @include sp{
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
        &--inner{
            &--item{
                width: 100%;
                margin-bottom: 110px;
                @include sp{
                    margin-bottom: 60px;
                }
                .bg{
                    background-color: #fff;
                    display: flex;
                    width: 100%;
                    @include sp{
                        padding: 20px 0 40px;
                    }
                    .item{
                        width: 90%;
                        @include sp{
                            width: 100%;
                        }
                        .item_inner{
                            width: 86.81%;
                            margin-left: auto;
                            display: flex;
                            padding: 45px 0px 45px 25px;
                            @include sp{
                                width: 100%;
                                padding: 0;
                                display: block;
                            }
                            &--img{
                                width: 440px;
                                @include sp{
                                    width: 92%;
                                    margin: 0 auto 20px;
                                }
                            }
                            &--txt_box{
                                margin-left: 45px;
                                width: 310px;
                                @include sp{
                                    margin: 0 auto;
                                    width: 92%;
                                }
                                span{
                                    font-size: 11px;
                                    letter-spacing: 0.28em;
                                    line-height: 1.85;
                                    font-weight: 400;
                                }
                                .label{
                                    font-size: 12px;
                                    letter-spacing: 0.28em;
                                    line-height: 1;
                                    font-weight: 500;
                                    color: #fff;
                                    background-color: #231815;
                                    padding: 5px 20px;
                                    display: inline-block;
                                    margin-bottom: 0.781vh;
                                }
                                .ttl{
                                    font-size: 16px;
                                    letter-spacing: 0.28em;
                                    line-height: 2.7;
                                    font-weight: 500;
                                }
                                .date{}
                                .time{}
                                .place{}
                                .note{
                                    margin-bottom: 1.171vh;
                                }
                                .txt{
                                    font-size: 11px;
                                    letter-spacing: 0.28em;
                                    line-height: 1.85;
                                    font-weight: 400;
                                    margin-bottom: 25px;
                                    text-align: justify;
                                    @include sp{
                                        margin-bottom: 20px;
                                    }
                                }
                                .kozuka{
                                    line-height: 1.65;
                                }
                                .head:after{
                                    content:':';
                                }
                                .more_btn{
                                    .btn{
                                        a{
                                            font-size: 16px;
                                            letter-spacing: 0.28em;
                                            line-height: 2.1;
                                            font-weight: 400;
                                            position: relative;
                                            p{
                                                position: relative;
                                            }
                                            p:before{
                                                content:'';
                                                position: absolute;
                                                border-bottom: 1px solid #231815;
                                                bottom: 0;
                                                left: 0;
                                                width: 240px;
                                                @include sp{
                                                    width: 100px;
                                                }
                                            }
                                            p:after{
                                                content:'';
                                                position: absolute;
                                                border-bottom: 1px solid #231815;
                                                bottom: 11px;
                                                left: 214px;
                                                width: 30px;
                                                transform: rotate(45deg);
                                                @include sp{
                                                    width: 15px;
                                                    bottom: 6px;
                                                    left: 86px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .bg:nth-child(2n+1){
                    background-color: #E2E4E4;
                }
            }
        }

        /*pagination*/
        .pagination{
            width: 90%;
            margin-bottom: 140px;
            @include sp{
                width: 100%;
                margin-bottom: 80px;
            }
            &--inner{
                width: 86.81%;
                margin-left: auto;
                @include sp{
                    width: 100%;
                }
                &--list{
                    @include flex($justify:start);
                    @include sp{
                        @include flex($justify:center);
                    }
                    &--item{
                        margin: 0 20px;
                        @include sp{
                            margin: 0 13px;
                        }
                        .page-link{
                            color: #231815;
                            padding: 0;
                            display: inline-block;
                            line-height: 1;
                            position: relative;
                            .arrow-left_txt{
                                padding-left: 20px;
                            }
                            .arrow-left{
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                left: 0;
                                img{
                                    width: 10px;
                                }
                            }
                            .arrow-right_txt{
                                padding-right: 20px;
                            }
                            .arrow-right{
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                right: 0;
                                img{
                                    width: 10px;
                                }
                            }
                        }
                        .page-link.is-active{
                            border-bottom: 1px solid #231815;
                        }
                    }
                    &--item:first-child{
                        margin-left: 0;
                    }
                }
            }
        }
    }

}

/*event-detail*/
#event-dtl{
    .l_header--logo{
        position: relative;
        top: 40px;
        left: 140px;
        z-index: 999;
        @include sp{
            position: absolute;
            top: 30px;
            left: 24px;
        }
    }
    .l_breadcrumb {
        width: 90%;
        position: relative;
        padding: 20px 0 100px;
        margin-top: 60px;
        @include sp{
            display: none;
        }
    }
    .pl_event-detail{
        &--inner{
            &--head{
                background-color: $green;
                text-align: center;
                .head_inner{
                    padding: 36px 0 70px;
                    @include sp{
                            padding: 150px 0 70px;
                        }
                    .category{
                        h2{
                            font-size: 18px;
                            letter-spacing: 0.28em;
                            line-height: 1.85;
                            font-weight: 500;
                            color: #fff;
                            margin-bottom: 15px;
                            @include sp{
                                font-size: 18px;
                            }
                        }
                    }
                    .cate_ttl{
                        h3{
                            font-size: 21px;
                            letter-spacing: 0.28em;
                            line-height: 1.75;
                            font-weight: 500;
                            color: #fff;
                        }
                    }
                }
            }
            &--article{
                @include sp{
                    margin-bottom: 40px;
                }
                .article_inner{
                    &--img{
                        .img_wrap.img-s{
                            width: 246px;
                            @include sp{
                                width: 100%;
                                margin-bottom: 20px;
                            }
                            img{}
                        }
                        .img_wrap.img-s:last-child{
                            @include sp{
                                margin-bottom: 0px;
                            }
                        }
                    }
                    &--ttl{
                        width: 782px;
                        margin: 0 auto 40px;
                        @include sp{
                            width: 100%;
                            margin: 0 auto 20px;
                        }
                        h2{
                            font-size: 22px;
                            letter-spacing: 0.28em;
                            line-height: 1.75;
                            font-weight: 500;
                            @include sp{
                                font-size: 18px;
                            }
                        }
                    }
                    &--txt{
                        .txt{}
                    }
                }
            }
            &--item{
                .item_inner{
                    width: 86.81%;
                    margin-left: auto;
                    @include sp{
                        width: 92%;
                        margin: 0 auto;
                    }
                    .item_box{
                        width: 782px;
                        margin: 0 auto 40px;
                        @include flex;
                        @include sp{
                            width: 100%;
                            display: block;
                        }
                        &--left{
                            width: 400px;
                            @include sp{
                                width: 100%;
                                margin-bottom: 20px;
                            }
                            p{
                                font-size: 12px;
                                letter-spacing: 0.28em;
                                line-height: 2.1;
                                font-weight: 400;
                                @include sp{
                                    font-size: 10px;
                                }
                            }
                            .sec_ttl{
                                p{
                                    font-size: 17px;
                                    letter-spacing: 0.28em;
                                    line-height: 2.41;
                                    font-weight: 500;
                                }
                            }
                            .box_table{
                                display: flex;
                            }
                            .date{}
                            .time{}
                            .place{}
                            .note{}
                        }
                        &--right{
                            &--inner{
                                a{
                                    width: 375px;
                                    height: 140px;
                                    background-color: $green;
                                    @include flex($justify:center,$items:center);
                                    @include sp{
                                        width: 100%;
                                    }
                                    .insta_logo{
                                        width: 30px;
                                    }
                                    .txt{
                                        font-size: 10px;
                                        letter-spacing: 0.1em;
                                        line-height: 1.8;
                                        font-weight: 400;
                                        margin-left: 6.58%;
                                        color: #fff;
                                        @include sp{
                                            margin-left: 10px;
                                        }
                                        .em_txt{
                                            font-size: 13px;
                                            letter-spacing: 0.1em;
                                            line-height: 1.38;
                                            font-weight: 500;
                                            padding-top: 5px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .reserv_area{
                        width: 782px;
                        height: 154px;
                        margin: 0 auto 80px;
                        background-color: $gray;
                        @include sp{
                            width: 100%;
                            height: 135px;
                        }
                        &--inner{
                            color: #fff;
                            height: 154px;
                            display: flex;
                            align-items: center;
                            justify-content: space-evenly;
                            flex-direction: column;
                            margin: 0 70px;
                            @include sp{
                                margin: 0;
                                height: 135px;
                            }
                            .txt{
                                font-size: 16px;
                                letter-spacing: 0.26em;
                                line-height: 1;
                                font-weight: 500;
                                @include sp{
                                    font-size: 16px;
                                }
                            }
                            .tel{
                                font-size: 41px;
                                letter-spacing: 0.12em;
                                line-height: 1;
                                font-weight: 500;
                                @include sp{
                                    font-size: 37px;
                                    margin-bottom: 10px;
                                }
                            }
                            .time{
                                font-size: 11px;
                                letter-spacing: 0.46em;
                                line-height: 1;
                                font-weight: 500;
                            }
                            .txt_en{
                                font-size: 16px;
                                letter-spacing: 0.26em;
                                line-height: 1;
                                font-weight: 500;
                                @include sp{
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
        /*detail-pagination*/
        .pagination{
            &--inner{
                &--list{
                    &--item{}
                    .list_txt{
                        margin: 0 auto;
                        p{
                            color: #fff;
                            background-color: $gray;
                        }
                    }
                }
            }
        }
    }
}